const initialState = {
  listRuangById: [],
  ruanganByGedung: [],
  jamLaporan: [],
  formListGedung: [],
  ModalDetailChecklist: true,
  HasilChecklist: [],
  MonitoringChecklist: [],
};

const gedungChecklistReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETRUANGANCHECKLIST":
      return {
        ...state,
        listRuangById: data,
      };
    case "SET_GETRUANGANBYGEDUNG":
      return {
        ...state,
        ruanganByGedung: data,
      };
    case "SET_JAMLAPORAN":
      return {
        ...state,
        jamLaporan: data,
      };
    case "SET_DETAILCHECKLIST":
      return {
        ...state,
        ModalDetailChecklist: data,
      };
    case "SET_HASILCHECKLIST":
      return {
        ...state,
        HasilChecklist: data,
      };
    case "SET_MONITORINGHASILCHECKLIST":
      return {
        ...state,
        MonitoringChecklist: data,
      };
    default:
      return state;
  }
};

export default gedungChecklistReducer;
