import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import Chart from "react-apexcharts";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  Label,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  getKpiMonitoringSurvey,
  updateTglAwal,
  updateTglAkhir,
} from "../../../stores";
import { useHistory } from "react-router-dom";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const survey = useSelector((state) => state.surveyReducer);
  const [filter, setFilter] = React.useState({
    tahun: new Date().getFullYear(),
  });

  const [kpi1Option, setKpi1Option] = React.useState(null);
  React.useEffect(() => {
    if (survey.variablesurvey) {
      setKpi1Option(survey.variablesurvey.data);
    }
  }, [survey.variablesurvey]);
  React.useEffect(() => {
    getKpiMonitoringSurvey(dispatch, filter);
  }, [filter]);

  console.log(survey.variablesurvey);
  return (
    <>
      <Container fluid>
        {survey.variablesurvey && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Survey Achievement</Card.Title>

                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Index</p>
                          <Card.Title as="h4">
                            {survey.variablesurvey.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {" "}
                            {survey.variablesurvey.target} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Service Quality</p>
                          <Card.Title as="h4">
                            {" "}
                            <b>
                              {survey.variablesurvey.pencapaian == 0
                                ? "-"
                                : survey.variablesurvey.pencapaian <= 25 &&
                                  survey.variablesurvey.pencapaian > 0
                                ? "D"
                                : survey.variablesurvey.pencapaian > 25 &&
                                  survey.variablesurvey.pencapaian <= 50
                                ? "C"
                                : survey.variablesurvey.pencapaian > 50 &&
                                  survey.variablesurvey.pencapaian <= 75
                                ? "B"
                                : "A"}
                            </b>
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              width="80vw"
                              data={kpi1Option}
                              margin={{
                                right: 30,
                                left: 50,
                                bottom: 30,
                              }}
                            >
                              <XAxis dataKey="name">
                                <Label
                                  value="2022"
                                  offset={-20}
                                  position="insideBottom"
                                />
                              </XAxis>
                              <YAxis>
                                <Label
                                  value="Value"
                                  offset={-20}
                                  angle={-90}
                                  position="insideLeft"
                                />
                              </YAxis>
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Total Score"
                                barSize={20}
                                fill="#ff002f"
                              >
                                <LabelList
                                  dataKey="Total Score"
                                  position="top"
                                />
                              </Bar>
                              <Bar
                                dataKey="Max Likert (Y)"
                                barSize={20}
                                fill="#0ed907"
                              >
                                <LabelList
                                  dataKey="Max Likert (Y)"
                                  position="top"
                                />
                              </Bar>
                              <Bar
                                dataKey="Participant"
                                barSize={20}
                                fill="#e0ac10"
                              >
                                <LabelList
                                  dataKey="Participant"
                                  position="top"
                                />
                              </Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/rekap-survey");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          survey.variablesurvey.pencapaian <
                          survey.variablesurvey.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}

        {/* end row */}
      </Container>
    </>
  );
}

export default Panels;
