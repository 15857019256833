import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getTemuanPest, verifikasiTemuanPest } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import { CardBody } from "reactstrap";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const temuanPest = useSelector((state) => state.pestRodentReducer);
  const [modal, setModal] = React.useState(false);
  //   const [project, setProject] = React.useState({});
  const [data, setData] = React.useState([]);
  const [dataTemuan, setDataTemuan] = React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    let tmp = temuanPest.listTemuanPest.map((val, index) => {
      return {
        ...val,
        tglLaporan: moment(val.startDate).format("DD MMM YYYY / h:mm:ss a"),

        actions:
          val.status == "On Progress" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataTemuan(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataTemuan(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
      };
    });
    setData(tmp);
  }, [temuanPest.listTemuanPest]);

  React.useEffect(() => {
    getTemuanPest(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Finding</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Building",
                      accessor: "gedung",
                    },
                    {
                      Header: "Area",
                      accessor: "area",
                    },
                    {
                      Header: "Jenis Temuan",
                      accessor: "jenisTemuan",
                    },
                    {
                      Header: "Reported Date",
                      accessor: "tglLaporan",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  {/* <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Detail Problem</Card.Title>
                    </Card.Header>
                  </Card.Header> */}
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Building
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.gedung}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Area
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.area}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Jenis Temuan
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.jenisTemuan}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.deskripsi}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.diajukanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Report Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataTemuan.startDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Deadline
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataTemuan.deadlineDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Solve Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataTemuan.solvedDate
                          ? "-"
                          : moment(dataTemuan.solvedDate).format(
                              "DD MMM YYYY / h:mm:ss a"
                            )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        On Schedule
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataTemuan.status != "Done"
                          ? "-"
                          : dataTemuan.solvedInTime
                          ? "Ya"
                          : "Tidak"}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Keterangan
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataTemuan.keterangan ? "-" : dataTemuan.keterangan}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Previous Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataTemuan.dokumentasiAwalArr ||
                      dataTemuan.dokumentasiAwalArr.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Previous Documentation is Not Available
                        </Col>
                      ) : (
                        dataTemuan.dokumentasiAwalArr.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                    {dataTemuan.status == "On Progress" ? null : (
                      <>
                        <hr />
                        <Row style={{ marginTop: 30 }}>
                          <Col
                            sm="12"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Final Documentation
                          </Col>
                        </Row>
                        <Row>
                          {!dataTemuan.dokumentasiAkhirArr ||
                          dataTemuan.dokumentasiAkhirArr.length == 0 ? (
                            <Col sm="12" style={{ textAlign: "center" }}>
                              Final Documentation is Not Available
                            </Col>
                          ) : (
                            dataTemuan.dokumentasiAkhirArr.map((val, index) => {
                              return (
                                <>
                                  <Col sm="4">
                                    <Image
                                      src={val}
                                      style={{
                                        width: 200,
                                        height: 250,
                                        margin: 20,
                                      }}
                                      thumbnail
                                    />
                                  </Col>
                                </>
                              );
                            })
                          )}
                        </Row>
                      </>
                    )}
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
