import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import { downloadQR, getShift } from "../../../stores";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getAreaNonGroup,
  daftarlokasi,
  deleteGedung,
  deleteRuang,
  daftarruangan,
  downloadPDF,
  editKodeRuangan,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function ReactTables() {
  const [singleSelect, setSingleSelect] = React.useState("");
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const [data, setData] = React.useState([]);
  const [dataRuangan, setDataRuangan] = React.useState([]);
  const [dataBaru, setDataBaru] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [dataGedung, setDataGedung] = React.useState({});
  const [modalRuangan, setModalRuangan] = React.useState(false);
  const [modalEditRuangan, setModalEditRuangan] = React.useState(false);
  const [idRuangan, setIdRuangan] = React.useState("");
  const [kodeRuangan, setKodeRuangan] = React.useState("");
  const history = useHistory();

  React.useEffect(() => {
    getShift(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = monitoring.listArea.map((val, index) => {
      return {
        ...val,
        totalRuangan: val.ruangan.length,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              onClick={() => {
                setDataGedung(val);
                setModalRuangan(true);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fa fa-plus-circle fa-lg" />
            </Button>{" "}
            <Button
              onClick={() => {
                deleteGedung(val._id);
              }}
              variant="danger"
              size="md"
              className="btn-link remove text-danger"
            >
              <i className="fa fa-times fa-lg" />
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
    let tmp2 = [];
    monitoring.listArea.map((val, index) => {
      val.ruangan.forEach((val2, index2) => {
        tmp2.push({
          gedung: val.gedung,
          ruangan: val2.area,
          koderuangan: val2.koderuangan,
          actions: (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModalEditRuangan(true);
                  setIdRuangan(val2._id);
                  console.log(val2);
                  // downloadPDF(val2._id, val2.area);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>
              <Button
                onClick={() => {
                  // console.log(val2._id);
                  downloadPDF(val2._id, val2.area);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-download fa-lg" />
              </Button>
              <Button
                onClick={() => {
                  // console.log(val2._id);
                  downloadQR(val2._id, val2.area);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-qrcode fa-lg" />
              </Button>
              <Button
                onClick={() => {
                  // console.log(val2._id);
                  deleteRuang(val2._id);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-times fa-lg" />
              </Button>
            </div>
          ),
        });
      });
    });
    setDataRuangan(tmp2);
  }, [monitoring.listArea]);
  React.useEffect(() => {
    getAreaNonGroup(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Add Building
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Building</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Building Name",
                      accessor: "gedung",
                    },
                    {
                      Header: "Room Total",
                      accessor: "totalRuangan",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h4 className="title">Area</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={dataRuangan}
                  columns={[
                    {
                      Header: "Building",
                      accessor: "gedung",
                    },
                    {
                      Header: "Area",
                      accessor: "ruangan",
                    },
                    {
                      Header: "Area Code",
                      accessor: "koderuangan",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modalRuangan}
        onHide={() => {
          setModalRuangan(false);
          setDataGedung({});
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Area</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Building</label>
                          <Form.Control
                            value={dataGedung.gedung}
                            // placeholder="Masukan Nama Gedung"
                            disabled={true}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Area's Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataGedung({
                                ...dataGedung,
                                namaRuangan: e.target.value,
                              });
                            }}
                            // placeholder="Masukan Nama Ruangan"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Shift</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={
                              !dataGedung.idShift
                                ? dataGedung.idShift
                                : dataGedung.idShift.val
                            }
                            onChange={(val) =>
                              setDataGedung({
                                ...dataGedung,
                                idShift: val.value,
                              })
                            }
                            options={monitoring.jamLaporan.map((val, index) => {
                              return {
                                value: val._id,
                                label: val.jam + "",
                              };
                            })}
                            placeholder="-Choose-"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        daftarruangan({
                          gedung: dataGedung._id,
                          area: dataGedung.namaRuangan,
                          idShift: dataGedung.idShift,
                        });
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Building</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Building</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataBaru({
                                ...dataBaru,
                                gedung: e.target.value,
                              });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Ruangan</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataBaru({
                                ...dataBaru,
                                area: e.target.value,
                              });
                            }}
                            placeholder="Masukan Nama Ruangan"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        daftarlokasi(dataBaru);
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modalEditRuangan}
        onHide={() => {
          setModalEditRuangan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Area Code</label>
                          <Form.Control
                            onChange={(e) => {
                              setKodeRuangan(e.target.value);
                            }}
                            // placeholder="Masukan Kode Ruangan Baru"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Ruangan</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataBaru({
                                ...dataBaru,
                                area: e.target.value,
                              });
                            }}
                            placeholder="Masukan Nama Ruangan"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        console.log(idRuangan, kodeRuangan);
                        e.preventDefault();
                        editKodeRuangan({
                          _id: idRuangan,
                          kodeRuangan: kodeRuangan,
                        });
                        // daftarlokasi(dataBaru);
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
