import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cekStatusUser, getPertanyaan, kirimHasilSurvey } from "../../stores";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function LoginPage() {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.surveyReducer);
  const [pertanyaanArr, setPertanyaanArr] = React.useState([]);
  const [nama, setNama] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [karyawanId, setKaryawanId] = React.useState("");

  React.useEffect(() => {
    getPertanyaan(dispatch);
  }, []);

  const cekStatus = () => {
    cekStatusUser(dispatch, { nama, karyawanId });
  };
  const submit = () => {
    // console.log(pertanyaanArr);
    kirimHasilSurvey({ pertanyaanArr, nama, karyawanId, deskripsi });
  };

  React.useEffect(() => {
    let tmp = survey.pertanyaan.map((val, index) => {
      return {
        urutan: val.urutan,
        pertanyaan: val.pertanyaan,
        jenis: val.jenis,
        score: 0,
      };
    });
    setPertanyaanArr(tmp);
  }, [survey.pertanyaan]);

  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="survey-form">
          <Card style={{ backgroundColor: "white" }}>
            <Card.Header>
              <Card.Title as="h4">Survey Registration</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <Form.Group>
                    <label>Name</label>
                    <Form.Control
                      onChange={(e) => {
                        setNama(e.target.value);
                        // setDataGedung({
                        //   ...dataGedung,
                        //   namaRuangan: e.target.value,
                        // });
                      }}
                      disabled={survey.statusUser ? true : false}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col sm="12">
                  <Form.Group>
                    <label>Employee ID</label>
                    <Form.Control
                      onChange={(e) => {
                        setKaryawanId(e.target.value);
                        // setDataGedung({
                        //   ...dataGedung,
                        //   namaRuangan: e.target.value,
                        // });
                      }}
                      disabled={survey.statusUser ? true : false}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              {survey.statusUser ? null : (
                <Button
                  className="btn-fill pull-right"
                  type="submit"
                  variant="info"
                  onClick={(e) => {
                    e.preventDefault();
                    cekStatus();
                    // daftarruangan({
                    //   gedung: dataGedung._id,
                    //   area: dataGedung.namaRuangan,
                    //   idShift: dataGedung.idShift,
                    // });
                  }}
                >
                  Submit
                </Button>
              )}
            </Card.Body>
          </Card>
          {survey.statusUser ? (
            <>
              <Card style={{ backgroundColor: "white" }}>
                <Card.Header>
                  <Card.Title as="h4">Satisfaction Survey</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm="12">
                      <Form.Label column>
                        Customer Satisfaction Survey
                      </Form.Label>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {pertanyaanArr.map((val, index) => {
                return (
                  <Card style={{ backgroundColor: "white" }} s>
                    <Card.Body>
                      <Row>
                        <Col sm="12">
                          <Form.Label column>
                            {val.urutan}. {val.pertanyaan}
                          </Form.Label>
                        </Col>
                        <Col sm="12">
                          <Form.Group
                            onChange={(e) => {
                              setPertanyaanArr([
                                ...pertanyaanArr.slice(0, index),
                                {
                                  urutan: val.urutan,
                                  pertanyaan: val.pertanyaan,
                                  jenis: val.jenis,
                                  score: e.target.value,
                                },
                                ...pertanyaanArr.slice(
                                  index + 1,
                                  pertanyaanArr.length
                                ),
                              ]);
                            }}
                          >
                            <Row>
                              <Col>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="100"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Sangat Baik
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="75"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Baik
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="50"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Cukup
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="25"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Buruk
                                  </Form.Check.Label>
                                </Form.Check>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      value="1"
                                      type="radio"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Buruk Sekali
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="2"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Buruk
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="3"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Cukup
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="4"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Cukup Baik
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="5"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Baik
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="6"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Baik Sekali
                                  </Form.Check.Label>
                                </Form.Check>
                              </Col>
                            </Row> */}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                );
              })}
              <Card style={{ backgroundColor: "white" }}>
                <Card.Body>
                  <Row>
                    <Col sm="12">
                      <Form.Group>
                        <label>
                          <b>
                            Silahkan menyampaikan masukan anda untuk perbaikan
                            IFM agar menjadi lebih baik kedepannya
                          </b>
                        </label>
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                            // setProject({
                            //   ...project,
                            //   deskripsi: e.target.value,
                            // });
                          }}
                          placeholder="Tuliskan mmasukan anda (Optional)"
                          as="textarea"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col>
                  <Button
                    className="btn-fill btn-wd"
                    variant="info"
                    onClick={submit}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </>
          ) : null}
        </div>

        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
