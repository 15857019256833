const initialState = {
  listVisit: [],
  listIssue: [],
  kpi1: null,
  kpi2: null,
  calendar: [],
};

const industrialReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_LISTVISIT":
      return {
        ...state,
        listVisit: data,
      };
    case "SET_LISTISSUE":
      return {
        ...state,
        listIssue: data,
      };
    case "SET_KPI1VISITISSUE":
      return {
        ...state,
        kpi1: data,
      };
    case "SET_KPI2RESPONISSUE":
      return {
        ...state,
        kpi2: data,
      };
    case "SET_CALENDAR":
      return {
        ...state,
        calendar: data,
      };

    default:
      return state;
  }
};

export default industrialReducer;
