import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getProject } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.itProjectReducer);
  const [modal, setModal] = React.useState(false);
  const [dataProject, setDataProject] = React.useState([]);
  const [data, setData] = React.useState([]);
  //   const [status, setStatus] = React.useState("");
  //   const [deskripsi, setDeskripsi] = React.useState("");
  //   console.log(training);
  //   function onsubmit(e) {
  //     e.preventDefault();
  //     let fd = new FormData();
  //     fd.append("trainingId", dataIssue._id);
  //     fd.append("respon", status);
  //     fd.append("alasan", deskripsi);

  //     verifikasiTraining(fd);
  //   }

  // console.log(monitoring.listProjectAll);
  const history = useHistory();
  React.useEffect(() => {
    let tmp = project.listProject.map((val, index) => {
      return {
        ...val,
        tglMulai: moment(val.startDate).format("DD MMM YYYY / h:mm:ss a"),
        tglSelesai: moment(val.deadlineDate).format("DD MMM YYYY / h:mm:ss a"),
        actions:
          val.status == "On Progress" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataProject(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataProject(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
      };
    });
    setData(tmp);
  }, [project.listProject]);

  React.useEffect(() => {
    getProject(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Row>
          <Col md="12">
            <h4 className="title">IT Project</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Project",
                      accessor: "judulProject",
                    },
                    {
                      Header: "Submitter",
                      accessor: "diajukanOleh",
                    },
                    {
                      Header: "Start Date",
                      accessor: "tglMulai",
                    },
                    {
                      Header: "End Date",
                      accessor: "tglSelesai",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Project
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProject.judulProject}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProject.diajukanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Start Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataProject.startDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        End Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataProject.deadlineDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProject.deskripsi}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProject.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        On Schedule
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataProject.status != "Done"
                          ? "-"
                          : dataProject.solvedInTime
                          ? "Ya"
                          : "Tidak"}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Final Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataProject.buktiSelesai ||
                      dataProject.buktiSelesai.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Final Documentation is Not Available
                        </Col>
                      ) : (
                        dataProject.buktiSelesai.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
