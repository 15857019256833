const initialState = {
  orders: [],
  items: [],
  kategori: [],
  kpi1: null,
  stockGudang: [],
  ordersGudang: [],
  stockGudangLt: [],
  biaya: null,
};

const consumableReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_KPI1CONSUMABLE":
      return {
        ...state,
        kpi1: data,
      };
    case "SET_ORDERS":
      return {
        ...state,
        orders: data,
      };
    case "SET_KATEGORI":
      return {
        ...state,
        kategori: data,
      };
    case "SET_ITEMSORDER":
      return {
        ...state,
        items: data,
      };
    case "SET_STOCKGUDANG":
      return {
        ...state,
        stockGudang: data,
      };
    case "SET_ORDERSGUDANG":
      return {
        ...state,
        ordersGudang: data,
      };
    case "SET_STOCKGUDANGLT":
      return {
        ...state,
        stockGudangLt: data,
      };
    case "SET_BIAYA":
      return {
        ...state,
        biaya: data,
      };
    default:
      return state;
  }
};

export default consumableReducer;
