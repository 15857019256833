import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  Table,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getRekapOrder,
  updateStatusOrder,
  downloadRekapOrder,
  getOrderById,
  downloadSuratJalan,
  downloadRekapOrderExcel,
  updateStatusBarang,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";
import Swal from "sweetalert2";

function ReactTables() {
  const dispatch = useDispatch();
  const consumable = useSelector((state) => state.consumableReducer);

  const [data, setData] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [idOrder, setIdOrder] = React.useState("");
  const [kode, setKode] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [statusOrder, setStatusOrder] = React.useState("");
  const [listOrder, setListOrder] = React.useState([]);
  const [modalData, setModalData] = React.useState({
    kode: "",
    nama: "",
    spek: "",
    brand: "",
    qty: "",
    satuan: "",
    hargaSatuan: "",
    totalHarga: "",
    tanggalPesan: "",
    tanggalKirim: "",
    tanggalTerima: "",
    deadLine: "",
    solved: "",
  });
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  React.useEffect(() => {
    let tmp = consumable.orders.map((val) => {
      return {
        item: (() => {
          let item = "";
          val.items.forEach((val2, index2) => {
            item += val2.nama + " " + val2.Qty + "x";
            if (index2 !== val.items.length - 1) {
              item += ", ";
            }
          });
          return item;
        })(),
        // kategori: val.kategori,
        code: val.kode,
        // status: val.status,
        tanggalpesan: moment(val.createdAt).format("DD MMM YYYY / h:mm:ss a"),
        deadLine: moment(val.deadlineDate).format("DD MMM YYYY / h:mm:ss a"),
        actions: (
          <div className="actions-right">
            {/* {val.status === "Progress" ?  */}
            {/* ( */}
            <>
              <Button
                onClick={() => {
                  downloadRekapOrderExcel({ id: val._id, kode: val.kode });
                }}
                variant="info"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fas fa-file-excel fa-lg"></i>
              </Button>{" "}
              <Button
                onClick={() => {
                  console.log(val);
                  setStatus(val.status);
                  setListOrder(val.items);
                  setIdOrder(val._id);
                  setKode(val.kode);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fas fa-marker fa-lg"></i>
              </Button>
              {/* <Button
                onClick={() => {
                  console.log(val);
                  setModalData({
                    nama: val.nama,
                    tanggalpesan: val.createdAt,
                    item: val.items,
                    histori: val.history,
                    status: val.status,
                    kode: val.kode,
                  });
                  setModal(true);
                  setIdOrder(val._id);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-primary"
              >
                <i className="fas fa-info-circle fa-lg"></i>
              </Button>{" "} */}
            </>
            {/* ) */}
            {/* : val.status === "Delivery Process" ? ( */}
            {/* <>
                <Button
                  onClick={() => {
                    downloadRekapOrderExcel({ id: val._id, kode: val.kode });
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-success"
                >
                  <i className="fas fa-file-excel fa-lg"></i>
                </Button>{" "}
                <Button
                  onClick={() => {
                    setListOrder([]);
                    setKode("");
                    setStatus("");
                    downloadRekapOrder({ id: val._id, kode: val.kode });
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-info"
                >
                  <i className="fas fa-download fa-lg"></i>
                </Button>{" "}
                <Button
                  onClick={() => {
                    setListOrder([]);
                    setKode("");
                    setStatus("");
                    downloadSuratJalan({ id: val._id, kode: val.kode });
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-default"
                >
                  <i className="fas fa-print fa-lg"></i>
                </Button>{" "}
                <Button
                  onClick={() => {
                    setModalData({
                      nama: val.nama,
                      tanggalpesan: val.createdAt,
                      item: val.items,
                      histori: val.history,
                      status: val.status,
                      kode: val.kode,
                    });
                    setModal(true);
                    setIdOrder(val._id);
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-primary"
                >
                  <i className="fas fa-info-circle fa-lg"></i>
                </Button>{" "}
              </> */}
            {/* ) : ( */}
            {/* <>
                <Button
                  onClick={() => {
                    downloadRekapOrderExcel({ id: val._id, kode: val.kode });
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-success"
                >
                  <i className="fas fa-file-excel fa-lg"></i>
                </Button>{" "}
                <Button
                  onClick={() => {
                    setStatus(val.status);
                    setListOrder(val.items);
                    setIdOrder(val._id);
                    setKode(val.kode);
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-success"
                >
                  <i className="fas fa-archive fa-lg"></i>
                </Button>{" "}
                <Button
                  onClick={() => {
                    setModalData({
                      nama: val.nama,
                      tanggalpesan: val.createdAt,
                      item: val.items,
                      histori: val.history,
                      status: val.status,
                      kode: val.kode,
                    });
                    setModal(true);
                    setIdOrder(val._id);
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-primary"
                >
                  <i className="fas fa-info-circle fa-lg"></i>
                </Button>{" "}
              </> */}
            {/* )} */}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [consumable.orders]);

  React.useEffect(() => {
    getRekapOrder(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Order Recapitulation</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    // {
                    //   Header: "Category",
                    //   accessor: "kategori",
                    // },
                    {
                      Header: "Code",
                      accessor: "code",
                    },
                    {
                      Header: "Item",
                      accessor: "item",
                    },
                    {
                      Header: "Order Date",
                      accessor: "tanggalpesan",
                    },
                    {
                      Header: "Deadline Date",
                      accessor: "deadLine",
                    },
                    {
                      Header: "Detail",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>

          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Ordering Form {kode}</Card.Title>
              </Card.Header>
              <Card.Body className="table-responsive p-0">
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Brand</th>
                      <th>Speck</th>
                      <th>Category</th>
                      <th>Qty.</th>
                      <th>Unit</th>
                      <th>Price</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOrder.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{val.kode}</td>
                          <td>{val.nama}</td>
                          <td>{val.brand}</td>
                          <td>{val.spek}</td>
                          <td>{val.kategori}</td>
                          <td>{val.Qty}</td>
                          <td>{val.satuan}</td>
                          <td>
                            {status == "Received" ? (
                              `Rp${val.hargaSatuan}`
                            ) : (
                              <Form action="#" method="#">
                                <Form.Group>
                                  <Form.Control
                                    onChange={(e) => {
                                      setListOrder([
                                        ...listOrder.slice(0, index),
                                        {
                                          ...val,
                                          hargaSatuan: parseInt(e.target.value),
                                          totalHarga: parseInt(
                                            listOrder[index].Qty *
                                              e.target.value
                                          ),
                                        },
                                        ...listOrder.slice(
                                          index + 1,
                                          index.length
                                        ),
                                      ]);
                                    }}
                                    disabled={
                                      val.statusBarang == "Delivery Process" ||
                                      val.statusBarang == "Received"
                                        ? true
                                        : false
                                    }
                                    value={val.hargaSatuan}
                                    type="number"
                                    min={1}
                                    max={Number(val.max)}
                                  ></Form.Control>
                                </Form.Group>
                              </Form>
                            )}
                          </td>
                          <td>{`Rp${val.totalHarga}`}</td>
                          <td>
                            <Row>
                              <Col>
                                {val.statusBarang == "Delivery Process" ? (
                                  <Button
                                    className="btn-wd mr-1"
                                    onClick={() => {
                                      null;
                                    }}
                                    variant="warning"
                                  >
                                    Delivery Process
                                  </Button>
                                ) : val.statusBarang == "Received" ? (
                                  <Button
                                    className="btn-wd mr-1"
                                    onClick={() => {
                                      null;
                                    }}
                                    variant="success"
                                  >
                                    Received
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn-wd mr-1"
                                    onClick={() => {
                                      // console.log(val);
                                      val.hargaSatuan == 0
                                        ? Swal.fire({
                                            icon: "warning",
                                            title: "Oops...",
                                            text: "Price can't be empty!",
                                          })
                                        : updateStatusBarang({
                                            idItem: val._id,
                                            hargaSatuan: val.hargaSatuan,
                                            totalHarga: val.totalHarga,
                                          });
                                    }}
                                    variant="primary"
                                  >
                                    Submit
                                  </Button>
                                )}
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    setModalData({
                                      kode: val.kode,
                                      nama: val.nama,
                                      spek: val.spek,
                                      brand: val.brand,
                                      qty: val.Qty,
                                      satuan: val.satuan,
                                      hargaSatuan: val.hargaSatuan,
                                      totalHarga: val.totalHarga,
                                      tanggalPesan: val.createdAt,
                                      tanggalKirim: !val.deliverDate
                                        ? ""
                                        : val.deliverDate,
                                      tanggalTerima: !val.arrivedDate
                                        ? ""
                                        : val.arrivedDate,
                                      deadLine: val.deadlineDate,
                                      solved: !val.solvedInTime
                                        ? ""
                                        : val.solvedInTime,
                                    });
                                    setModal(true);
                                  }}
                                  variant="info"
                                  size="md"
                                  className="btn-link remove text-info"
                                  // {
                                  //   val.status == "Received"
                                  //     ? val.solvedInTime == true
                                  //       ? "btn-link remove text-warning"
                                  //       : "btn-link remove text-danger"
                                  //     : new Date(val.deadlineDate).getTime() >= date
                                  //     ? "btn-link remove text-warning"
                                  //     : "btn-link remove text-danger"
                                  // }
                                >
                                  <i className="fas fa-info-circle fa-lg"></i>
                                </Button>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            {/* {listOrder.length > 0 && (
              <>
                {status == "Received" ? null : (
                  <Button
                    className="btn-wd mr-1"
                    onClick={() => {
                      let tmp = listOrder.map((val) => {
                        return {
                          idItem: val._id,
                          hargaSatuan: val.hargaSatuan,
                          totalHarga: val.totalHarga,
                        };
                      });
                      updateStatusOrder({
                        items: JSON.stringify(tmp),
                        idOrder: idOrder,
                        status: "Delivery",
                      });
                    }}
                    variant="primary"
                  >
                    Submit
                  </Button>
                )}
              </>
            )} */}
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Code:
            </Col>
            <Col sm="9">{modalData.kode}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Name:
            </Col>
            <Col sm="9">{modalData.nama}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Speck:
            </Col>
            <Col sm="9">{modalData.spek}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Brand:
            </Col>
            <Col sm="9">{modalData.brand}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Qty:
            </Col>
            <Col sm="9">{modalData.qty}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Unit:
            </Col>
            <Col sm="9">{modalData.satuan}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Price:
            </Col>
            <Col sm="9">Rp.{modalData.hargaSatuan}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Total:
            </Col>
            <Col sm="9">Rp.{modalData.totalHarga}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Order Date:
            </Col>
            <Col sm="9">
              {moment(modalData.tanggalPesan).format("DD MMM YYYY / h:mm:ss a")}
            </Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Deadline Date:
            </Col>
            <Col sm="9">
              {moment(modalData.deadLine).format("DD MMM YYYY / h:mm:ss a")}
            </Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Deliver Date:
            </Col>
            <Col sm="9">
              {modalData.tanggalKirim == ""
                ? "-"
                : moment(modalData.tanggalKirim).format(
                    "DD MMM YYYY / h:mm:ss a"
                  )}
            </Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Arrive Date:
            </Col>
            <Col sm="9">
              {modalData.tanggalTerima == ""
                ? "-"
                : moment(modalData.tanggalTerima).format(
                    "DD MMM YYYY / h:mm:ss a"
                  )}
            </Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Solve Intime:
            </Col>
            <Col sm="9">
              {modalData.solved == ""
                ? "-"
                : modalData.solved == true
                ? "Yes"
                : "No"}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail {modalData.kode}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Order Name:
            </Col>
            <Col sm="9">{modalData.nama}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Order Date:
            </Col>
            <Col sm="9">
              {moment(modalData.tanggalpesan).format("DD MMM YYYY / h:mm:ss a")}
            </Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Item :
            </Col>
            <Col sm="9">
              {modalData.item.map((val) => {
                console.log(val, "aaaaaaaa");
                return (
                  <p className="mb-1">
                    {val.nama} - {val.Qty} {val.satuan} - {val.kategori} -{" "}
                    {val.status} -
                    {moment(val.deadlineDate).format("DD MMM YYYY / h:mm:ss a")}
                  </p>
                );
              })}
            </Col>
          </Row>
          
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default ReactTables;
