import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getVisitIndustrial(dispatch) {
  baseAxios
    .get(`/visitindustrialissue/getvisitall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTVISIT", data: respon.data });
    })
    .catch(errorHandler);
}

export function getIssue(dispatch) {
  baseAxios
    .get(`/responindustrial/getissueall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTISSUE", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpi1VisitIssue(dispatch, data) {
  baseAxios
    .get(
      `/visitindustrialissue/getvisitindustrialgrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI1VISITISSUE", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpi2ResponIssue(dispatch, data) {
  baseAxios
    .get(
      `/responindustrial/getresponissuegrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI2RESPONISSUE", data: respon.data });
    })
    .catch(errorHandler);
}

export function tambahVisitIssue(data) {
  console.log(data);
  baseAxios
    .post(`/visitindustrialissue/ajukanvisit`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function editVisitIssue(data) {
  console.log(data);
  baseAxios
    .patch(`/visitindustrialissue/ajukanvisitulang`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function tambahIssue(data) {
  console.log(data);
  baseAxios
    .post(`/responindustrial/ajukanissue`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getCalendarVisitIndustrial(dispatch) {
  baseAxios
    .get("/visitindustrialissue/getcalendarvisitindustrial", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_CALENDAR", data: respon.data });
    })
    .catch(errorHandler);
}

export function deletedVisitIsue(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      baseAxios
        .delete("/visitindustrialissue/deletvisitisue", {
          data: { visitId: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);
    }
  });
}
