import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Tab,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  editMenuKantin,
  tambahMenuKantin,
  getMenuKantin,
  deleteMenuKantin,
  tambahMenuKantinBaru,
  editMenuKantinBaru,
  getMenuKantinBaru,
  deleteMenuKantinBaru,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function TambahMenuKantin() {
  const dispatch = useDispatch();
  const date = new Date();
  const menuKantin = useSelector((state) => state.kantinReducer);
  // const [modal, setModal] = React.useState(false);
  const [modalAddMenu, setModalAddMenu] = React.useState(false);
  const [modalEditMenu, setModalEditMenu] = React.useState(false);
  const [modalEditMenuBaru, setModalEditMenuBaru] = React.useState(false);
  const [modalAddMenuBaru, setModalAddMenuBaru] = React.useState(false);
  const [idMenu, setIdMenu] = React.useState("");
  const [idMenuBaru, setIdMenuBaru] = React.useState("");
  const [listMenu, setListMenu] = React.useState([]);
  const [listMenuBaru, setListMenuBaru] = React.useState([]);
  const [listMenuDropDownBaru, setListDropDownMenuBaru] = React.useState([]);
  const [menu, setMenu] = React.useState("");
  const [menuBaru, setMenuBaru] = React.useState("");
  const [kodeMenuBaru, setkodeMenuBaru] = React.useState("");
  const [kodeMenu, setKodeMenu] = React.useState({
    value: "",
    label: "Choose the Menu",
    isDisabled: true,
  });
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).setHours(
      7,
      0,
      0,
      0
    )
  );

  const handleModalOpen = () => {
    setModalAddMenuBaru(true);
    setkodeMenuBaru("");
    setMenuBaru("");
  };

  const handleModalClose = () => {
    setModalAddMenuBaru(false);
  };

  function submitmenu(e) {
    e.preventDefault();
    tambahMenuKantin({
      kodeMenu: kodeMenu.value,
      tanggal: dariTgl,
    });
  }
  function submitmenubaru(e) {
    e.preventDefault();
    handleModalClose();
    tambahMenuKantinBaru({
      kodeMenu: kodeMenuBaru,
      namaMenu: menuBaru,
    });
  }

  function edit(e) {
    e.preventDefault();

    editMenuKantin({
      _id: idMenu,
      // kodeMenu: kodeMenu,
      tanggal: dariTgl,
    });
  }
  function editBaru(e) {
    e.preventDefault();
    editMenuKantinBaru({
      _id: idMenuBaru,
      kodeMenu: kodeMenuBaru,
      namaMenu: menuBaru,
    });
  }

  React.useEffect(() => {
    let tmp =
      menuKantin.getMenuKantinBaru &&
      menuKantin.getMenuKantinBaru.map((val) => {
        return {
          ...val,
          label: `${val.kodeMenu} - ${val.namaMenu}`,
          value: val.kodeMenu,
        };
      });
    setListDropDownMenuBaru(tmp);
  }, [menuKantin.getMenuKantinBaru]);

  React.useEffect(() => {
    let tmp =
      menuKantin.getMenuKantinBaru &&
      menuKantin.getMenuKantinBaru.map((val, index) => {
        return {
          ...val,

          actions: (
            <div className="actions-right">
              {/* <Button
                onClick={() => {
                  setModalEditMenuBaru(true);
                  setIdMenuBaru(val._id);
                  setKodeMenu(val.kodeMenu);
                  setMenuBaru(val.namaMenu);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>{" "} */}
              <Button
                onClick={() => {
                  // deletedIdMenuBaru(val.kodeMenu);
                  deleteMenuKantinBaru({
                    kodeMenuBaru: val.kodeMenu,
                  });
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fas fa-times fa-lg" />
              </Button>{" "}
            </div>
          ),
        };
      });
    setListMenuBaru(tmp);
  }, [menuKantin.getMenuKantinBaru]);

  React.useEffect(() => {
    let tmp =
      menuKantin.getMenuKantin &&
      menuKantin.getMenuKantin.map((val, index) => {
        return {
          ...val,
          tanggalTampil: !val.tanggal
            ? "-"
            : moment(val.tanggal).format("dddd, DD MMM YYYY"),
          actions: (
            <div className="actions-right">
              {/* <Button
                onClick={() => {
                  setModalEditMenu(true);
                  setIdMenu(val._id);
                  setMenu(val.namaMenu);
                  setDariTgl(val.tanggal);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>{" "} */}
              <Button
                onClick={() => {
                  deleteMenuKantin({
                    kodeMenuPenilaian: val.kodeMenu,
                  });
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fas fa-times fa-lg" />
              </Button>{" "}
            </div>
          ),
        };
      });
    setListMenu(tmp);
  }, [menuKantin.getMenuKantin]);

  React.useEffect(() => {
    getMenuKantin(dispatch);
    getMenuKantinBaru(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card style={{ border: 0 }}>
              <Card.Body>
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    setModalAddMenu(true);
                  }}
                  variant="primary"
                >
                  Add Form Food Menu
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card style={{ border: 0 }}>
              <Card.Body className="text-right">
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    handleModalOpen();
                  }}
                  variant="primary"
                >
                  Add New Food
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <h4 className="title">List Menu</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={listMenuBaru}
                  columns={[
                    {
                      Header: "Code Menu",
                      accessor: "kodeMenu",
                    },
                    {
                      Header: "Name Menu",
                      accessor: "namaMenu",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h4 className="title">Menu Canteen</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={listMenu}
                  columns={[
                    {
                      Header: "Code Menu",
                      accessor: "kodeMenu",
                    },
                    {
                      Header: "Name Menu",
                      accessor: "namaMenu",
                    },
                    {
                      Header: "Date",
                      accessor: "tanggalTampil",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modalAddMenu}
        onHide={() => {
          setModalAddMenu(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Food Menu</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Food Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setKodeMenu(value);
                            }}
                            value={kodeMenu}
                            options={[
                              {
                                value: "",
                                label: "Choose the Menu",
                                isDisabled: true,
                              },
                              ...listMenuDropDownBaru,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <label>Select Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate().setHours(7, 0, 0, 0));
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitmenu}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modalAddMenuBaru}
        onHide={() => {
          setModalAddMenuBaru(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add New Food</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Code Food</label>
                          <Form.Control
                            onChange={(e) => {
                              setkodeMenuBaru(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Food Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setMenuBaru(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitmenubaru}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalEditMenu}
        onHide={() => {
          setModalEditMenu(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Food Name</label>
                          <Form.Control
                            disabled
                            value={menu.toString()}
                            onChange={(e) => {
                              setMenu(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <label>Select Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate().setHours(7, 0, 0, 0));
                            }}
                            value={
                              !dariTgl
                                ? "-"
                                : moment(dariTgl).format("MM/DD/YYYY")
                            }
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={edit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalEditMenuBaru}
        onHide={() => {
          setModalEditMenuBaru(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Code Menu</label>
                          <Form.Control
                            value={kodeMenu.toString()}
                            onChange={(e) => {
                              setKodeMenu(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Food Name</label>
                          <Form.Control
                            value={menuBaru.toString()}
                            onChange={(e) => {
                              setMenuBaru(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={editBaru}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TambahMenuKantin;
