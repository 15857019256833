import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  Label,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { getKpi1Safety, updateTglAwal, updateTglAkhir } from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const safety = useSelector((state) => state.safetyReducer);
  const auth = useSelector((state) => state.authReducer);
  console.log(safety);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(auth.dariTgl);
  const [smpTgl, setSmpTgl] = React.useState(auth.smpTgl);
  const [kpi1Option, setKpi1Option] = React.useState(null);

  React.useEffect(() => {
    if (safety.kpi1) {
      setKpi1Option(safety.kpi1.data);
    }
  }, [safety.kpi1]);

  React.useEffect(() => {
    getKpi1Safety(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
  }, [dariTgl, smpTgl]);

  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAwal(dispatch, {
                    dariTgl: e.toDate().setHours(0, 0, 0, 0),
                  });
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAkhir(dispatch, {
                    smpTgl: e.toDate().setHours(23, 59, 0, 0),
                  });
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        {/* Baris Satu */}
        {safety.kpi1 && (
          <Row>
            <Col md="3">
              <Card>
                <Card.Header>
                  <Card.Title as="h5">
                    <b>Fatalities</b>
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">{safety.kpi1.F2} %</Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                {/* <Card.Footer>
                <hr></hr>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button
                      onClick={() => {
                        history.push("/admin/kejadian");
                      }}
                      className="btn-wd btn-outline mr-1"
                      type="button"
                      variant={
                        safety.kpi1.pencapaian < safety.kpi1.target
                          ? "danger"
                          : "info"
                      }
                    >
                      Detail
                    </Button>
                  </Col>
                </Row>
              </Card.Footer> */}
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <Card.Header>
                  <Card.Title as="h5">
                    <b>Lost time injuries (LTI)</b>
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">{safety.kpi1.LTI2} %</Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                {/* <Card.Footer>
                <hr></hr>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button
                      onClick={() => {
                        history.push("/admin/kejadian");
                      }}
                      className="btn-wd btn-outline mr-1"
                      type="button"
                      variant={
                        safety.kpi1.pencapaian < safety.kpi1.target
                          ? "danger"
                          : "info"
                      }
                    >
                      Detail
                    </Button>
                  </Col>
                </Row>
              </Card.Footer> */}
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Header>
                  <Card.Title as="h5">
                    <b>Restricted Work Cases (RWC)</b>
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">{safety.kpi1.RWC2} %</Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                {/* <Card.Footer>
                <hr></hr>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button
                      onClick={() => {
                        history.push("/admin/kejadian");
                      }}
                      className="btn-wd btn-outline mr-1"
                      type="button"
                      variant={
                        safety.kpi1.pencapaian < safety.kpi1.target
                          ? "danger"
                          : "info"
                      }
                    >
                      Detail
                    </Button>
                  </Col>
                </Row>
              </Card.Footer> */}
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <Card.Header>
                  <Card.Title as="h5">
                    <b>Medical Treatment Cases (MTC)</b>
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">{safety.kpi1.MTC2} %</Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                {/* <Card.Footer>
                <hr></hr>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button
                      onClick={() => {
                        history.push("/admin/kejadian");
                      }}
                      className="btn-wd btn-outline mr-1"
                      type="button"
                      variant={
                        safety.kpi1.pencapaian < safety.kpi1.target
                          ? "danger"
                          : "info"
                      }
                    >
                      Detail
                    </Button>
                  </Col>
                </Row>
              </Card.Footer> */}
              </Card>
            </Col>
          </Row>
        )}
        {safety.kpi1 && (
          <Row>
            <Col md="3">
              <Card>
                <Card.Header>
                  <Card.Title as="h5">
                    <b>First Aid Case (FAC)</b>
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">{safety.kpi1.FAC2} %</Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                {/* <Card.Footer>
                <hr></hr>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button
                      onClick={() => {
                        history.push("/admin/kejadian");
                      }}
                      className="btn-wd btn-outline mr-1"
                      type="button"
                      variant={
                        safety.kpi1.pencapaian < safety.kpi1.target
                          ? "danger"
                          : "info"
                      }
                    >
                      Detail
                    </Button>
                  </Col>
                </Row>
              </Card.Footer> */}
              </Card>
            </Col>
            {/* <Col md="3">
              <Card>
                <Card.Header>
                  <Card.Title as="h5">
                    <b>Near Miss (NM)</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">{safety.kpi1.NM2} %</Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
               
              </Card>
            </Col> */}
          </Row>
        )}
        {safety.kpi1 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Safety Achievement</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {safety.kpi1.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {safety.kpi1.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              data={kpi1Option}
                              margin={{
                                right: 30,
                                left: 50,
                                bottom: 30,
                              }}
                            >
                              <XAxis dataKey="nama" />
                              <YAxis>
                                <Label
                                  value="Incident"
                                  offset={-20}
                                  angle={-90}
                                  position="insideLeft"
                                />
                              </YAxis>
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="On Time"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Not Ontime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col>
                      <div>Note:</div>
                      <ul>
                        <li>
                          Incident must be followed up in less than 1x24 hours
                        </li>
                      </ul>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        style={{ maxHeight: 40 }}
                        onClick={() => {
                          history.push("/admin/kejadian");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          safety.kpi1.pencapaian < safety.kpi1.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default Panels;
