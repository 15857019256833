import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getTraining(dispatch) {
  baseAxios
    .get(`/training/gettrainingall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTTRAINING", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiTraining(data) {
  baseAxios
    .patch(`/training/respontraining`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi1Training(dispatch, data) {
  baseAxios
    .get(
      `/training/getgrafiktraining?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI1TRAINING", data: respon.data });
    })
    .catch(errorHandler);
}

export function getPembinaan(dispatch) {
  baseAxios
    .get(`/pembinaan/getpembinaanall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTPEMBINAAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiPembinaan(data) {
  baseAxios
    .patch(`/pembinaan/responpembinaan`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi2Pembinaan(dispatch, data) {
  baseAxios
    .get(
      `/pembinaan/getgrafikpembinaan?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI2PEMBINAAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function tambahTraining(data) {
  console.log(data);
  baseAxios
    .post(`/training/ajukantraining`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function editTraining(data) {
  console.log(data);
  baseAxios
    .patch(`/training/ajukantrainingulang`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getCalendarTraining(dispatch) {
  baseAxios
    .get("/training/gettrainingcalendar", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_CALENDAR", data: respon.data });
    })
    .catch(errorHandler);
}

export function importAbsensi(data) {
  // console.log(data);
  baseAxios
    .post(`/training/importexcelabsensi`, data, {
      headers: {
        token: localStorage.getItem("tokenjti"),
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        console.log(
          parseInt(
            Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
          )
        );
        console.log(ProgressEvent);
      },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getAbsensi(dispatch, data) {
  baseAxios
    .get(`/training/getabsensiall?dari=${data.dariTgl}&sampai=${data.smpTgl}`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTABSENSI", data: respon.data });
    })
    .catch(errorHandler);
}

export function exportAbsensi(data) {
  // console.log(data);
  baseAxios
    .get(
      `/training/exportabsenbydate?dari=${data.dariTgl}&sampai=${data.smpTgl}&departement=${data.departement}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
        responseType: "blob",
      }
    )
    .then(async (respon) => {
      console.log(respon);
      const url = window.URL.createObjectURL(new Blob([respon.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Absensi ${data.dariTgl} sampai ${data.smpTgl}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      Swal.close();
    })
    .catch(errorHandler);
}

export function getKpi3Absensi(dispatch, data) {
  baseAxios
    .get(
      `/training/getgrafikabsensi?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI3ABSENSI", data: respon.data });
    })
    .catch(errorHandler);
}

export function getAbsensiDashboard(dispatch, data) {
  baseAxios
    .get(
      `/training/getgrafikabsensi?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPIABSENSIDASHBOARD", data: respon.data });
    })
    .catch(errorHandler);
}

export function deleteTraining(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      // console.log(data.visitId);
      baseAxios
        .delete("/training/deletetraining", {
          data: { trainingId: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire({
            title: respon.data.message,
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        })
        .catch(errorHandler);

      // Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });
}

export function getAllEvent(dispatch) {
  baseAxios
    .get(`/training/getallevent`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_ALLEVENT", data: respon.data });
    })
    .catch(errorHandler);
}

export function getAllEventCalendar(dispatch) {
  baseAxios
    .get(`/training/getalleventcalendar`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_ALLEVENTCALENDAR", data: respon.data });
    })
    .catch(errorHandler);
}

export function setJumlahKaryawan(data) {
  console.log(data);
  baseAxios
    .post(`/training/setjumlahkaryawan`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getJmlKaryawan(dispatch) {
  baseAxios
    .get(`/training/getjumlahkaryawan`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      console.log("asdasdasda", respon.data);
      dispatch({ type: "SET_JMLKARYAWAN", data: respon.data.data });
    })
    .catch(errorHandler);
}
