import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  Table,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  Label,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import {
  getKpi1Consumable,
  exportBandU,
  getBiaya,
  updateTglAwal,
  updateTglAkhir,
  exportOrderPerBulanExcel,
  getMenuKantinBaru,
  grafikPenilaian1,
  grafikPenilaian2,
} from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";
import Swal from "sweetalert2";
const barColor = [
  "#4287f5",
  "#f51b84",
  "#33d91a",
  "#f77f16",
  "#ff1414",
  "#06cf8c",
  "#dc02f0",
  "#341f91",
  "#8c0e19",
  "#1fdbb6",
  "#1fdb48",
  "#9cdb1f",
  "#11cbfa",
  "#de95c4",
  "#e38d5f",
  "#f23f2c",
];
function detailFoodMenu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const menuKantin = useSelector((state) => state.kantinReducer);
  const auth = useSelector((state) => state.authReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(auth.dariTgl);
  const [smpTgl, setSmpTgl] = React.useState(auth.smpTgl);
  const [singleSelect, setSingleSelect] = React.useState(
    new Date().getFullYear()
  );
  const [singleSelectMonth, setSingleSelectMonth] = React.useState(
    new Date().getMonth()
  );
  const [objectSelect, setObjectSelect] = React.useState("");
  const [objectSelectMonth, setObjectSelectMonth] = React.useState("");
  const [listYear, setListYear] = React.useState([]);
  const [listMenu, setListMenu] = React.useState([]);
  const [listMenu2, setListMenu2] = React.useState([]);
  const [grafikNilai1, setGrafikNiali1] = React.useState(null);
  const [grafikNilai2, setGrafikNiali2] = React.useState(null);
  const [kodeMenu, setKodeMenu] = React.useState({
    value: "",
    label: "Choose the Menu",
    isDisabled: true,
  });
  const [kodeMenu2, setKodeMenu2] = React.useState({
    value: "",
    label: "Choose the Menu",
    isDisabled: true,
  });
  const [listMonth, setListMonth] = React.useState([
    {
      value: 0,
      label: "January",
    },
    {
      value: 1,
      label: "February",
    },
    {
      value: 2,
      label: "March",
    },
    {
      value: 3,
      label: "April",
    },
    {
      value: 4,
      label: "May",
    },
    {
      value: 5,
      label: "June",
    },
    {
      value: 6,
      label: "July",
    },
    {
      value: 7,
      label: "August",
    },
    {
      value: 8,
      label: "September",
    },
    {
      value: 9,
      label: "October",
    },
    {
      value: 10,
      label: "November",
    },
    {
      value: 11,
      label: "December",
    },
  ]);

  React.useEffect(() => {
    if (menuKantin.grafikPenilaian1) {
      setGrafikNiali1(menuKantin.grafikPenilaian1);
    }
  }, [menuKantin.grafikPenilaian1]);
  React.useEffect(() => {
    if (menuKantin.grafikPenilaian2) {
      setGrafikNiali2(menuKantin.grafikPenilaian2);
    }
  }, [menuKantin.grafikPenilaian2]);

  React.useEffect(() => {
    let tmp =
      menuKantin.getMenuKantinBaru &&
      menuKantin.getMenuKantinBaru.map((val) => {
        return {
          ...val,
          label: `${val.kodeMenu} - ${val.namaMenu}`,
          value: val.kodeMenu,
        };
      });
    setListMenu(tmp);
  }, [menuKantin.getMenuKantinBaru]);
  React.useEffect(() => {
    let tmp =
      menuKantin.getMenuKantinBaru &&
      menuKantin.getMenuKantinBaru.map((val) => {
        return {
          ...val,
          label: `${val.kodeMenu} - ${val.namaMenu}`,
          value: val.kodeMenu,
        };
      });
    setListMenu2(tmp);
  }, [menuKantin.getMenuKantinBaru]);

  React.useEffect(() => {
    grafikPenilaian1(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      kodeMenuPenilaian: kodeMenu.kodeMenu,
    });
  }, [dariTgl, smpTgl, kodeMenu]);
  React.useEffect(() => {
    grafikPenilaian2(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      kodeMenuPenilaian: kodeMenu2.kodeMenu,
    });
  }, [dariTgl, smpTgl, kodeMenu2]);

  React.useEffect(() => {
    createYear();
    getMenuKantinBaru(dispatch);
  }, []);

  const createYear = () => {
    let date = new Date().getFullYear();
    let tmp = [];
    for (let i = 2; i >= 0; i--) {
      tmp.unshift({ value: date - i, label: (date - i).toString() });
    }
    setListYear(tmp);
  };

  return (
    <>
      <Container fluid>
        <h3> Food Menu Achievement </h3>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAwal(dispatch, {
                    dariTgl: e.toDate().setHours(0, 0, 0, 0),
                  });
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAkhir(dispatch, {
                    smpTgl: e.toDate().setHours(23, 59, 0, 0),
                  });
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {/* {menuKantin.grafikPenilaian1 && ( */}
          <Col md="6">
            <Card>
              <Card.Header>
                <Row>
                  <Col md="4">
                    <Card.Title as="h4">Select Menu :</Card.Title>
                  </Col>
                  <Col>
                    <Select
                      className="react-select primary pb-2"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      onChange={(value) => {
                        setKodeMenu(value);
                      }}
                      value={kodeMenu}
                      options={[
                        {
                          value: "",
                          label: "Choose the Menu",
                          isDisabled: true,
                        },
                        ...listMenu,
                        "-",
                      ]}
                      placeholder="Single Select"
                    />
                  </Col>
                </Row>
              </Card.Header>
              <hr></hr>

              <Card.Body>
                <Row>
                  <Col>
                    {grafikNilai1 && (
                      <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            data={grafikNilai1}
                            margin={{
                              right: 30,
                              left: 50,
                              bottom: 30,
                            }}
                          >
                            <XAxis dataKey="name" />
                            <YAxis>
                              <Label
                                value="Total Rating"
                                offset={-20}
                                angle={-90}
                                position="insideLeft"
                              />
                            </YAxis>
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} />
                            <Bar
                              dataKey="Total Rating"
                              barSize={20}
                              fill="#04ba22"
                              stackId="a"
                            ></Bar>
                            <Bar
                              dataKey="Total Rater"
                              barSize={20}
                              fill="#043dba"
                              stackId="b"
                            ></Bar>
                            {/* <CartesianGrid stroke="#f5f5f5" /> */}
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                    )}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <Row>
                  {/* <Col>
                      <div>Note:</div>
                      <ul>
                        <li>Order must arrive in less than 30 days</li>
                      </ul>
                    </Col> */}
                  <Col className="d-flex justify-content-end">
                    <Button
                      style={{ maxHeight: 40 }}
                      onClick={() => {
                        history.push("/admin/rekapkantin");
                      }}
                      className="btn-wd btn-outline mr-1"
                      type="button"
                      // variant={
                      //   consumable.kpi1.pencapaian < consumable.kpi1.target
                      //     ? "danger"
                      //     : "info"
                      // }
                    >
                      Detail
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          {/* )} */}

          {/* ===================Grafik 2 ========================== */}
          {/* {menuKantin.grafikPenilaian2 && ( */}
          <Col md="6">
            <Card>
              <Card.Header>
                <Row>
                  <Col md="4">
                    <Card.Title as="h4">Select Menu :</Card.Title>
                  </Col>
                  <Col>
                    <Select
                      className="react-select primary pb-2"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      onChange={(value) => {
                        setKodeMenu2(value);
                      }}
                      value={kodeMenu2}
                      options={[
                        {
                          value: "",
                          label: "Choose the Menu",
                          isDisabled: true,
                        },
                        ...listMenu2,
                        "-",
                      ]}
                      placeholder="Single Select"
                    />
                  </Col>
                </Row>
              </Card.Header>
              <hr></hr>

              <Card.Body>
                <Row>
                  <Col>
                    {grafikNilai2 && (
                      <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            data={grafikNilai2}
                            margin={{
                              right: 30,
                              left: 50,
                              bottom: 30,
                            }}
                          >
                            <XAxis dataKey="name" />
                            <YAxis>
                              <Label
                                value="Total Ratings"
                                offset={-20}
                                angle={-90}
                                position="insideLeft"
                              />
                            </YAxis>
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} />
                            <Bar
                              dataKey="Total Rating"
                              barSize={20}
                              fill="#04ba22"
                              stackId="a"
                            ></Bar>
                            <Bar
                              dataKey="Total Rater"
                              barSize={20}
                              fill="#043dba"
                              stackId="b"
                            ></Bar>
                            {/* <CartesianGrid stroke="#f5f5f5" /> */}
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                    )}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button
                      style={{ maxHeight: 40 }}
                      onClick={() => {
                        history.push("/admin/rekapkantin");
                      }}
                      className="btn-wd btn-outline mr-1"
                      type="button"
                    >
                      Detail
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          {/* )} */}
        </Row>
      </Container>
    </>
  );
}

export default detailFoodMenu;
