import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { importAbsensi, getAbsensi, exportAbsensi } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import * as XLSX from "xlsx";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const training = useSelector((state) => state.trainingReducer);
  const [modal, setModal] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [dataAbsen, setDataAbsen] = React.useState([]);
  const [dataJson, setDataJson] = React.useState(null);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));

  // function onsubmit(e) {
  //   e.preventDefault();
  //   let fd = new FormData();
  //   fd.append("file", dataAbsen);
  //   importAbsensi(fd);
  // }

  const history = useHistory();

  React.useEffect(() => {
    let tmp = training.listAbsensi.map((val, index) => {
      // console.log(val);
      return {
        ...val,
        // tglAbsen: moment(val.tanggal).format("DD MMM YYYY"),
        actions:
          // we've added some custom button actions
          val.keterangan == "ijin" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  console.log(val);
                  setModal(true);
                  setDataAbsen(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.keterangan == "absen" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataAbsen(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.keterangan == "cuti" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataAbsen(val);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.keterangan == "sakit" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataAbsen(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataAbsen(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
      };
    });
    setData(tmp);
  }, [training.listAbsensi]);

  React.useEffect(() => {
    getAbsensi(dispatch, {
      dariTgl: moment(dariTgl).format("YYYY-MM-DD"),
      smpTgl: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  const exportAbsen = () => {
    exportAbsensi({
      dariTgl: moment(dariTgl).format("YYYY-MM-DD"),
      smpTgl: moment(smpTgl).format("YYYY-MM-DD"),
      departement: "ifm",
    });
  };

  const exportAbsenHeadCount = () => {
    exportAbsensi({
      dariTgl: moment(dariTgl).format("YYYY-MM-DD"),
      smpTgl: moment(smpTgl).format("YYYY-MM-DD"),
      departement: "headcount",
    });
  };
  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="4">
            <h4 className="title">Export Attendance</h4>
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    // console.log(dariTgl, smpTgl);
                    // console.log(moment(dariTgl).format("DD-MM-YYYY"));
                    exportAbsen();
                  }}
                  className="btn-wd btn-outline mr-1"
                  type="button"
                  variant={"info"}
                >
                  Export IFM
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    // console.log(dariTgl, smpTgl);
                    // console.log(moment(dariTgl).format("DD-MM-YYYY"));
                    exportAbsenHeadCount();
                  }}
                  className="btn-wd btn-outline mr-1"
                  type="button"
                  variant={"info"}
                >
                  Export Headcount
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Uploading the Attendance
        </Button> */}
        <Row>
          <Col md="12">
            <h4 className="title">Personnel Attendence</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Personnel ID",
                      accessor: "nopeg",
                    },
                    {
                      Header: "Name",
                      accessor: "nama_karyawan",
                    },
                    {
                      Header: "Position",
                      accessor: "jabatan",
                    },
                    {
                      Header: "Check-in Date",
                      accessor: "tgl_masuk",
                    },
                    {
                      Header: "Check-in",
                      accessor: "jam_masuk",
                    },
                    {
                      Header: "Check-out Date",
                      accessor: "tgl_pulang",
                    },
                    {
                      Header: "Check-out",
                      accessor: "jam_keluar",
                    },
                    {
                      Header: "Status",
                      accessor: "keterangan",
                    },
                    {
                      Header: "Description",
                      accessor: "deskripsi",
                    },
                    {
                      Header: "action",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail Absensi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  {/* <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Detail Problem</Card.Title>
                    </Card.Header>
                  </Card.Header> */}
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Personnel ID
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataAbsen.nopeg}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Name
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataAbsen.nama_karyawan}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Position
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataAbsen.jabatan}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Check-In Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataAbsen.tgl_masuk}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Check-In
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataAbsen.jam_masuk == null
                          ? "-"
                          : dataAbsen.jam_masuk}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Check-Out Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataAbsen.tgl_pulang}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Check-Out
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataAbsen.jam_keluar == null
                          ? "-"
                          : dataAbsen.jam_keluar}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataAbsen.keterangan}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataAbsen.deskripsi == null
                          ? "-"
                          : dataAbsen.deskripsi}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Documentation
                      </Col>
                    </Row>
                    <Row>
                      {dataAbsen.foto == null ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Documentation is Not Available
                        </Col>
                      ) : (
                        <Col sm="4">
                          <Image
                            src={`https://backoffice.bapguard.com/Public/jti/${dataAbsen.foto}`}
                            style={{
                              width: 200,
                              height: 250,
                              margin: 20,
                            }}
                            thumbnail
                          />
                        </Col>
                      )}
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Uploading the Attendance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>File EXCEL</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataAbsen(e.target.files[0]);
                            }}
                            placeholder="Tuliskan Deskripsi Project"
                            type="file"
                            id="inputFile"
                            accept=".csv"
                            multiple
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={onsubmit}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default ReactTables;
