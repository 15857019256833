import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { daftarFinger } from "../../stores";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Select from "react-select";

var axios = require("axios");

function DaftarFinger() {
  const [karyawanId, setKaryawanId] = React.useState("");
  const [idMesin, setIdMesin] = React.useState("");

  const cekStatus = () => {
    daftarFinger(karyawanId);
  };

  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="survey-form">
          <Card style={{ backgroundColor: "white" }}>
            <Card.Header>
              <Card.Title as="h4">Register Fingerprint</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <Form.Group>
                    <label>Employee ID</label>
                    <Form.Control
                      onChange={(e) => {
                        setKaryawanId(e.target.value);
                        // setDataGedung({
                        //   ...dataGedung,
                        //   namaRuangan: e.target.value,
                        // });
                      }}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <label>ID Finger Machine</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={idMesin.val}
                      onChange={(val) => setIdMesin(val.value)}
                      options={[
                        { value: "Parkir A", label: "Parkir A" },
                        { value: "Parkir B", label: "Parkir B" },
                        { value: "Gedung E", label: "Gedung E" },
                      ]}
                      placeholder="-Choose-"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button
                className="btn-fill pull-right"
                type="submit"
                variant="info"
                onClick={(e) => {
                  e.preventDefault();
                  cekStatus();
                }}
              >
                Submit
              </Button>
            </Card.Body>
          </Card>
          {/* {survey.statusUser ? (
            <>
              <Card style={{ backgroundColor: "white" }}>
                <Card.Header>
                  <Card.Title as="h4">Satisfaction Survey</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm="12">
                      <Form.Label column>
                        Customer Satisfaction Survey
                      </Form.Label>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {pertanyaanArr.map((val, index) => {
                return (
                  <Card style={{ backgroundColor: "white" }} s>
                    <Card.Body>
                      <Row>
                        <Col sm="12">
                          <Form.Label column>
                            {val.urutan}. {val.pertanyaan}
                          </Form.Label>
                        </Col>
                        <Col sm="12">
                          <Form.Group
                            onChange={(e) => {
                              setPertanyaanArr([
                                ...pertanyaanArr.slice(0, index),
                                {
                                  urutan: val.urutan,
                                  pertanyaan: val.pertanyaan,
                                  score: e.target.value,
                                },
                                ...pertanyaanArr.slice(
                                  index + 1,
                                  pertanyaanArr.length
                                ),
                              ]);
                            }}
                          >
                            <Row>
                              <Col>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="60"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Buruk
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      name={`pertanyaan${index}`}
                                      type="radio"
                                      value="100"
                                    ></Form.Check.Input>
                                    <span className=""></span>
                                    Baik
                                  </Form.Check.Label>
                                </Form.Check>
                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                );
              })}
              <Row>
                <Col>
                  <Button
                    className="btn-fill btn-wd"
                    variant="info"
                    onClick={submit}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </>
          ) : null} */}
        </div>

        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default DaftarFinger;
