import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getRekapOrderGudang,
  updateStatusOrderGudang,
  downloadRekapOrderGudang,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";
function ReactTables() {
  const dispatch = useDispatch();
  const consumable = useSelector((state) => state.consumableReducer);
  const [modal, setModal] = React.useState(false);

  const [data, setData] = React.useState([]);
  const [modalData, setModalData] = React.useState({
    nama: "",
    tanggalpesan: "",
    item: [],
    histori: [],
  });
  //   console.log(consumable.orders);
  const [statusOrder, setStatusOrder] = React.useState("");
  const [idOrder, setIdOrder] = React.useState("");
  React.useEffect(() => {
    let tmp = consumable.ordersGudang.map((val) => {
      return {
        item: (() => {
          let item = "";
          val.items.forEach((val2, index2) => {
            item += val2.nama + " " + val2.Qty + "x";
            if (index2 !== val.items.length - 1) {
              item += ", ";
            }
          });
          return item;
        })(),
        divisiOrder: val.divisi,
        // kategori: val.kategori,
        status: val.status,
        tanggalpesan: moment(val.createdAt).format("DD MMM YYYY / h:mm:ss a"),
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                console.log(val);
                setModalData({
                  nama: val.divisi,
                  tanggalpesan: val.createdAt,
                  item: val.items,
                  histori: val.history,
                  status: val.status,
                });
                setModal(true);
                setIdOrder(val._id);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fas fa-marker fa-lg"></i>
            </Button>{" "}
            <Button
              onClick={() => {
                downloadRekapOrderGudang({ id: val._id, kode: val.kode });
                // console.log(val);
                // setModalData({
                //   nama: val.nama,
                //   tanggalpesan: val.createdAt,
                //   item: val.items,
                //   histori: val.history,
                //   status: val.status,
                // });
                // setModal(true);
                // setIdOrder(val._id);
              }}
              variant="danger"
              size="md"
              className="btn-link remove text-danger"
            >
              <i className="fas fa-file-pdf fa-lg"></i>
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [consumable.ordersGudang]);

  React.useEffect(() => {
    getRekapOrderGudang(dispatch);
  }, []);

  function onsubmit(e) {
    e.preventDefault();
    // let fd = new FormData();
    // fd.append("pembinaanId", dataPembinaan._id);
    // fd.append("respon", status);
    // fd.append("alasan", deskripsi);

    updateStatusOrderGudang({
      idOrderGudang: idOrder,
      status: statusOrder,
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Order Recapitulation</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Divisi",
                      accessor: "divisiOrder",
                    },
                    {
                      Header: "Item",
                      accessor: "item",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Order Date",
                      accessor: "tanggalpesan",
                    },
                    {
                      Header: "Detail",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData.status == "Received" ? null : (
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Form.Group>
                      <label>Status</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={statusOrder.val}
                        onChange={(val) => setStatusOrder(val.value)}
                        options={[{ value: "Done", label: "Done" }]}
                        placeholder="-Choose-"
                      />
                    </Form.Group>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={onsubmit}
                    >
                      Submit
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Division:
            </Col>
            <Col sm="9">{modalData.nama}</Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Order Date:
            </Col>
            <Col sm="9">
              {moment(modalData.tanggalpesan).format("DD MMM YYYY / h:mm:ss a")}
            </Col>
          </Row>
          <Row>
            <Col sm="3" style={{ fontWeight: "bold" }}>
              Item :
            </Col>
            <Col sm="9">
              {modalData.item.map((val) => {
                return (
                  <p className="mb-1">
                    {val.nama} - {val.Qty} {val.satuan} - {val.kategori}
                  </p>
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col sm="12" style={{ fontWeight: "bold" }}>
              History :
            </Col>
            <Col sm="12">
              <VerticalTimeline>
                {modalData.histori.map((val) => {
                  return (
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{ background: "#f2f2f2", color: "#2b2b2b" }}
                      contentArrowStyle={{
                        borderRight: "7px solid  rgb(33, 150, 243)",
                      }}
                      date={moment(val.date).format("DD MMM YYYY / h:mm:ss")}
                      iconStyle={{ background: "#dba800", color: "#fff" }}
                      position="right"
                    >
                      <h4 className="vertical-timeline-element-subtitle">
                        {val.status}
                      </h4>
                    </VerticalTimelineElement>
                  );
                })}
              </VerticalTimeline>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
