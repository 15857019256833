import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getChecklistRuanganById,
  getPertanyaan,
  kirimHasilSurvey,
  kirimKeluhan,
} from "../../stores";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import DataTable from "react-data-table-component";

function LoginPage() {
  const dispatch = useDispatch();
  let query = new URLSearchParams(useLocation().search);
  const survey = useSelector((state) => state.surveyReducer);
  const [nama, setNama] = React.useState("");
  const [noHp, setNoHp] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [gambar, setGambar] = React.useState([]);
  const [dataShift, setDataShift] = React.useState([]);
  const submit = () => {
    kirimKeluhan({ nama, noHp, deskripsi, gambar, kode: query.get("kode") });
  };

  React.useEffect(() => {
    getChecklistRuanganById(dispatch, { idRuangan: query.get("kode") });
  }, []);

  console.log(survey, "???????????????????????????");
  React.useEffect(() => {
    let tmp =
      survey.ruanganChecklistById &&
      survey.ruanganChecklistById.map((val, index) => {
        // console.log(val, "s1");
        return { ...val, jamShift: val.jamShift == 11 ? "Shift 1" : "Shift 2" };
      });
    setDataShift(tmp);
  }, [survey.ruanganChecklistById]);
  const columns = [
    {
      name: "Shift",
      width: "200px",
      sortable: true,
      selector: (row) => row.jamShift,
    },
    {
      name: "Gedung",
      // width: "200px",
      sortable: true,
      selector: (row) => row.gedung,
    },
    {
      name: "Ruangan",
      sortable: true,
      // width: "150px",
      selector: (row) => row.area,
    },
    {
      name: "Status",
      sortable: true,
      width: "150px",
      selector: (row) => row.status,
    },
  ];

  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="survey-form">
          <Card style={{ backgroundColor: "white" }}>
            <Card.Body>
              <Row>
                <Col md="12">
                  <Card className="stacked-form">
                    <Card.Header>
                      <Card.Title as="h4">Form Keluhan</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form action="#" method="#">
                        <Form.Group>
                          <label>Nama Lengkap</label>
                          <Form.Control
                            placeholder="Masukan nama lengkap anda"
                            type="text"
                            value={nama}
                            onChange={(e) => {
                              setNama(e.target.value);
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Form>
                      <Form action="#" method="#">
                        <Form.Group>
                          <label>Nomor HP</label>
                          <Form.Control
                            placeholder="Masukan nomor hp anda tanpa angka depan (0)"
                            type="text"
                            value={noHp}
                            onChange={(e) => {
                              setNoHp(e.target.value);
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Form>
                      <Form action="#" method="#">
                        <Form.Group>
                          <label>Deskripsi Keluhan</label>
                          <Form.Control
                            placeholder="Tuliskan penjelasan masalah terkait keluhan anda"
                            type="text"
                            value={deskripsi}
                            onChange={(e) => {
                              setDeskripsi(e.target.value);
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Form>
                      <label
                        className="label-input-file btn btn-danger text-white mb-4"
                        for="import2"
                      >
                        Tambahkan Gambar
                        <input
                          type="file"
                          id="import2"
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                          onChange={(e) => {
                            setGambar([
                              ...gambar,
                              ...Array.from(e.target.files),
                            ]);
                          }}
                          style={{ display: "none" }}
                          accept="image/*"
                          multiple
                        />
                      </label>
                      {gambar.map((val, index) => {
                        return (
                          <div className="d-flex align-items-start">
                            <p>{val.name}</p>
                            <Button
                              onClick={() => {
                                setGambar([
                                  ...gambar.slice(0, index),
                                  ...gambar.slice(index + 1, gambar.length),
                                ]);
                              }}
                              variant="danger"
                              size="sm"
                              className="btn-link remove text-danger"
                            >
                              <i className="fa fa-times" />
                            </Button>
                          </div>
                        );
                      })}
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          submit();
                        }}
                        className="btn-fill"
                        type="submit"
                        variant="info"
                      >
                        Submit
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <DataTable columns={columns} data={dataShift} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>

        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
