import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  Label,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import {
  getKpi1Training,
  getKpi2Pembinaan,
  getKpi3Absensi,
  updateTglAwal,
  updateTglAkhir,
} from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const trainingPersonnel = useSelector((state) => state.trainingReducer);
  const auth = useSelector((state) => state.authReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(auth.dariTgl);
  const [smpTgl, setSmpTgl] = React.useState(auth.smpTgl);
  const [kpi1Option, setKpi1Option] = React.useState(null);
  const [kpi2Option, setKpi2Option] = React.useState(null);
  const [kpi3Option, setKpi3Option] = React.useState(null);

  React.useEffect(() => {
    if (trainingPersonnel.kpi1) {
      setKpi1Option(trainingPersonnel.kpi1.data);
    }
  }, [trainingPersonnel.kpi1]);
  React.useEffect(() => {
    if (trainingPersonnel.kpi2) {
      setKpi2Option(trainingPersonnel.kpi2.data);
    }
  }, [trainingPersonnel.kpi2]);
  React.useEffect(() => {
    if (trainingPersonnel.kpi3) {
      setKpi3Option(trainingPersonnel.kpi3.data);
    }
  }, [trainingPersonnel.kpi3]);
  React.useEffect(() => {
    getKpi1Training(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2Pembinaan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi3Absensi(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
  }, [dariTgl, smpTgl]);

  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAwal(dispatch, {
                    dariTgl: e.toDate().setHours(0, 0, 0, 0),
                  });
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAkhir(dispatch, {
                    smpTgl: e.toDate().setHours(23, 59, 0, 0),
                  });
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        {trainingPersonnel.kpi3 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">
                    Personnel Attendance Achievement
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Masuk</p>
                          <Card.Title as="h4">
                            {Math.round(
                              trainingPersonnel.kpi3.pencapaian[0].Masuk * 100
                            )}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Sakit</p>
                          <Card.Title as="h4">
                            {Math.round(
                              trainingPersonnel.kpi3.pencapaian[0].Sakit * 100
                            )}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Cuti</p>
                          <Card.Title as="h4">
                            {Math.round(
                              trainingPersonnel.kpi3.pencapaian[0].Cuti * 100
                            )}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Ijin</p>
                          <Card.Title as="h4">
                            {Math.round(
                              trainingPersonnel.kpi3.pencapaian[0].Ijin * 100
                            )}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Alpha</p>
                          <Card.Title as="h4">
                            {Math.round(
                              trainingPersonnel.kpi3.pencapaian[0].Alpha * 100
                            )}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {Math.round(
                              trainingPersonnel.kpi3.achievement * 100
                            )}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {trainingPersonnel.kpi3.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi3Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              data={kpi3Option}
                              margin={{
                                right: 30,
                                left: 50,
                                bottom: 30,
                              }}
                            >
                              <XAxis dataKey="nama" />
                              <YAxis>
                                <Label
                                  value="Employee"
                                  offset={-20}
                                  angle={-90}
                                  position="insideLeft"
                                />
                              </YAxis>
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Masuk"
                                barSize={20}
                                fill="#04ba22"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Sakit"
                                barSize={20}
                                fill="#043dba"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Cuti"
                                barSize={20}
                                fill="#ff9d00"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Ijin"
                                barSize={20}
                                fill="#fc2828"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Alpha"
                                barSize={20}
                                fill="#ab0202"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col>
                      <div>Note:</div>
                      <ul>
                        <li>
                          Masuk : Hadir, Finger tidak terdeteksi, Ijin terlambat
                          dengan pemberitahuan
                        </li>
                        <li>Cuti : Cuti tahunan, Cuti khusus</li>
                        <li> Sakit : Sakit dengan surat keterangan dokter </li>
                        <li>
                          Ijin : Ijin pribadi diluar cuti, Sakit tanpa surat
                          keterangan dokter
                        </li>
                        <li>Alpha : Tidak ada pemberitahuan</li>
                      </ul>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        style={{ maxHeight: 40 }}
                        onClick={() => {
                          history.push("/admin/absen");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          trainingPersonnel.kpi3.pencapaian <
                          trainingPersonnel.kpi3.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
        {auth.username !== "HR JTI" ? (
          <>
            {trainingPersonnel.kpi1 && (
              <Row>
                <Col>
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Training Achievement</Card.Title>
                      {/* <p className="card-category">Bootstrap default style</p> */}
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <div className="numbers d-flex justify-content-center">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">Achievement</p>
                              <Card.Title as="h4">
                                {trainingPersonnel.kpi1.pencapaian} %
                              </Card.Title>
                            </div>
                            <div className="d-flex flex-column align-items-center ml-3">
                              <p className="card-category">Target</p>
                              <Card.Title as="h4">
                                {trainingPersonnel.kpi1.target} %
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {kpi1Option && (
                            <div style={{ width: "100%", height: 300 }}>
                              <ResponsiveContainer>
                                <ComposedChart
                                  data={kpi1Option}
                                  margin={{
                                    right: 30,
                                    left: 50,
                                    bottom: 30,
                                  }}
                                >
                                  <XAxis dataKey="nama" />
                                  <YAxis>
                                    <Label
                                      value="Total Training"
                                      offset={-20}
                                      angle={-90}
                                      position="insideLeft"
                                    />
                                  </YAxis>
                                  <Tooltip />
                                  <Legend verticalAlign="top" height={36} />

                                  <CartesianGrid stroke="#f5f5f5" />
                                  <Bar
                                    dataKey="On Time"
                                    barSize={20}
                                    fill="#006605"
                                    stackId="a"
                                  ></Bar>
                                  <Bar
                                    dataKey="Not Ontime"
                                    barSize={20}
                                    fill="#ff002f"
                                    stackId="a"
                                  ></Bar>
                                </ComposedChart>
                              </ResponsiveContainer>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <hr></hr>
                      <Row>
                        <Col>
                          <div>Note:</div>
                          <ul>
                            <li>
                              Training must be completed before the deadline
                            </li>
                          </ul>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <Button
                            style={{ maxHeight: 40 }}
                            onClick={() => {
                              history.push("/admin/training");
                            }}
                            className="btn-wd btn-outline mr-1"
                            type="button"
                            variant={
                              trainingPersonnel.kpi1.pencapaian <
                              trainingPersonnel.kpi1.target
                                ? "danger"
                                : "info"
                            }
                          >
                            Detail
                          </Button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            )}

            {trainingPersonnel.kpi2 && (
              <Row>
                <Col>
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Mentoring Achievement</Card.Title>
                      {/* <p className="card-category">Bootstrap default style</p> */}
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <div className="numbers d-flex justify-content-center">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">Achievement</p>
                              <Card.Title as="h4">
                                {trainingPersonnel.kpi2.pencapaian} %
                              </Card.Title>
                            </div>
                            <div className="d-flex flex-column align-items-center ml-3">
                              <p className="card-category">Target</p>
                              <Card.Title as="h4">
                                {trainingPersonnel.kpi2.target} %
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="numbers d-flex justify-content-center">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">Achievement</p>
                              <Card.Title as="h4">
                                {trainingPersonnel.kpi2.kejadian} %
                              </Card.Title>
                            </div>
                            <div className="d-flex flex-column align-items-center ml-3">
                              <p className="card-category">Target</p>
                              <Card.Title as="h4">
                                {trainingPersonnel.kpi2.target} %
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          {kpi2Option && (
                            <div style={{ width: "100%", height: 300 }}>
                              <ResponsiveContainer>
                                <ComposedChart
                                  data={kpi2Option}
                                  margin={{
                                    right: 30,
                                    left: 50,
                                    bottom: 30,
                                  }}
                                >
                                  <XAxis dataKey="nama" />
                                  <YAxis>
                                    <Label
                                      value="Mentoring"
                                      offset={-20}
                                      angle={-90}
                                      position="insideLeft"
                                    />
                                  </YAxis>
                                  <Tooltip />
                                  <Legend verticalAlign="top" height={36} />

                                  <CartesianGrid stroke="#f5f5f5" />
                                  <Bar
                                    dataKey="On Time"
                                    barSize={20}
                                    fill="#006605"
                                    stackId="a"
                                  ></Bar>
                                  <Bar
                                    dataKey="Not Ontime"
                                    barSize={20}
                                    fill="#ff002f"
                                    stackId="a"
                                  ></Bar>
                                </ComposedChart>
                              </ResponsiveContainer>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <hr></hr>
                      <Row>
                        <Col>
                          <div>Note:</div>
                          <ul>
                            <li>
                              Mentoring must be completed before the deadline
                            </li>
                          </ul>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <Button
                            style={{ maxHeight: 40 }}
                            onClick={() => {
                              history.push("/admin/pembinaan");
                            }}
                            className="btn-wd btn-outline mr-1"
                            type="button"
                            variant={
                              trainingPersonnel.kpi2.pencapaian <
                              trainingPersonnel.kpi2.target
                                ? "danger"
                                : "info"
                            }
                          >
                            Detail
                          </Button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        ) : null}
      </Container>
    </>
  );
}

export default Panels;
