import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getAreaNonGroup(dispatch) {
  baseAxios
    .get("/lokasi/getcategoryarea", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({
        type: "SET_LISTAREA",
        data: respon.data,
      });
      Swal.close();
    })
    .catch(errorHandler);
}
export function getTimeLine(dispatch, data) {
  baseAxios
    .get(`/keluhan/gettimeline?id=${data}`, {})
    .then(async (respon) => {
      dispatch({
        type: "SET_TIMELINE",
        data: respon.data,
      });
    })
    .catch(errorHandler);
}
export function daftarlokasi(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/lokasi/daftargedung", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function daftarruangan(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/lokasi/daftarruangan", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function editKodeRuangan(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .patch("/lokasi/editkoderuangan", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getShift(dispatch) {
  baseAxios
    .get("/shift/getshiftall", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_JAMLAPORAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function daftarShift(data) {
  // var data = {
  //   jam: "[12, 13, 14]",
  //   durasi: "2",
  // };
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/shift/daftarShift", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}
export function deleteShift(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .delete("/shift/deleteshift", {
      data: { _id: data },
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}
export function deleteGedung(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      // console.log(data.visitId);
      baseAxios
        .delete("/lokasi/deletegedung", {
          data: { gedungId: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);

      // Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });
  // Swal.fire({
  //   title: "Tunggu ...",
  //   didOpen() {
  //     Swal.showLoading();
  //   },
  //   allowOutsideClick: false,
  //   allowEscapeKey: false,
  //   allowEnterKey: false,
  // });
  // baseAxios
  //   .delete("/lokasi/deletegedung", {
  //     data: { gedungId: data },
  //     headers: { token: localStorage.getItem("tokenjti") },
  //   })
  //   .then(async (respon) => {
  //     Swal.fire({
  //       title: respon.data.message,
  //       icon: "success",
  //     }).then(() => {
  //       window.location.reload();
  //     });
  //   })
  //   .catch(errorHandler);
}

export function deleteRuang(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      // console.log(data.visitId);
      baseAxios
        .delete("/lokasi/deletearea", {
          data: { ruanganId: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);

      // Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });

  // Swal.fire({
  //   title: "Tunggu ...",
  //   didOpen() {
  //     Swal.showLoading();
  //   },
  //   allowOutsideClick: false,
  //   allowEscapeKey: false,
  //   allowEnterKey: false,
  // });
  // baseAxios
  //   .delete("/lokasi/deletearea", {
  //     data: { ruanganId: data },
  //     headers: { token: localStorage.getItem("tokenjti") },
  //   })
  //   .then(async (respon) => {
  //     Swal.fire({
  //       title: respon.data.message,
  //       icon: "success",
  //     }).then(() => {
  //       window.location.reload();
  //     });
  //   })
  //   .catch(errorHandler);
}

export function getLaporanRekap(dispatch, data) {
  baseAxios
    .get(
      `/laporan/historylaporan?tglmulai=${data.mulai}&tglselesai=${data.selesai}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_LISTLAPORAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function laporkanSelesai(data) {
  baseAxios
    .post(`/laporan/laporanselesai`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getvisittotalhariini(dispatch) {
  baseAxios
    .get(`/laporan/getvisithariini`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_TOTALVISITHARIINI", data: respon.data });
    })
    .catch(errorHandler);
}

export function getvisitproblemtotal(dispatch) {
  baseAxios
    .get(`/laporan/getvisitproblem`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_TOTALPROBLEM", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpiMonitoringProblem(dispatch, data) {
  baseAxios
    .get(
      `/laporan/pelaporankebersihan?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      // `/laporan/getkpimonitoring1?tglmulai=${data.mulai}&tglselesai=${data.selesai}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then((respon) => {
      dispatch({ type: "SET_KPI1", data: respon.data });
    })
    .catch(errorHandler);
}

export function deleteLaporanKunjungan(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      // console.log(data.visitId);
      baseAxios
        .delete("/laporan/deletlaporan", {
          data: { laporanId: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);
    }
  });
}

export function getKpiMonitoringKeluhan(dispatch, data) {
  baseAxios
    .get(`/keluhan/keluhangrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_KPI2", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpiMonitoringProject(dispatch, data) {
  baseAxios
    .get(
      `/project/getprojectgrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI4", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpiMonitoringKunjungan(dispatch, data) {
  baseAxios
    .get(
      `/laporan/getgrafikkunjungan?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI3", data: respon.data });
    })
    .catch(errorHandler);
}

export function exportBandU(data) {
  baseAxios
    .get(`/laporan/getexportbandu?dari=${data.dariTgl}&sampai=${data.smpTgl}`, {
      headers: { token: localStorage.getItem("tokenjti") },
      responseType: "blob",
    })
    .then(async (respon) => {
      // console.log(respon);
      const url = window.URL.createObjectURL(new Blob([respon.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `B&U ${moment(data.dariTgl).format("DD MMM YYYY")}-${moment(
          data.smpTgl
        ).format("DD MMM YYYY")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      Swal.close();
    })
    .catch(errorHandler);
}

export function getRekapProblem(dispatch) {
  baseAxios
    .get("/laporan/rekapproblem", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_REKAPPROBLEM", data: respon.data });
    })
    .catch(errorHandler);
}

export function getRekapLaporan(dispatch) {
  baseAxios
    .get("/laporan/getrekaplaporan", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_REKAPLAPORAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function getRekapKeluhan(dispatch) {
  baseAxios
    .get("/keluhan/getkeluhanall", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_REKAPKELUHAN", data: respon.data });
    })
    .catch(errorHandler);
}

// Project
export function daftarKategoriProject(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/project/daftarprojectkategory", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKategori(dispatch) {
  baseAxios
    .get("/project/getprojectkategory", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTKATEGORI", data: respon.data });
    })
    .catch(errorHandler);
}

export function pengajuanProject(data) {
  baseAxios
    .post(`/project/ajukanproject`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function verifikasiProject(data) {
  baseAxios
    .patch(`/project/responproject`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getProjectAll(dispatch) {
  baseAxios
    .get(`/project/getprojectall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_PROJECTALL", data: respon.data });
    })
    .catch(errorHandler);
}

export function downloadFile(data) {
  baseAxios
    .get(`/project/downloaddokumen?file=` + data, {
      responseType: "blob",
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      const url = window.URL.createObjectURL(new Blob([respon.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data);
      document.body.appendChild(link);
      link.click();
    })
    .catch(errorHandler);
}

export function kirimKeluhan(data) {
  if (!data.nama || !data.noHp || !data.deskripsi) {
    Swal.fire({
      title: "Harap lengkapi form terlebih dahulu",
      icon: "error",
    });
  } else {
    Swal.fire({
      title: "Loading ...",
      didOpen() {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    let formData = new FormData();

    formData.append("nama", data.nama);
    formData.append("noHp", data.noHp);
    formData.append("koderuangan", data.kode);
    formData.append("deskripsi", data.deskripsi);
    data.gambar.map((val, index) => {
      formData.append("dokumentasiAwalArr", val);
    });
    baseAxios
      .post(`/keluhan/daftarkeluhanbakalan`, formData, {
        headers: { token: localStorage.getItem("tokenjti") },
      })
      .then(async (respon) => {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(errorHandler);
  }
}
export function downloadPDF(data, ruangan) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .get(`/lokasi/getpdfruangan?ruanganId=${data}`, {
      headers: { token: localStorage.getItem("tokenjti") },
      responseType: "blob",
    })
    .then(async (respon) => {
      console.log(respon);
      Swal.close();
      const file = new Blob([respon.data], { type: "application/pdf" });
      console.log(file);
      const url = window.URL.createObjectURL(file);
      // window.open(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `QR${ruangan}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(errorHandler);
}
export function downloadQR(data, ruangan) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .get(`/lokasi/getqrruangan?ruanganId=${data}`, {
      headers: { token: localStorage.getItem("tokenjti") },
      responseType: "blob",
    })
    .then(async (respon) => {
      console.log(respon);
      Swal.close();
      const file = new Blob([respon.data], { type: "application/pdf" });
      console.log(file);
      const url = window.URL.createObjectURL(file);
      // window.open(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `QR${ruangan}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(errorHandler);
}

export function getCalendar(dispatch) {
  baseAxios
    .get("/project/getprojectcalendar", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_CALENDAR", data: respon.data });
    })
    .catch(errorHandler);
}

export function assignRoomToShift(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .patch("/shift/assignshifttoroom", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getProposal(dispatch) {
  baseAxios
    .get(`/proposal/getproposal`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_PROPOSAL", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiProposal(data) {
  baseAxios
    .patch(`/proposal/responproposal`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function daftarHariLibur(data) {
  // console.log(">>>>>>>>>>>>.", data);
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/shift/addevent", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getCalendarPatroli(dispatch) {
  baseAxios
    .get("/shift/getevent", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_CALENDARPATROLI", data: respon.data });
    })
    .catch(errorHandler);
}
export function deletedJadwalPatroli(data) {
  console.log(data);
  Swal.fire({
    title: `Are you sure delete ${data.nama}?`,
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      // console.log(data.visitId);
      baseAxios
        .delete("/shift/deletejadwallibur", {
          data: { idJadwal: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);
    }
  });
}
