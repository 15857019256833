import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Tab,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";
import {
  daftarGedungChecklist,
  daftarRuanganChecklist,
  deleteGedungChecklist,
  deleteRuanganChecklist,
  getGedungChecklist,
  getRuanganByGedung,
  getShift,
  downloadQRChecklist,
  editKodeRuanganChecklist,
  getHasilChecklist,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function TambahGedungChecklist() {
  const dispatch = useDispatch();
  const date = new Date();
  const hasilGedungChekclist = useSelector(
    (state) => state.gedungChecklistReducer
  );

  const [gedungChecklist, setGedungChecklist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [modalDetailChecklist, setModalDetailChecklist] = React.useState(false);
  const [detailCKGedung, setDetailCKGedung] = React.useState([]);
  const [namaGedung, setNamaGedung] = React.useState("");

  const [idKodeRuanganCheckilst, setIdKodeRuanganCheckilst] =
    React.useState("");

  React.useEffect(() => {
    getRuanganByGedung(dispatch);
    getShift(dispatch);
    getHasilChecklist(dispatch);
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  React.useEffect(() => {
    let tmp =
      hasilGedungChekclist.MonitoringChecklist &&
      hasilGedungChekclist.MonitoringChecklist.map((val) => {
        return {
          ...val,
          gedung: val._id.namaGedung,
          tanggal: val._id.tanggal,
          action: (
            <div className="actions-right">
              <Button
                className="mr-2"
                variant="info"
                style={{
                  marginTop: 5,
                  padding: "6px 15px",
                  fontSize: "16px",
                }}
                onClick={() => {
                  setModalDetailChecklist(true);
                  setDetailCKGedung(val.hasilChecklist);
                  setNamaGedung(val._id.namaGedung);
                }}
              >
                Detail
              </Button>
            </div>
          ),
        };
      });
    setGedungChecklist(tmp);
    //=====================Detailllllll==============
  }, [hasilGedungChekclist.MonitoringChecklist]);

  const customStyles = {
    rows: {
      style: {
        // height: 100, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#FFFFFF",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
      },
    },
    cells: {
      style: {
        minHeight: 50,
      },
    },
  };

  const colHasilChecklist = [
    {
      name: "Name Building",
      minWidth: "280px",
      center: true,
      selector: (row) => row.gedung,
    },

    {
      name: "Date",
      minWidth: "280px",
      center: true,
      selector: (row) => moment(row.tanggal).format("DD MMMM YYYY"),
    },

    {
      name: "Information",
      minWidth: "280px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  const colDetailChecklist = [
    {
      name: "Name Room",
      minWidth: "180px",
      center: true,
      selector: (row) => row.namaRuangan,
    },
    {
      name: "Checklist Type",
      minWidth: "180px",
      center: true,
      selector: (row) => row.jenisChecklist,
    },
    {
      name: "Monitoring Results",
      minWidth: "210px",
      center: true,
      selector: (row) =>
        row.catatan === "B"
          ? "Bersih/Baik"
          : row.catatan === "K"
          ? "Kotor"
          : row.catatan === "R"
          ? "Rusak"
          : "Tidak Ada Keterangan",
    },
    {
      name: "Name Checker",
      minWidth: "180px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Date",
      minWidth: "180px",
      center: true,
      selector: (row) => moment(row.tanggal).format("DD MMMM YYYY"),
    },
    // {
    //   name: "Time",
    //   minWidth: "180px",
    //   center: true,
    //   selector: (row) => row.jam,
    // },

    {
      name: "Documentation",
      minWidth: "180px",
      center: true,
      selector: (row) =>
        row.foto[0] === "" ? (
          "-"
        ) : (
          <div className="actions-right">
            <Button
              className="mr-2"
              variant="info"
              style={{
                marginTop: 5,
                padding: "6px 15px",
                fontSize: "14px",
              }}
              onClick={() => {
                setModalPreviewFoto(row.foto);
              }}
            >
              Preview
            </Button>
          </div>
        ),
    },
    {
      name: "Information",
      minWidth: "180px",
      center: true,
      selector: (row) => (row.keterangan === "" ? "-" : row.keterangan),
    },
  ];

  return (
    <>
      <Container fluid>
        <h3> Recapitulation Building Checklist</h3>

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <Col
                  md={"12"}
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <div>
                    <Form.Control
                      style={{
                        marginLeft: 10,
                      }}
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Cari Berdasarkan Nama Gedung"
                    />
                  </div>
                </Col>

                <DataTable
                  columns={colHasilChecklist}
                  data={gedungChecklist.filter((row) =>
                    row.gedung.toLowerCase().includes(searchText.toLowerCase())
                  )}
                  customStyles={customStyles}
                  pagination
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>

      <Modal
        size="lg"
        show={modalDetailChecklist}
        onHide={() => {
          setModalDetailChecklist(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">
                        Detail Checklist{" "}
                        <p style={{ fontSize: 20, marginTop: 10 }}>
                          Building : {namaGedung}
                        </p>
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <DataTable
                      columns={colDetailChecklist}
                      data={detailCKGedung}
                      customStyles={customStyles}
                      pagination
                    />

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TambahGedungChecklist;
