import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTimeLine } from '../../stores';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from 'react-bootstrap';

function LoginPage() {
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  let query = new URLSearchParams(useLocation().search);
  React.useEffect(() => {
    getTimeLine(dispatch, query.get('id'));
  }, []);
  return (
    <>
      <div
        className='full-page section-image'
        data-color='black'
        data-image={require('assets/img/full-screen-image-2.jpg').default}
      >
        {monitoring.timeline && (
          <div className='survey-form'>
            <Card>
              <Card.Header>
                <Card.Title as='h4'>Progress Keluhan</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm='12'>
                    <p
                      className='mb-1'
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      Area {monitoring.timeline.ruangan.area}, Gedung{' '}
                      {monitoring.timeline.gedung.gedung}
                    </p>
                    <p className='mb-1' style={{ fontSize: '12px' }}>
                      {monitoring.timeline.deskripsi}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <VerticalTimeline>
              {monitoring.timeline.progress.map((val, index) => {
                return (
                  <VerticalTimelineElement
                    className='vertical-timeline-element--work'
                    contentStyle={{
                      background: 'white',
                      color: '#fff',
                    }}
                    contentArrowStyle={{
                      borderRight: '7px solid  rgb(33, 150, 243)',
                    }}
                    date={moment(val.date).format('DD MMMM YYYY')}
                    iconStyle={{
                      background:
                        val.title === 'On Progress'
                          ? 'rgb(33, 150, 243)'
                          : val.title === 'Telah dikunjungi'
                          ? 'rgb(245, 191, 66)'
                          : 'rgb(111, 224, 63)',
                      color: '#fff',
                    }}
                    // icon={<WorkIcon />}
                  >
                    <h3
                      className='vertical-timeline-element-title'
                      style={{ color: 'black' }}
                    >
                      {val.title}
                    </h3>
                    <p
                      className='vertical-timeline-element-subtitle'
                      style={{ color: 'black' }}
                    >
                      {val.deskripsi}
                    </p>
                    <p style={{ color: 'black' }}>
                      {moment(val.date).format(' h:mm:ss')}
                    </p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>
        )}

        <div
          className='full-page-background'
          style={{
            backgroundImage:
              'url(' +
              require('assets/img/full-screen-image-2.jpg').default +
              ')',
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
