import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export function getPertanyaan(dispatch) {
  baseAxios
    .get("/kuisioner/getsurveyquestion", {})
    .then(async (respon) => {
      dispatch({
        type: "SET_PERTANYAAN",
        data: respon.data,
      });
    })
    .catch(errorHandler);
}

export function kirimHasilSurvey(data) {
  console.log(data);
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  let valid = true;
  data.pertanyaanArr.forEach((element) => {
    if (!element.score) {
      valid = false;
    }
  });
  if (valid) {
    baseAxios
      .post("/kuisioner/lakukansurvey", {
        pertanyaanArr: JSON.stringify(data.pertanyaanArr),
        nama: data.nama,
        karyawanId: data.karyawanId,
        deskripsi: data.deskripsi,
      })
      .then(async (respon) => {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(errorHandler);
  } else {
    Swal.fire({
      title: "Harap isi semua pertanyaan terlebih dahulu!",
      icon: "error",
    });
  }
}

export function deletePertanyaan(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .delete("/kuisioner/hapuspertanyaan", {
      data: { pertanyaanId: data },
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function simpanPertanyaan(data) {
  console.log(data, ">>>>>>>>>>>>>>>");
  let valid = true;
  data.forEach((element) => {
    if (!element.pertanyaan) {
      valid = false;
    }
  });

  if (valid) {
    baseAxios
      .post(
        "/kuisioner/daftarpertanyaan",
        {
          pertanyaanArr: JSON.stringify(data),
        },
        {
          headers: { token: localStorage.getItem("tokenjti") },
        }
      )
      .then(async (respon) => {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(errorHandler);
  } else {
    Swal.fire({
      title:
        "Harap isi semua pertanyaan terlebih dahulu atau hapus pertanyaan tersebut!",
      icon: "error",
    });
  }
}

export function getKpiMonitoringSurvey(dispatch, data) {
  baseAxios
    .get(`/kuisioner/grafikkuisioner?tahun=${data.tahun}`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then((respon) => {
      dispatch({ type: "SET_VARIABLESURVEY", data: respon.data });
    })
    .catch(errorHandler);
}

export function getRekapSurvey(dispatch) {
  baseAxios
    .get("/kuisioner/getallkuisioner", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_REKAPSURVEY", data: respon.data });
    })
    .catch(errorHandler);
}

export function cekStatusUser(dispatch, data) {
  if (data.nama == "" || data.karyawanId == "") {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Name and Employee ID is not be Empty!",
    });
  } else {
    baseAxios
      .post("/kuisioner/cekidusersurvey", data)
      .then(async (respon) => {
        // console.log(respon.data.length);
        if (respon.data.length != 0) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You have filled out the survey!",
          });
        } else {
          dispatch({ type: "SET_STATUS", data: true });
        }
        // dispatch({ type: "SET_STATUS", data: true });
      })
      .catch(errorHandler);
  }
}

export function daftarFinger(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/finger/daftarFinger", { nopeg: data })
    .then((respon) => {
      console.log(respon);
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function downloadHasilKuisionerExcel(data) {
  console.log("data", data);
  baseAxios
    .get("/kuisioner/downloadhasilsurvey", {
      params: { periode: data.periode },
      headers: { token: localStorage.getItem("tokenjti") },
      responseType: "blob",
    })
    .then(async (respon) => {
      // console.log(respon);
      const url = window.URL.createObjectURL(new Blob([respon.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Kuisioner.xlsx`);
      document.body.appendChild(link);
      link.click();
      Swal.close();
    })
    .catch(() => {
      console.log("gagal");
    });
}

export function getListRuanganChecklist(dispatch, data) {
  baseAxios
    .get(`/kuisioner/getruanganchecklist`, {})
    .then(async (respon) => {
      console.log("checklist", respon);
      dispatch({
        type: "SET_LISTRUANGANCHECKLIST",
        data: respon.data.data,
      });
    })
    .catch(errorHandler);
}

export function laporkanChecklist(dispatch, data) {
  let formData = new FormData();
  formData.append("dilaporkanOleh", data.nama);
  formData.append("idShift", data.idShifts);
  formData.append("idChecklist", data.idChekclist);
  formData.append("pertanyaanArr", JSON.stringify(data.jawabanChecklist));
  formData.append("keterangan", data.deskripsi);
  data.gambarSebelum.map((val, index) => {
    formData.append("dokumentasiAwalArr", val);
  });
  data.gambarSesudah.map((val, index) => {
    formData.append("dokumentasiAkhirArr", val);
  });

  baseAxios
    .post(`/kuisioner/laporanChecklist`, formData)
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getListRuanganChecklistShift1(dispatch) {
  baseAxios
    .get("/kuisioner/getruanganchecklistshift1", {})
    .then(async (respon) => {
      dispatch({
        type: "SET_LISTRUANGANCHECKLISTSHIFT1",
        data: respon.data.data,
      });
    })
    .catch(errorHandler);
}

export function getListRuanganChecklistShift2(dispatch) {
  baseAxios
    .get("/kuisioner/getruanganchecklistshift2", {})
    .then(async (respon) => {
      dispatch({
        type: "SET_LISTRUANGANCHECKLISTSHIFT2",
        data: respon.data.data,
      });
    })
    .catch(errorHandler);
}

export function getChecklistRuanganById(dispatch, data) {
  baseAxios
    .get(`/kuisioner/getChecklistRuanganById?idRuangan=${data.idRuangan}`, {})
    .then(async (respon) => {
      dispatch({
        type: "SET_RUANGANCHECKLISTBYID",
        data: respon.data.data,
      });
    })
    .catch(errorHandler);
}
