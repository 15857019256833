import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAreaById,
  getHasilChecklistByDate,
  sendDailyChecklist,
} from "../../stores";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import HalamanChecklist from "./HalamanChecklist";
import { Label } from "reactstrap";
import DataTable from "react-data-table-component";
import gedungChecklistReducer from "stores/gedungchecklist";
import moment from "moment";

function LoginPage() {
  const dispatch = useDispatch();
  // const survey = useSelector((state) => state.surveyReducer);
  const formChecklist = useSelector((state) => state.gedungChecklistReducer);
  const ModalDetailChecklist = useSelector(
    (state) => state.gedungChecklistReducer
  );
  let query = new URLSearchParams(useLocation().search);

  const [nama, setNama] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [showForm, setShowForm] = React.useState(false);
  const [disableName, setDisableName] = React.useState(false);
  const [listRuangArr, setListRuangArr] = React.useState([]);
  const [namaGedung, setNamaGedung] = React.useState("");
  const [namaRuang, setNamaRuang] = React.useState("");
  const [modalDetail, setModalDetail] = React.useState(false);
  const [hasilChecklist, setHasilChecklist] = React.useState([]);
  const [detailChecklist, setDetailChecklist] = React.useState([]);

  React.useEffect(() => {
    getAreaById(dispatch, query.get("idRuangan"));
  }, []);
  React.useEffect(() => {
    getHasilChecklistByDate(dispatch, {
      idGedung:
        formChecklist.listRuangById.response === undefined
          ? ""
          : formChecklist.listRuangById.response &&
            formChecklist.listRuangById.response[0].idGedungChecklist,
      idRuangan:
        formChecklist.listRuangById.response === undefined
          ? ""
          : formChecklist.listRuangById.response &&
            formChecklist.listRuangById.response[0]._id,
    });
  }, [formChecklist.listRuangById.response]);
  const cekStatus = () => {
    if (nama == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Name is not be Empty!",
      });
    } else {
      setShowForm(true);
      setDisableName(true);
    }
  };
  const submit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Apakah anda yakin mengirim data checklist?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        let tmp = [];
        listRuangArr.map((val) => {
          tmp.push({ ...val, nama: nama });
        });
        tmp.map((val) => {
          sendDailyChecklist({
            idGedung: val.idGedung,
            idRuangan: val.idRuangan,
            nama: val.nama,
            catatan: val.catatan,
            keterangan: val.keterangan,
            foto: val.foto,
          });
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  React.useEffect(() => {
    setNamaGedung(
      formChecklist.listRuangById.response &&
        formChecklist.listRuangById.response[0].gedung.gedungChecklist
    );
    setNamaRuang(
      formChecklist.listRuangById.response &&
        formChecklist.listRuangById.response[0].areaChecklist
    );
    let tmp2 = [];
    formChecklist.listRuangById.response &&
      formChecklist.listRuangById.response.map((val, index) => {
        tmp2.push({
          idGedung: val.idGedungChecklist,
          idRuangan: val._id,
          catatan: "",
          keterangan: "",
          foto: [],
          areaChecklist: val.areaChecklist,
          jenisChecklist: val.jenisChecklist,
          // score: 0,
        });
        // });
      });
    setListRuangArr(tmp2);
  }, [formChecklist.listRuangById.response]);
  React.useEffect(() => {
    let tmp =
      formChecklist.HasilChecklist &&
      formChecklist.HasilChecklist.map((val) => {
        return {
          ...val,
        };
      });
    setHasilChecklist(tmp);
    let tmp2 = [];
    formChecklist.HasilChecklist &&
      formChecklist.HasilChecklist.map((val) => {
        val.hasilChecklist.forEach((val2) => {
          tmp2.push({
            action:
              val2.foto[0] === "" ? (
                "-"
              ) : (
                <div className="actions-right">
                  <Button
                    className="mr-2"
                    variant="info"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "18px",
                    }}
                  >
                    <a
                      href={val2.foto}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "white" }}
                    >
                      Preview
                    </a>
                  </Button>
                </div>
              ),
            namaRuangan: val2.namaRuangan,
            tanggal: val2.tanggal,
            jam: val2.jam,
            catatan: val2.catatan,
            jenisChecklist: val2.jenisChecklist,
            nama: val2.nama,
            keterangan: val2.keterangan,
          });
        });
      });
    setDetailChecklist(tmp2);
  }, [formChecklist.HasilChecklist]);

  const customStyles = {
    rows: {
      style: {
        // height: 100, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
      },
    },
    cells: {
      style: {
        minHeight: 50,
      },
    },
  };

  const colHasilChecklist = [
    {
      name: "Name Room",
      minWidth: "180px",
      center: true,
      selector: (row) => row.namaRuangan,
    },
    {
      name: "Checklist Type",
      minWidth: "180px",
      center: true,
      selector: (row) => row.jenisChecklist,
    },
    {
      name: "Monitoring Results",
      minWidth: "180px",
      center: true,
      selector: (row) =>
        row.catatan === "B"
          ? "Bersih/Baik"
          : row.catatan === "K"
          ? "Kotor"
          : row.catatan === "R"
          ? "Rusak"
          : "Tidak Ada Keterangan",
    },
    {
      name: "Name Checker",
      minWidth: "180px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Date",
      minWidth: "180px",
      center: true,
      selector: (row) => moment(row.tanggal).format("DD MMMM YYYY"),
    },
    // {
    //   name: "Time",
    //   minWidth: "180px",
    //   center: true,
    //   selector: (row) => row.jam,
    // },

    {
      name: "Documentation",
      minWidth: "280px",
      center: true,
      selector: (row) => row.action,
    },
    {
      name: "Information",
      minWidth: "280px",
      center: true,
      selector: (row) => (row.keterangan === "" ? "-" : row.keterangan),
      // "KKKKKKKKKKK",
    },
  ];

  return (
    <>
      {ModalDetailChecklist.ModalDetailChecklist ? (
        <div
          className="full-page section-image"
          data-color="black"
          data-image={require("assets/img/full-screen-image-2.jpg").default}
        >
          <div className="survey-form">
            <Card style={{ backgroundColor: "white" }}>
              <Card.Header>
                <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                  Daily Cleanliness Checklist
                  <p
                    style={{ fontSize: 22, marginTop: 10, fontWeight: "bold" }}
                  >
                    Building : {namaGedung}
                  </p>
                  <p
                    style={{ fontSize: 22, marginTop: 10, fontWeight: "bold" }}
                  >
                    Area : {namaRuang}
                  </p>
                  <p style={{ fontSize: 22 }}>
                    <Row>
                      <Col sm="6" style={{ fontSize: 22, fontWeight: "bold" }}>
                        Checklist Today : {hasilChecklist.length}
                      </Col>
                      <Col>
                        <Button
                          onClick={() => {
                            setModalDetail(!modalDetail);
                            getHasilChecklistByDate(dispatch, {
                              idGedung:
                                formChecklist.listRuangById.response ===
                                undefined
                                  ? ""
                                  : formChecklist.listRuangById.response &&
                                    formChecklist.listRuangById.response[0]
                                      .idGedungChecklist,
                              idRuangan: "",
                            });
                          }}
                        >
                          Detail
                        </Button>
                      </Col>
                    </Row>
                  </p>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {formChecklist.listRuangById.status === 200 ? (
                  <>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setNama(e.target.value);
                            }}
                            // disabled={cekStatus ? true : false}
                            type="text"
                            disabled={disableName}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12"></Col>
                    </Row>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        cekStatus(setNama);
                        // daftarruangan({
                        //   gedung: dataGedung._id,
                        //   area: dataGedung.namaRuangan,
                        //   idShift: dataGedung.idShift,
                        // });
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Label>{formChecklist.listRuangById.message}</Label>
                  </>
                )}
              </Card.Body>
            </Card>
            {showForm ? (
              <>
                <Card style={{ backgroundColor: "white" }}>
                  <Card.Header>
                    <Card.Title as="h4" style={{ marginBottom: 18 }}>
                      {" "}
                      Daily Cleanliness Checklist
                    </Card.Title>
                  </Card.Header>
                  {/* <Card.Body>
           <Row>
             <Col sm="12">
               <Form.Label column>
                 Daily Cleanliness Checklist
               </Form.Label>
             </Col>
           </Row>
         </Card.Body> */}
                </Card>
                {listRuangArr.map((val, index) => {
                  return (
                    <Card style={{ backgroundColor: "white" }}>
                      <Card.Body>
                        <Row>
                          <Col sm="6">
                            <Form.Label column style={{ fontSize: 18 }}>
                              {val.areaChecklist}
                              <p style={{ fontSize: 18 }}>
                                {val.jenisChecklist}
                              </p>
                            </Form.Label>

                            <Form.Group
                              onChange={(e) => {
                                setListRuangArr([
                                  ...listRuangArr.slice(0, index),
                                  {
                                    idGedung: val.idGedung,
                                    idRuangan: val.idRuangan,
                                    keterangan: val.keterangan,
                                    foto: val.foto,
                                    areaChecklist: val.areaChecklist,
                                    jenisChecklist: val.jenisChecklist,
                                    catatan: e.target.value,
                                  },
                                  ...listRuangArr.slice(
                                    index + 1,
                                    listRuangArr.length
                                  ),
                                ]);
                              }}
                            >
                              <Row>
                                <Col>
                                  <Form.Check>
                                    <Form.Check.Label>
                                      <Form.Check.Input
                                        name={`areaChecklist${index}`}
                                        type="radio"
                                        value="B"
                                      ></Form.Check.Input>
                                      <span className=""></span>
                                      Bersih/Baik
                                    </Form.Check.Label>
                                  </Form.Check>
                                  <Form.Check>
                                    <Form.Check.Label>
                                      <Form.Check.Input
                                        name={`areaChecklist${index}`}
                                        type="radio"
                                        value="K"
                                      ></Form.Check.Input>
                                      <span className=""></span>
                                      Kotor
                                    </Form.Check.Label>
                                  </Form.Check>
                                  <Form.Check>
                                    <Form.Check.Label>
                                      <Form.Check.Input
                                        name={`areaChecklist${index}`}
                                        type="radio"
                                        value="R"
                                      ></Form.Check.Input>
                                      <span className=""></span>
                                      Rusak
                                    </Form.Check.Label>
                                  </Form.Check>
                                  <Form.Check>
                                    <Form.Check.Label>
                                      <Form.Check.Input
                                        name={`areaChecklist${index}`}
                                        type="radio"
                                        value="-"
                                      ></Form.Check.Input>
                                      <span className=""></span>
                                      Tidak Ada (Item Inspeksi)
                                    </Form.Check.Label>
                                  </Form.Check>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                          <Col sm="6">
                            <label>
                              <b>Keterangan Ketidaksesuaian</b>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setListRuangArr([
                                  ...listRuangArr.slice(0, index),
                                  {
                                    idGedung: val.idGedung,
                                    idRuangan: val.idRuangan,
                                    keterangan: e.target.value,
                                    foto: val.foto,
                                    areaChecklist: val.areaChecklist,
                                    jenisChecklist: val.jenisChecklist,
                                    catatan: val.catatan,
                                  },
                                  ...listRuangArr.slice(
                                    index + 1,
                                    listRuangArr.length
                                  ),
                                ]);
                              }}
                              placeholder="Tuliskan mmasukan anda (Optional)"
                              as="textarea"
                            ></Form.Control>

                            <label
                              // className="label-input-file btn btn-danger text-white mb-4"
                              for="import2"
                            >
                              Tambahkan Gambar
                              <input
                                type="file"
                                id="import2"
                                onClick={(e) => {
                                  e.target.value = "";
                                }}
                                onChange={(e) => {
                                  setListRuangArr([
                                    ...listRuangArr.slice(0, index),
                                    {
                                      idGedung: val.idGedung,
                                      idRuangan: val.idRuangan,
                                      catatan: val.catatan,
                                      keterangan: val.keterangan,
                                      foto: e.target.files,
                                      areaChecklist: val.areaChecklist,
                                      jenisChecklist: val.jenisChecklist,
                                    },
                                    ...listRuangArr.slice(
                                      index + 1,
                                      listRuangArr.length
                                    ),
                                  ]);
                                  // setFoto([
                                  //   ...gambar,
                                  //   ...Array.from(e.target.files),
                                  // ]);
                                }}
                                // style={{ display: "none" }}
                                accept="image/*,.jpg,.jpeg,.png"
                                multiple
                              />
                            </label>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  );
                })}
                {/* <Card style={{ backgroundColor: "white" }}>
         <Card.Body>
           <Row>
             <Col sm="12">
               <Form.Group>
                 <label>
                   <b>Keterangan Ketidaksesuaian</b>
                 </label>
                 <Form.Control
                   onChange={(e) => {
                     setDeskripsi(e.target.value);
                     // setProject({
                     //   ...project,
                     //   deskripsi: e.target.value,
                     // });
                   }}
                   placeholder="Tuliskan mmasukan anda (Optional)"
                   as="textarea"
                 ></Form.Control>
               </Form.Group>
             </Col>
           </Row>
         </Card.Body>
       </Card> */}

                <Row>
                  <Col>
                    <Button
                      className="btn-fill btn-wd"
                      variant="info"
                      onClick={submit}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </div>

          <div
            className="full-page-background"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/full-screen-image-2.jpg").default +
                ")",
            }}
          ></div>
        </div>
      ) : (
        <>
          <HalamanChecklist />
        </>
      )}
      <Modal
        size="lg"
        show={modalDetail}
        onHide={() => {
          setModalDetail(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title
                        as="h4"
                        style={{
                          marginTop: 10,
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          flex: 1,
                          marginBottom: 15,
                        }}
                      >
                        Detail Checklist
                        <p
                          as="h4"
                          style={{
                            marginTop: 10,
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            flex: 1,
                            marginBottom: 15,
                          }}
                        >
                          Building :{namaGedung}
                        </p>
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <DataTable
                      columns={colHasilChecklist}
                      data={detailChecklist}
                      customStyles={customStyles}
                      pagination
                    />

                    {/* <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Area Code</label>
                          <Form.Control
                            onChange={(e) => {
                              setKodeRuanganChecklist(e.target.value);
                            }}
                            // placeholder="Masukan Kode Ruangan Baru"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Ruangan</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataBaru({
                                ...dataBaru,
                                area: e.target.value,
                              });
                            }}
                            placeholder="Masukan Nama Ruangan"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}

                    {/* <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        console.log(
                          idKodeRuanganCheckilst,
                          kodeRuanganChecklist
                        );
                        e.preventDefault();
                        editKodeRuanganChecklist({
                          _id: idKodeRuanganCheckilst,
                          kodeRuanganChecklist: kodeRuanganChecklist,
                        });
                      }}
                    >
                      Submit
                    </Button> */}
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginPage;
