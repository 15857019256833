import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getVisit(dispatch) {
  baseAxios
    .get(`/kunjunganpest/getvisitall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTVISIT", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiVisit(data) {
  baseAxios
    .patch(`/kunjunganpest/responvisit`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi1KunjunganPest(dispatch, data) {
  baseAxios
    .get(
      `/kunjunganpest/getGrafikKunjunganPest?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI1KUNJUNGAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function getTemuanPest(dispatch) {
  baseAxios
    .get(`/temuanpest/gettemuanpestall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTTEMUANPEST", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiTemuanPest(data) {
  baseAxios
    .patch(`/temuanpest/respontemuanpest`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi2TemuanPest(dispatch, data) {
  baseAxios
    .get(
      `/temuanpest/gettemuanpestgrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI2TEMUAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function tambahVisit(data) {
  baseAxios
    .post(`/kunjunganpest/ajukanvisit`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function editVisit(data) {
  console.log(data);
  baseAxios
    .patch(`/kunjunganpest/ajukanvisitulang`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getCalendarVisitPest(dispatch) {
  baseAxios
    .get("/kunjunganpest/getcalendarvisitpest", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_CALENDAR", data: respon.data });
    })
    .catch(errorHandler);
}

export function deletedVisitPest(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      // console.log(data.visitId);
      baseAxios
        .delete("/kunjunganpest/deletevisit", {
          data: { visitId: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);

      // Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });
}
