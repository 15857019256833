import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  Label,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import {
  getKpi1VisitIssue,
  getKpi2ResponIssue,
  updateTglAwal,
  updateTglAkhir,
} from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";
import pestRodentReducer from "stores/pestRodent";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const issue = useSelector((state) => state.industrialReducer);
  const auth = useSelector((state) => state.authReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(auth.dariTgl);
  const [smpTgl, setSmpTgl] = React.useState(auth.smpTgl);
  const [kpi1Option, setKpi1Option] = React.useState(null);
  const [kpi2Option, setKpi2Option] = React.useState(null);

  React.useEffect(() => {
    if (issue.kpi1) {
      setKpi1Option(issue.kpi1.data);
    }
  }, [issue.kpi1]);
  React.useEffect(() => {
    if (issue.kpi2) {
      setKpi2Option(issue.kpi2.data);
    }
  }, [issue.kpi2]);
  React.useEffect(() => {
    getKpi1VisitIssue(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2ResponIssue(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
  }, [dariTgl, smpTgl]);

  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAwal(dispatch, {
                    dariTgl: e.toDate().setHours(0, 0, 0, 0),
                  });
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAkhir(dispatch, {
                    smpTgl: e.toDate().setHours(23, 59, 0, 0),
                  });
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        {issue.kpi1 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">
                    Industrial Issue Visiting Achievement
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {issue.kpi1.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">{issue.kpi1.target} %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              data={kpi1Option}
                              margin={{
                                right: 30,
                                left: 50,
                                bottom: 30,
                              }}
                            >
                              <XAxis dataKey="nama" />
                              <YAxis>
                                <Label
                                  value="Visits"
                                  offset={-20}
                                  angle={-90}
                                  position="insideLeft"
                                />
                              </YAxis>
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="On Time"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Not Ontime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col>
                      <div>Note:</div>
                      <ul>
                        <li>Visit be completed before the deadline</li>
                      </ul>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/kunjunganIndustrial");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          issue.kpi1.pencapaian < issue.kpi1.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}

        {issue.kpi2 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Issue Achievement</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {issue.kpi2.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">{issue.kpi2.target} %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi2Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              data={kpi2Option}
                              margin={{
                                right: 30,
                                left: 50,
                                bottom: 30,
                              }}
                            >
                              <XAxis dataKey="nama" />
                              <YAxis>
                                <Label
                                  value="Issue"
                                  offset={-20}
                                  angle={-90}
                                  position="insideLeft"
                                />
                              </YAxis>
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="On Time"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Not Ontime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col>
                      <div>Note:</div>
                      <ul>
                        <li>
                          Issue must be followed up in less than 3x24 hours
                        </li>
                      </ul>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        style={{ maxHeight: 40 }}
                        onClick={() => {
                          history.push("/admin/issue");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          issue.kpi2.pencapaian < issue.kpi2.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default Panels;
