const initialState = {
  listSafety: [],
  kpi1: null,
};

const safetyReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_LISTSAFETY":
      return {
        ...state,
        listSafety: data,
      };
    case "SET_KPI1SAFETY":
      return {
        ...state,
        kpi1: data,
      };
    default:
      return state;
  }
};

export default safetyReducer;
