import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getProjectAll, verifikasiProject } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const [modal, setModal] = React.useState(false);
  const [project, setProject] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  function onsubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("projectId", project._id);
    fd.append("respon", status);
    fd.append("alasan", deskripsi);
    // console.log(project._id, status, deskripsi);
    // console.log(fd);
    // project.dokumen.map((val, index) => {
    //   fd.append("dokumen", val);
    // });

    verifikasiProject(fd);
  }

  // console.log(monitoring.listProjectAll);
  const history = useHistory();
  React.useEffect(() => {
    let tmp = monitoring.listProjectAll.map((val, index) => {
      return {
        ...val,
        actions:
          // we've added some custom button actions
          val.status == "Waiting for Approval" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setProject(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.status == "Rejected" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setProject(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.status == "Received" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  console.log(val);
                  setModal(true);
                  setProject(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.solvedInTime ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setProject(val);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  console.log(val);
                  setModal(true);
                  setProject(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
      };
    });
    setData(tmp);
  }, [monitoring.listProjectAll]);
  //   React.useEffect(() => {
  //     getAreaNonGroup(dispatch);
  //   }, []);

  React.useEffect(() => {
    getProjectAll(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Row>
          <Col md="12">
            <h4 className="title">Project Verification</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Building",
                      accessor: "gedung",
                    },
                    {
                      Header: "Area",
                      accessor: "area",
                    },
                    {
                      Header: "Project",
                      accessor: "title",
                    },
                    {
                      Header: "Submitter",
                      accessor: "diajukanOleh",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {project.status == "Waiting for Approval" ? (
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Form.Group>
                      <label>Status</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={status.val}
                        onChange={(val) => setStatus(val.value)}
                        options={[
                          { value: "Received", label: "Received" },
                          { value: "Rejected", label: "Rejected" },
                        ]}
                        placeholder="-Choose-"
                      />
                    </Form.Group>
                    {status == "Rejected" ? (
                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                            // setProject({
                            //   ...project,
                            //   deskripsi: e.target.value,
                            // });
                          }}
                          placeholder="Please Write Project Description"
                          as="textarea"
                        ></Form.Control>
                      </Form.Group>
                    ) : null}

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={onsubmit}
                    >
                      Submit
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  {/* <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Detail Problem</Card.Title>
                    </Card.Header>
                  </Card.Header> */}
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Building
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{project.gedung}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Area
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{project.area}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Problem
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{project.title}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Start Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(project.startDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        End Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(project.deadlineDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{project.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Solve Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!project.solvedDate
                          ? "-"
                          : moment(project.solvedDate).format(
                              "DD MMM YYYY / h:mm:ss a"
                            )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Note
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!project.keterangan ? "-" : project.keterangan}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Previous Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!project.filePendukung ||
                      project.filePendukung.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Previous Documentation is Not Available
                        </Col>
                      ) : (
                        project.filePendukung.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                    {project.status == "Done" ? (
                      <>
                        <hr />
                        <Row style={{ marginTop: 30 }}>
                          <Col
                            sm="12"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Final Documentation
                          </Col>
                        </Row>
                        <Row>
                          {!project.buktiSelesai ||
                          project.buktiSelesai.length == 0 ? (
                            <Col sm="12" style={{ textAlign: "center" }}>
                              Final Documentation is Not Available
                            </Col>
                          ) : (
                            project.buktiSelesai.map((val, index) => {
                              return (
                                <>
                                  <Col sm="4">
                                    <Image
                                      src={val}
                                      style={{
                                        width: 200,
                                        height: 250,
                                        margin: 20,
                                      }}
                                      thumbnail
                                    />
                                  </Col>
                                </>
                              );
                            })
                          )}
                        </Row>
                      </>
                    ) : null}
                    {/* <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        setModal(false);
                        e.preventDefault();
                      }}
                    >
                      Close
                    </Button> */}
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
