import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  cekStatusUser,
  getPertanyaan,
  getListRuanganChecklist,
  laporkanChecklist,
  getListRuanganChecklistShift1,
  getListRuanganChecklistShift2,
  kirimHasilSurvey,
} from "../../../stores";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";

function CheckListKebersihan() {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.surveyReducer);
  const [nama, setNama] = React.useState("");
  const [idShift, setIdShift] = React.useState("");
  const [idChecklist, setIdChecklist] = React.useState("");
  const [kondisi, setKondisi] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [gambarSebelum, setGambarSebelum] = React.useState([]);
  const [gambarSesudah, setGambarSesudah] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [detail, setDetail] = React.useState({});
  const [pertanyaanArrToilet, setPertanyaanArrToilet] = React.useState([
    { pertanyaan: "Bau", score: 0 },
    { pertanyaan: "Konsumabel Toilet (Sabun, Tissue, dll)", score: 0 },
    { pertanyaan: "Peralatan Toilet (Gayung, Gantungan Baju, Dll)", score: 0 },
    { pertanyaan: "Kloset", score: 0 },
    { pertanyaan: "Bak Air", score: 0 },
    { pertanyaan: "Tempat Sampah", score: 0 },
    { pertanyaan: "Wastafel", score: 0 },
    { pertanyaan: "Hand Dryer", score: 0 },
    { pertanyaan: "Urinoir", score: 0 },
    { pertanyaan: "Atap", score: 0 },
    { pertanyaan: "Dinding", score: 0 },
    { pertanyaan: "Lantai", score: 0 },
    { pertanyaan: "Pintu dan Jendela", score: 0 },
    { pertanyaan: "Air (Kran & Saluran Pembuangan)", score: 0 },
    { pertanyaan: "Penerangan ( Lampu), incl. Saklar", score: 0 },
  ]);

  const [pertanyaanArrDalamGedung, setPertanyaanArrDalamGedung] =
    React.useState([
      { pertanyaan: "Bau", score: 0 },
      { pertanyaan: "Meja dan Kursi", score: 0 },
      { pertanyaan: "Peralatan Kerja (Komputer, Telp, dll)", score: 0 },
      { pertanyaan: "Tempat Sampah", score: 0 },
      { pertanyaan: "Kaca (termasuk Blind)", score: 0 },
      { pertanyaan: "Atap (termasuk Area AC dan Lampu)", score: 0 },
      { pertanyaan: "Dinding dan Louvre", score: 0 },
      { pertanyaan: "Lantai", score: 0 },
      { pertanyaan: "Pintu dan Jendela", score: 0 },
      { pertanyaan: "Kondisi Penerangan (Lampu)", score: 0 },
      { pertanyaan: "Kondisi AC", score: 0 },
    ]);

  const [pertanyaanArrLuarGedung, setPertanyaanArrLuarGedung] = React.useState([
    { pertanyaan: "Rumput Liar di Paving", score: 0 },
    { pertanyaan: "Bau", score: 0 },
    { pertanyaan: "Selokan", score: 0 },
    { pertanyaan: "Landscape (Jalan dan Paving)", score: 0 },
    { pertanyaan: "Dinding dan Atap samping Luar Gedung", score: 0 },
    { pertanyaan: "Pagar dan Dinding Perimeter", score: 0 },
    { pertanyaan: "Kaca", score: 0 },
    { pertanyaan: "Tempat Sampah", score: 0 },
    { pertanyaan: "Air (Kran dan Saluran Pembuangan)", score: 0 },
    { pertanyaan: "Penerangan (Lampu)", score: 0 },
  ]);

  const [pertanyaanArrTaman, setPertanyaanArrTaman] = React.useState([
    { pertanyaan: "Rumput Liar", score: 0 },
    { pertanyaan: "Tanah Taman", score: 0 },
    { pertanyaan: "Penanaman", score: 0 },
    { pertanyaan: "Penyiraman", score: 0 },
    { pertanyaan: "Pemupukan", score: 0 },
    { pertanyaan: "Pengobatan Hama", score: 0 },
    { pertanyaan: "Perawatan dan Kerapian Taman", score: 0 },
    { pertanyaan: "Sprinkle, Kran dan Selang Air", score: 0 },
    { pertanyaan: "Penerangan (Lampu)", score: 0 },
  ]);

  const [jenisForm, setJenisForm] = React.useState(0);
  let typeForm = [
    { id: 1, form: "Toilet" },
    { id: 2, form: "Dalam Gedung" },
    { id: 3, form: "Luar Gedung" },
    { id: 4, form: "Taman" },
  ];
  const [statusNama, setStatusNama] = React.useState(false);
  const [statusRuangan, setStatusRuangan] = React.useState(false);
  const [statusKondisi, setStatusKondisi] = React.useState(false);
  const [statusDeskripsi, setStatusDeskripsi] = React.useState(false);
  const [statusGambarSebelum, setStatusGambarSebelum] = React.useState(false);
  const [statusGambarSesudah, setStatusGambarSesudah] = React.useState(false);

  const [dataShift1, setDataShift1] = React.useState([]);
  const [dataShift2, setDataShift2] = React.useState([]);

  const [statusShift1, setStatusShift1] = React.useState(false);
  const [statusShift2, setStatusShift2] = React.useState(false);
  React.useEffect(() => {
    getListRuanganChecklist(dispatch);
    getListRuanganChecklistShift1(dispatch);
    getListRuanganChecklistShift2(dispatch);
  }, []);

  const cekStatus = () => {
    if (nama == "") {
      setStatusNama(true);
    } else {
      setStatusNama(false);
    }
    if (idShift == "" || idChecklist == "") {
      setStatusRuangan(true);
    } else {
      setStatusRuangan(false);
    }

    if (deskripsi == "") {
      setStatusDeskripsi(true);
    } else {
      setStatusDeskripsi(false);
    }
    if (gambarSebelum.length == 0) {
      setStatusGambarSebelum(true);
    } else {
      setStatusGambarSebelum(false);
    }
    if (gambarSesudah.length == 0) {
      setStatusGambarSesudah(true);
    } else {
      setStatusGambarSesudah(false);
    }

    if (
      nama !== "" &&
      idShift !== "" &&
      idChecklist !== "" &&
      deskripsi !== "" &&
      gambarSebelum.length !== 0 &&
      gambarSesudah.length !== 0
    ) {
      laporkanChecklist(dispatch, {
        nama: nama,
        idShifts: idShift,
        idChekclist: idChecklist,
        jawabanChecklist:
          jenisForm === 1
            ? pertanyaanArrToilet
            : jenisForm === 2
            ? pertanyaanArrDalamGedung
            : jenisForm === 3
            ? pertanyaanArrLuarGedung
            : pertanyaanArrTaman,
        deskripsi,
        gambarSebelum,
        gambarSesudah,
      });
    }
  };

  React.useEffect(() => {
    let tmp =
      survey.ruanganChecklistShift1 &&
      survey.ruanganChecklistShift1.map((val, index) => {
        // console.log(val, "s1");
        return { ...val, jamShift: val.jamShift == 11 ? "Shift 1" : null };
      });
    setDataShift1(tmp);
  }, [survey.ruanganChecklistShift1]);

  React.useEffect(() => {
    let tmp =
      survey.ruanganChecklistShift2 &&
      survey.ruanganChecklistShift2.map((val, index) => {
        // console.log(val, "s2");
        return { ...val, jamShift: val.jamShift == 16 ? "Shift 2" : null };
      });
    setDataShift2(tmp);
  }, [survey.ruanganChecklistShift2]);

  const columns = [
    // {
    //   name: "Shift",
    //   width: "200px",
    //   sortable: true,
    //   selector: (row) => row.jamShift,
    // },
    {
      name: "Gedung",
      // width: "200px",
      sortable: true,
      selector: (row) => row.gedung,
    },
    {
      name: "Ruangan",
      sortable: true,
      // width: "150px",
      selector: (row) => row.area,
    },
    {
      name: "Status",
      sortable: true,
      width: "150px",
      selector: (row) => row.status,
    },
  ];

  const columns1 = [
    // {
    //   name: "Shift",
    //   width: "200px",
    //   sortable: true,
    //   selector: (row) => row.jamShift,
    // },
    {
      name: "Gedung",
      // width: "200px",
      sortable: true,
      selector: (row) => row.gedung,
    },
    {
      name: "Ruangan",
      sortable: true,
      // width: "150px",
      selector: (row) => row.area,
    },
    {
      name: "Status",
      sortable: true,
      width: "150px",
      selector: (row) => row.status,
    },
    {
      name: "Persentase",
      sortable: true,
      width: "150px",
      selector: (row) => (!row.nilai ? "-" : `${Math.round(row.nilai * 100)}%`),
    },
    {
      name: "Aksi",
      sortable: true,
      width: "150px",
      selector: (row) =>
        row.status === "Belum Dikunjungi" ? (
          "-"
        ) : (
          <Button
            onClick={() => {
              setModalDetail(true);
              setDetail(row);
              console.log(row);
            }}
          >
            Detail
          </Button>
        ),
    },
  ];

  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="survey-form">
          <Card style={{ backgroundColor: "white" }}>
            <Card.Header>
              <Card.Title as="h4">Checklist Form</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <Form.Group>
                    <label>Nama</label>
                    <Form.Control
                      onChange={(e) => {
                        setNama(e.target.value);
                        setStatusNama(false);
                      }}
                      disabled={survey.statusUser ? true : false}
                      type="text"
                    ></Form.Control>
                    {statusNama ? (
                      <label style={{ color: "red" }}>
                        * Nama tidak boleh kosong
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col sm="12">
                  <Form.Group>
                    <label>Lokasi</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      // value={periode.val}
                      onChange={(val) => {
                        console.log("hasil pilihan", JSON.parse(val.value));
                        setStatusRuangan(false);
                        setIdChecklist(JSON.parse(val.value).idChecklist);
                        setIdShift(JSON.parse(val.value).idShift);
                      }}
                      options={survey.ruanganChecklist.map((val, index) => {
                        return {
                          value: `{"idChecklist":"${val._id}","idShift":"${val.idShift}"}`,
                          label: `${val.gedung} - ${val.area} - ${
                            val.jamShift == 11 ? "Shift1" : "Shift2"
                          }`,
                        };
                      })}
                      placeholder="-Pilih Lokasi-"
                    />
                    {statusRuangan ? (
                      <label style={{ color: "red" }}>
                        * Wajib memilih lokasi checklist
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col sm="12">
                  <Form.Group>
                    <label>Jenis Form</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      // value={periode.val}
                      onChange={(val) => {
                        setJenisForm(val.value);
                      }}
                      options={typeForm.map((val, index) => {
                        return {
                          value: val.id,
                          label: val.form,
                        };
                      })}
                      placeholder="-Pilih Jenis Form-"
                    />
                    {statusRuangan ? (
                      <label style={{ color: "red" }}>
                        * Wajib memilih jenis form checklist
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>
                {jenisForm === 1
                  ? pertanyaanArrToilet.map((val, index) => {
                      return (
                        <Card
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            marginRight: 20,
                            marginLeft: 20,
                          }}
                          s
                        >
                          <Card.Body>
                            <Row>
                              <Col sm="12">
                                <Form.Label
                                  column
                                  style={{ textTransform: "none" }}
                                >
                                  {val.pertanyaan}
                                </Form.Label>
                              </Col>
                              <Col sm="12">
                                <Form.Group
                                  onChange={(e) => {
                                    setPertanyaanArrToilet([
                                      ...pertanyaanArrToilet.slice(0, index),
                                      {
                                        pertanyaan: val.pertanyaan,
                                        score: e.target.value,
                                      },
                                      ...pertanyaanArrToilet.slice(
                                        index + 1,
                                        pertanyaanArrToilet.length
                                      ),
                                    ]);
                                  }}
                                >
                                  <Row>
                                    <Col>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Ok"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Ok
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Kotor"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Kotor
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Rusak"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Rusak
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Tidak Tersedia"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Tidak Tersedia
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    })
                  : jenisForm === 2
                  ? pertanyaanArrDalamGedung.map((val, index) => {
                      return (
                        <Card
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            marginRight: 20,
                            marginLeft: 20,
                          }}
                          s
                        >
                          <Card.Body>
                            <Row>
                              <Col sm="12">
                                <Form.Label
                                  column
                                  style={{ textTransform: "none" }}
                                >
                                  {val.pertanyaan}
                                </Form.Label>
                              </Col>
                              <Col sm="12">
                                <Form.Group
                                  onChange={(e) => {
                                    setPertanyaanArrDalamGedung([
                                      ...pertanyaanArrDalamGedung.slice(
                                        0,
                                        index
                                      ),
                                      {
                                        pertanyaan: val.pertanyaan,
                                        score: e.target.value,
                                      },
                                      ...pertanyaanArrDalamGedung.slice(
                                        index + 1,
                                        pertanyaanArrDalamGedung.length
                                      ),
                                    ]);
                                  }}
                                >
                                  <Row>
                                    <Col>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Ok"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Ok
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Kotor"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Kotor
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Rusak"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Rusak
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Tidak Tersedia"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Tidak Tersedia
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    })
                  : jenisForm === 3
                  ? pertanyaanArrLuarGedung.map((val, index) => {
                      return (
                        <Card
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            marginRight: 20,
                            marginLeft: 20,
                          }}
                          s
                        >
                          <Card.Body>
                            <Row>
                              <Col sm="12">
                                <Form.Label
                                  column
                                  style={{ textTransform: "none" }}
                                >
                                  {val.pertanyaan}
                                </Form.Label>
                              </Col>
                              <Col sm="12">
                                <Form.Group
                                  onChange={(e) => {
                                    setPertanyaanArrLuarGedung([
                                      ...pertanyaanArrLuarGedung.slice(
                                        0,
                                        index
                                      ),
                                      {
                                        pertanyaan: val.pertanyaan,
                                        score: e.target.value,
                                      },
                                      ...pertanyaanArrLuarGedung.slice(
                                        index + 1,
                                        pertanyaanArrLuarGedung.length
                                      ),
                                    ]);
                                  }}
                                >
                                  <Row>
                                    <Col>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Ok"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Ok
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Kotor"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Kotor
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Rusak"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Rusak
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Tidak Tersedia"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Tidak Tersedia
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    })
                  : jenisForm === 4
                  ? pertanyaanArrTaman.map((val, index) => {
                      return (
                        <Card
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            marginRight: 20,
                            marginLeft: 20,
                          }}
                          s
                        >
                          <Card.Body>
                            <Row>
                              <Col sm="12">
                                <Form.Label
                                  column
                                  style={{ textTransform: "none" }}
                                >
                                  {val.pertanyaan}
                                </Form.Label>
                              </Col>
                              <Col sm="12">
                                <Form.Group
                                  onChange={(e) => {
                                    setPertanyaanArrTaman([
                                      ...pertanyaanArrTaman.slice(0, index),
                                      {
                                        pertanyaan: val.pertanyaan,
                                        score: e.target.value,
                                      },
                                      ...pertanyaanArrTaman.slice(
                                        index + 1,
                                        pertanyaanArrTaman.length
                                      ),
                                    ]);
                                  }}
                                >
                                  <Row>
                                    <Col>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Ok"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Ok
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Kotor"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Kotor
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Rusak"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Rusak
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            name={`pertanyaan${index}`}
                                            type="radio"
                                            value="Tidak Tersedia"
                                          ></Form.Check.Input>
                                          <span className=""></span>
                                          Tidak Tersedia
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    })
                  : null}
                {/* <Col sm="12">
                  <Form.Group>
                    <label>Kondisi</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      // value={status.val}
                      onChange={(val) => {
                        setKondisi(val.value);
                        setStatusKondisi(false);
                      }}
                      options={[
                        { value: "Baik", label: "Baik" },
                        { value: "Kotor", label: "Kotor" },
                        { value: "Rusak", label: "Rusak" },
                      ]}
                      placeholder="-Pilih Kondisi-"
                    />
                    {statusKondisi ? (
                      <label style={{ color: "red" }}>
                        * Wajib memilih kondisi ruangan
                      </label>
                    ) : null}
                  </Form.Group>
                </Col> */}
                <Col sm="12">
                  <Form.Group>
                    <label>Keterangan</label>
                    <Form.Control
                      placeholder="Tuliskan penjelasan keadaan lokasi yang anda bersihkan."
                      // value={deskripsi}
                      onChange={(e) => {
                        setDeskripsi(e.target.value);
                        setStatusDeskripsi(false);
                      }}
                      as="textarea"
                    ></Form.Control>
                    {statusDeskripsi ? (
                      <label style={{ color: "red" }}>
                        * Keterangan tidak boleh kosong
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <label
                    className="label-input-file btn btn-danger text-white mb-4"
                    for="import"
                  >
                    Tambahkan Foto Sebelum
                    <input
                      type="file"
                      id="import"
                      onClick={(e) => {
                        console.log(">>>>>>OnClickSebelum");
                        e.target.value = "";
                      }}
                      onChange={(e) => {
                        setStatusGambarSebelum(false);
                        setGambarSebelum([
                          ...gambarSebelum,
                          ...Array.from(e.target.files),
                        ]);
                      }}
                      style={{ display: "none" }}
                      accept="image/*"
                      multiple
                    />
                  </label>
                  {statusGambarSebelum ? (
                    <label style={{ color: "red" }}>
                      * Harap lampirkan foto sebelum
                    </label>
                  ) : null}

                  {gambarSebelum.map((val, index) => {
                    return (
                      <div className="d-flex align-items-start">
                        <p>{val.name}</p>
                        <Button
                          onClick={() => {
                            setGambarSebelum([
                              ...gambarSebelum.slice(0, index),
                              ...gambarSebelum.slice(
                                index + 1,
                                gambarSebelum.length
                              ),
                            ]);
                          }}
                          variant="danger"
                          size="sm"
                          className="btn-link remove text-danger"
                        >
                          <i className="fa fa-times" />
                        </Button>
                      </div>
                    );
                  })}
                </Col>
                <Col sm="6">
                  <label
                    className="label-input-file btn btn-success text-white mb-4"
                    for="import2"
                  >
                    Tambahkan Foto Sesudah
                    <input
                      type="file"
                      id="import2"
                      onClick={(event) => {
                        console.log(">>>>>>OnClickSesudah");
                        event.target.value = "";
                      }}
                      onChange={(event) => {
                        setStatusGambarSesudah(false);
                        setGambarSesudah([
                          ...gambarSesudah,
                          ...Array.from(event.target.files),
                        ]);
                      }}
                      style={{ display: "none" }}
                      accept="image/*"
                      multiple
                    />
                  </label>
                  {statusGambarSesudah ? (
                    <label style={{ color: "red" }}>
                      * Harap lampirkan foto sesudah
                    </label>
                  ) : null}

                  {gambarSesudah.map((val, index) => {
                    return (
                      <div className="d-flex align-items-start">
                        <p>{val.name}</p>
                        <Button
                          onClick={() => {
                            setGambarSesudah([
                              ...gambarSesudah.slice(0, index),
                              ...gambarSesudah.slice(
                                index + 1,
                                gambarSesudah.length
                              ),
                            ]);
                          }}
                          variant="danger"
                          size="sm"
                          className="btn-link remove text-danger"
                        >
                          <i className="fa fa-times" />
                        </Button>
                      </div>
                    );
                  })}
                </Col>
              </Row>
              {survey.statusUser ? null : (
                <Button
                  className="btn-fill pull-right"
                  type="submit"
                  variant="info"
                  onClick={(e) => {
                    e.preventDefault();
                    cekStatus();
                  }}
                >
                  Submit
                </Button>
              )}
            </Card.Body>
          </Card>

          <Card style={{ backgroundColor: "white" }}>
            <Card.Header>
              <Card.Title as="h4">List Kunjungan Checklist Ruangan</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Row style={{ marginLeft: 15 }}>
                    <label style={{ color: "#000000", fontSize: 14 }}>
                      <b>Shift 1</b>
                    </label>
                    <label
                      className="d-flex align-items-center"
                      style={{ marginLeft: 10 }}
                    >
                      <Form.Check
                        type="switch"
                        id="custom-switch-11"
                        onChange={(e) => {
                          setStatusShift1(!statusShift1);
                        }}
                      />
                      <span className="ml-1">Tampilkan</span>
                    </label>
                  </Row>
                  {statusShift1 ? (
                    <DataTable columns={columns} data={dataShift1} pagination />
                  ) : null}
                </Col>
                <Col sm={12} style={{ marginTop: 20 }}>
                  <Row style={{ marginLeft: 15 }}>
                    <label style={{ color: "#000000", fontSize: 14 }}>
                      <b>Shift 2</b>
                    </label>
                    <label
                      className="d-flex align-items-center"
                      style={{ marginLeft: 10 }}
                    >
                      <Form.Check
                        type="switch"
                        id="custom-switch-12"
                        onChange={(e) => {
                          setStatusShift2(!statusShift2);
                        }}
                      />
                      <span className="ml-1">Tampilkan</span>
                    </label>
                  </Row>
                  {statusShift2 ? (
                    <DataTable
                      columns={columns1}
                      data={dataShift2}
                      pagination
                    />
                  ) : null}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>

        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>

      <Modal
        size="md"
        show={modalDetail}
        onHide={() => {
          setModalDetail(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="4" style={{ fontWeight: "bold" }}>
                        Nama
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="7">{detail.dilaporkanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="4" style={{ fontWeight: "bold" }}>
                        Gedung
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="7">{detail.gedung}</Col>
                    </Row>
                    <Row>
                      <Col sm="4" style={{ fontWeight: "bold" }}>
                        Area
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="7">{detail.area}</Col>
                    </Row>
                    <Row>
                      <Col sm="4" style={{ fontWeight: "bold" }}>
                        Shift
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="7">{detail.jamShift}</Col>
                    </Row>
                    <Row>
                      <Col sm="4" style={{ fontWeight: "bold" }}>
                        Keterangan
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="7">{detail.keterangan}</Col>
                    </Row>
                    <Row>
                      <Col sm="4" style={{ fontWeight: "bold" }}>
                        Persentase
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="7">{Math.round(detail.nilai * 100)}%</Col>
                    </Row>
                    {/* <Row>
                      <Col sm="4" style={{ fontWeight: "bold" }}>
                        {" "}
                        Brand
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="7">{dataItem.brand}</Col>
                    </Row>
                    <Row>
                      <Col sm="4" style={{ fontWeight: "bold" }}>
                        {" "}
                        Category
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="7">{dataItem.kategori}</Col>
                    </Row> */}
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CheckListKebersihan;
