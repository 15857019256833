import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getSafety(dispatch) {
  baseAxios
    .get(`/safety/getkejadianall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTSAFETY", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiSafety(data) {
  baseAxios
    .patch(`/safety/responkejadian`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi1Safety(dispatch, data) {
  baseAxios
    .get(`/safety/getsafetygrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_KPI1SAFETY", data: respon.data });
    })
    .catch(errorHandler);
}
