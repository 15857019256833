import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  Table,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  Label,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import {
  getKpi1Consumable,
  exportBandU,
  getBiaya,
  updateTglAwal,
  updateTglAkhir,
  exportOrderPerBulanExcel,
} from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";
import Swal from "sweetalert2";
const barColor = [
  "#4287f5",
  "#f51b84",
  "#33d91a",
  "#f77f16",
  "#ff1414",
  "#06cf8c",
  "#dc02f0",
  "#341f91",
  "#8c0e19",
  "#1fdbb6",
  "#1fdb48",
  "#9cdb1f",
  "#11cbfa",
  "#de95c4",
  "#e38d5f",
  "#f23f2c",
];
function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const consumable = useSelector((state) => state.consumableReducer);
  const auth = useSelector((state) => state.authReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(auth.dariTgl);
  const [smpTgl, setSmpTgl] = React.useState(auth.smpTgl);
  const [kpi1Option, setKpi1Option] = React.useState(null);
  const [biaya, setBiaya] = React.useState(null);
  const [singleSelect, setSingleSelect] = React.useState(
    new Date().getFullYear()
  );
  const [singleSelectMonth, setSingleSelectMonth] = React.useState(
    new Date().getMonth()
  );
  const [objectSelect, setObjectSelect] = React.useState("");
  const [objectSelectMonth, setObjectSelectMonth] = React.useState("");
  const [listYear, setListYear] = React.useState([]);
  const [listMonth, setListMonth] = React.useState([
    {
      value: 0,
      label: "January",
    },
    {
      value: 1,
      label: "February",
    },
    {
      value: 2,
      label: "March",
    },
    {
      value: 3,
      label: "April",
    },
    {
      value: 4,
      label: "May",
    },
    {
      value: 5,
      label: "June",
    },
    {
      value: 6,
      label: "July",
    },
    {
      value: 7,
      label: "August",
    },
    {
      value: 8,
      label: "September",
    },
    {
      value: 9,
      label: "October",
    },
    {
      value: 10,
      label: "November",
    },
    {
      value: 11,
      label: "December",
    },
  ]);
  React.useEffect(() => {
    if (consumable.kpi1) {
      setKpi1Option(consumable.kpi1.data);
    }
  }, [consumable.kpi1]);

  React.useEffect(() => {
    getKpi1Consumable(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    setBiaya(consumable.biaya);
  }, [consumable.biaya]);
  React.useEffect(() => {
    getBiaya(dispatch, { tahun: singleSelect });
  }, []);

  React.useEffect(() => {
    createYear();
  }, []);

  const createYear = () => {
    let date = new Date().getFullYear();
    let tmp = [];
    for (let i = 2; i >= 0; i--) {
      tmp.unshift({ value: date - i, label: (date - i).toString() });
    }
    setListYear(tmp);
  };
  // console.log(biaya);
  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAwal(dispatch, {
                    dariTgl: e.toDate().setHours(0, 0, 0, 0),
                  });
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAkhir(dispatch, {
                    smpTgl: e.toDate().setHours(23, 59, 0, 0),
                  });
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        {consumable.kpi1 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Ordering Achievement</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {consumable.kpi1.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {consumable.kpi1.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              data={kpi1Option}
                              margin={{
                                right: 30,
                                left: 50,
                                bottom: 30,
                              }}
                            >
                              <XAxis dataKey="name" />
                              <YAxis>
                                <Label
                                  value="Order"
                                  offset={-20}
                                  angle={-90}
                                  position="insideLeft"
                                />
                              </YAxis>
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Progress"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Delivery"
                                barSize={20}
                                fill="#ff9d00"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Received"
                                barSize={20}
                                fill="#eb34db"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="OnTime"
                                barSize={20}
                                fill="#0ed907"
                                stackId="b"
                              ></Bar>
                              <Bar
                                dataKey="Not OnTime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="b"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col>
                      <div>Note:</div>
                      <ul>
                        <li>Order must arrive in less than 30 days</li>
                      </ul>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        style={{ maxHeight: 40 }}
                        onClick={() => {
                          history.push("/admin/rekapconsumable");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          consumable.kpi1.pencapaian < consumable.kpi1.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
        {consumable.biaya && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">
                    <Row>
                      <Col>Expenditure {singleSelect}</Col>

                      <Col md="2">
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={objectSelect}
                          onChange={(value) => {
                            setObjectSelect(value);
                            setSingleSelect(value.value);
                            getBiaya(dispatch, { tahun: value.value });
                          }}
                          options={listYear.map((val) => {
                            return val;
                          })}
                          placeholder="Year"
                        />
                      </Col>
                    </Row>
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      {biaya && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              data={biaya}
                              margin={{
                                right: 30,
                                left: 50,
                                bottom: 30,
                              }}
                            >
                              <XAxis dataKey="nama">
                                <Label
                                  value="Month"
                                  offset={-20}
                                  position="insideBottom"
                                />
                              </XAxis>
                              <YAxis>
                                <Label
                                  value="Cost"
                                  offset={-40}
                                  angle={-90}
                                  position="insideLeft"
                                />
                              </YAxis>
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Cost"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Target"
                                barSize={20}
                                fill="#ff9d00"
                                stackId="b"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    {/* <Col>
                      <div>Note:</div>
                      <ul>
                        <li>Order must arrive in less than 30 days</li>
                      </ul>
                    </Col> */}
                    <Col className="d-flex justify-content-end">
                      <Col md="2">
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={objectSelectMonth}
                          onChange={(value) => {
                            setObjectSelectMonth(value);
                            setSingleSelectMonth(value.value);
                            // getBiaya(dispatch, { tahun: value.value });
                          }}
                          options={listMonth.map((val) => {
                            return val;
                          })}
                          placeholder="Month"
                        />
                      </Col>
                      {/* <Col> */}
                      <Button
                        style={{ maxHeight: 40 }}
                        onClick={() => {
                          if (objectSelectMonth === "") {
                            Swal.fire({
                              title: "Choose Month First",
                              icon: "warning",
                            });
                          } else {
                            exportOrderPerBulanExcel({
                              bulan: singleSelectMonth,
                              tahun: singleSelect,
                            });
                          }
                          // console.log(objectSelectMonth);
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={"info"}
                      >
                        Export
                      </Button>
                      {/* </Col> */}
                    </Col>
                  </Row>
                </Card.Footer>
                {/* <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/rekapconsumable");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          consumable.kpi1.pencapaian < consumable.kpi1.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer> */}
              </Card>
            </Col>
          </Row>
        )}
        {/* {consumable.biaya && (
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Expenditure</Card.Title>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Month</th>
                        <th>Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {biaya &&
                        biaya.map((val) => {
                          return (
                            <tr>
                              <td>{val.nama}</td>
                              <td>Rp{val.Cost}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )} */}
      </Container>
    </>
  );
}

export default Panels;
