import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getItemOrder,
  pesanBarang,
  getKategoryCS,
  importStock,
  getStockGudangLt,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Swal from "sweetalert2";
import moment from "moment";
function ReactTables() {
  const dispatch = useDispatch();
  const consumable = useSelector((state) => state.consumableReducer);

  const [data, setData] = React.useState([]);
  const [dataStockLt, setDataStockLt] = React.useState([]);
  const [orderedItem, setOrderedItem] = React.useState([]);
  const [orderedItem2, setOrderedItem2] = React.useState(null);
  const [listKategori, setListKategori] = React.useState([]);
  const [choosenKategori, setChoosenKategori] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  const [modal, setModal] = React.useState(false);
  const [dataStock, setDataStock] = React.useState(null);

  function onsubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("file", dataStock);
    importStock(fd);
  }

  React.useEffect(() => {
    let tmp = consumable.items.map((val, index) => {
      return {
        code: val.kode,
        item: val.nama,
        // qty: val.stock,
        satuan: val.satuan,
        brand: val.brand,
        kategori: val.kategori,
        speck: val.spek,
        input: false,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                console.log(val);
                // let tmp2 = [
                //   ...orderedItem,
                //   {
                //     idItem: val._id,
                //     kode: val.kode,
                //     nama: val.nama,
                //     unit: 1,
                //     harga: 0,
                //     totalHarga: 0,
                //     satuan: val.satuan,
                //     kategori: val.kategori,
                //     max: val.stock,
                //     input: true,
                //   },
                // ];
                // setOrderedItem2(tmp2);
                setOrderedItem2({
                  idItem: val._id,
                  kode: val.kode,
                  nama: val.nama,
                  unit: 1,
                  harga: 0,
                  brand: val.brand,
                  totalHarga: 0,
                  satuan: val.satuan,
                  kategori: val.kategori,
                  max: val.stock,
                  input: true,
                });
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fas fa-plus fa-lg"></i>
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [consumable.items]);

  React.useEffect(() => {
    let tmp = consumable.stockGudangLt.map((val, index) => {
      return {
        code: val.kode,
        item: val.nama,
        // qty: val.stock,
        satuan: val.satuan,
        brand: val.brand,
        kategori: val.kategori,
        speck: val.spek,
        sisa: val.minStock - val.stock,
        input: false,
        actions: (
          <div className="actions-right">
            {val.status == "Process" ? (
              <Button
                onClick={() => {}}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fas fa-info-circle fa-lg"></i>
              </Button>
            ) : (
              <div>
                {/* {dataStockLt.length != 0 && dataStockLt[index].input ? 
                (
                  <Button
                    onClick={() => {}}
                    variant="success"
                    size="md"
                    className="btn-link remove text-success"
                  >
                    <i className="fas fa-check fa-lg"></i>
                  </Button>
                ) 
                : ( */}
                <Button
                  onClick={() => {
                    // let tmp3 = [
                    //   ...dataStockLt.slice(0, index),
                    //   {
                    //     ...val,
                    //     input: true,
                    //   },
                    //   ...dataStockLt.slice(index + 1, index.length),
                    // ];
                    // setDataStockLt(tmp3);
                    // let tmp2 = [
                    //   ...orderedItem2,
                    //   {
                    //     idItem: val.idStock,
                    //     kode: val.kode,
                    //     nama: val.nama,
                    //     unit: val.minStock - val.stock,
                    //     harga: 0,
                    //     totalHarga: 0,
                    //     satuan: val.satuan,
                    //     kategori: val.kategori,
                    //     max: val.stock,
                    //   },
                    // ];
                    // setOrderedItem2(tmp2);
                    setOrderedItem2({
                      idItem: val.idStock,
                      kode: val.kode,
                      nama: val.nama,
                      unit: val.minStock - val.stock,
                      brand: val.brand,
                      harga: 0,
                      totalHarga: 0,
                      satuan: val.satuan,
                      kategori: val.kategori,
                      // max: val.stock,
                    });
                  }}
                  variant="dager"
                  size="md"
                  className="btn-link remove text-danger"
                >
                  {/* {dataStockLt.length != 0 && dataStockLt[index].input
                    ? "benar"
                    : "salah"} */}
                  <i className="fas fa-plus fa-lg"></i>
                </Button>
                {/* )} */}
              </div>
            )}{" "}
          </div>
        ),
      };
    });
    setDataStockLt(tmp);
  }, [consumable.stockGudangLt]);

  React.useEffect(() => {
    let varTPM = orderedItem2;
    if (varTPM !== null) {
      let check = false;
      orderedItem.map((val) => {
        if (val.kode === varTPM.kode) {
          check = true;
        }
      });
      if (!check) {
        let tpm = [...orderedItem, varTPM];
        console.log(orderedItem, "<<<", varTPM, "<<<", tpm);
        setOrderedItem(tpm);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The item is already included in the order!",
        });
      }
    }
  }, [orderedItem2]);
  // // React.useEffect(() => {
  // //   if (consumable.stockGudangLt) {
  // //     let tmp = [...consumable.stockGudangLt];
  // //     tmp.forEach((val) => {
  // //       val.check = false;
  // //     });
  // //     setDataStockLt(tmp);
  // //   }
  // }, [consumable.stockGudangLt]);

  React.useEffect(() => {
    let tmp = consumable.kategori.map((val, index) => {
      return {
        label: val.nama,
        value: val.nama,
      };
    });
    setListKategori(tmp);
  }, [consumable.kategori]);

  React.useEffect(() => {
    getItemOrder(dispatch);
    getKategoryCS(dispatch);
    getStockGudangLt(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Uploading the Stock
        </Button> */}
        <Row>
          <Col md="12">
            <h4 className="title">Order</h4>
          </Col>
        </Row>
        <Row>
          <Col md={"12"}>
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Code",
                      accessor: "code",
                    },
                    {
                      Header: "Item",
                      accessor: "item",
                    },
                    {
                      Header: "Speck",
                      accessor: "speck",
                    },
                    // {
                    //   Header: "Stock",
                    //   accessor: "qty",
                    // },
                    {
                      Header: "Brand",
                      accessor: "brand",
                    },
                    {
                      Header: "Unit",
                      accessor: "satuan",
                    },
                    {
                      Header: "Category",
                      accessor: "kategori",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
          {/* {dataStockLt.length == 0 ? null : (
            <>
              <Col md="12">
                <Card.Header>
                  <Card.Title as="h4">Must Order</Card.Title>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Code</th>
                        <th>Item</th>
                        <th>Brand</th>
                        <th>Speck</th>
                        <th>Unit</th>
                        <th>Category</th>
                        <th>Must Order</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataStockLt.map((val, index) => {
                        console.log(val);
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{val.kode}</td>
                            <td>{val.nama}</td>
                            <td>{val.brand}</td>
                            <td>{val.spek}</td>
                            <td>{val.satuan}</td>
                            <td>{val.kategori}</td>
                            <td>{val.minStock - val.stock}</td>
                            <td>
                              <i className="fas fa-plus fa-lg"></i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Col>
            </>
          )} */}
          {/* {dataStockLt.length == 0 ? null : ( */}
          {/* <> */}
          <Col md="12">
            <h4 className="title">Must Order</h4>
          </Col>

          <Col md="12">
            <Card>
              <Card.Body>
                <ReactTable
                  data={dataStockLt}
                  columns={[
                    {
                      Header: "Code",
                      accessor: "code",
                    },
                    {
                      Header: "Item",
                      accessor: "item",
                    },
                    {
                      Header: "Speck",
                      accessor: "speck",
                    },
                    // {
                    //   Header: "Stock",
                    //   accessor: "qty",
                    // },
                    {
                      Header: "Brand",
                      accessor: "brand",
                    },
                    {
                      Header: "Unit",
                      accessor: "satuan",
                    },
                    {
                      Header: "Category",
                      accessor: "kategori",
                    },
                    {
                      Header: "Must Order",
                      accessor: "sisa",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
          {/* </> */}
          {/* )} */}

          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Ordering Form</Card.Title>
              </Card.Header>
              <Card.Body className="table-responsive p-0">
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Qty.</th>
                      <th>Unit</th>
                      <th>Brand</th>
                      <th>Price</th>
                      {/* <th>Total</th>
                      <th>Category</th> */}
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderedItem.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{val.kode}</td>
                          <td>{val.nama}</td>
                          <td>
                            <Form action="#" method="#">
                              <Form.Group>
                                <Form.Control
                                  onChange={(e) => {
                                    // console.log(
                                    //   parseInt(
                                    //     orderedItem[index].harga *
                                    //       e.target.value
                                    //   )
                                    // );
                                    setOrderedItem([
                                      ...orderedItem.slice(0, index),
                                      {
                                        ...val,
                                        unit: e.target.value,
                                        totalHarga: parseInt(
                                          orderedItem[index].harga *
                                            e.target.value
                                        ),
                                      },
                                      ...orderedItem.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                    setDataStockLt([
                                      ...dataStockLt.slice(0, index),
                                      {
                                        ...val,
                                        input: false,
                                      },
                                      ...dataStockLt.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                  }}
                                  value={val.unit}
                                  type="number"
                                  min={1}
                                  max={Number(val.max)}
                                ></Form.Control>
                              </Form.Group>
                            </Form>
                          </td>
                          <td>{val.satuan}</td>
                          <td>{val.brand}</td>
                          {/* <td>
                            <Form action="#" method="#">
                              <Form.Group>
                                <Form.Control
                                  onChange={(e) => {
                                    // console.log(
                                    //   parseInt(
                                    //     orderedItem[index].unit * e.target.value
                                    //   )
                                    // );
                                    setOrderedItem([
                                      ...orderedItem.slice(0, index),
                                      {
                                        ...val,
                                        harga: e.target.value,
                                        totalHarga: parseInt(
                                          orderedItem[index].unit *
                                            e.target.value
                                        ),
                                      },
                                      ...orderedItem.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                  }}
                                  value={val.harga}
                                  type="number"
                                  min={1}
                                  max={Number(val.max)}
                                ></Form.Control>
                              </Form.Group>
                            </Form>
                          </td> */}
                          {/* <td>
                            {`Rp ${val.totalHarga}`} */}
                          {/* <Form action="#" method="#">
                              <Form.Group>
                                <Form.Control
                                  onChange={(e) => {
                                    setOrderedItem([
                                      ...orderedItem.slice(0, index),
                                      {
                                        ...val,
                                        totalHarga: e.target.value,
                                      },
                                      ...orderedItem.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                  }}
                                  value={val.totalHarga}
                                  type="number"
                                  min={1}
                                  max={Number(val.max)}
                                ></Form.Control>
                              </Form.Group>
                            </Form> */}
                          {/* </td> */}
                          <td>{val.kategori}</td>
                          <td>
                            <Button
                              onClick={() => {
                                setOrderedItem([
                                  ...orderedItem.slice(0, index),
                                  ...orderedItem.slice(index + 1, index.length),
                                ]);
                              }}
                              variant="danger"
                              size="md"
                              className="btn-link remove text-danger"
                            >
                              <i className="fas fa-times fa-lg"></i>
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            {orderedItem.length > 0 && (
              <>
                {/* <Select
                  className="react-select primary pb-2"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  onChange={(value) => {
                    setChoosenKategori(value);
                  }}
                  value={choosenKategori}
                  options={[
                    {
                      value: "",
                      label: "Choose the Category",
                      isDisabled: true,
                    },
                    ...listKategori,
                  ]}
                  placeholder="Single Select"
                /> */}
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    let tmp = orderedItem.map((val) => {
                      return {
                        idItem: val.idItem,
                        qty: val.unit,
                        harga: parseInt(val.harga),
                        totalHarga: val.totalHarga,
                      };
                    });
                    pesanBarang({
                      items: JSON.stringify(tmp),
                      // kategori: choosenKategori.value,
                    });
                  }}
                  variant="primary"
                >
                  Submit
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Uploading the Stock
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          {/* <label>File EXCEL</label> */}
                          <Form.Control
                            onChange={(e) => {
                              setDataStock(e.target.files[0]);
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            type="file"
                            id="inputFile"
                            // multiple
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={onsubmit}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
