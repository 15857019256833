import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getProject(dispatch) {
  baseAxios
    .get(`/itproject/getitall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTPROJECT", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpi1ItProject(dispatch, data) {
  baseAxios
    .get(`/itproject/getitgrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_KPI1ITPROJECT", data: respon.data });
    })
    .catch(errorHandler);
}

export function getHardware(dispatch) {
  baseAxios
    .get(`/ithardware/gethardwareitall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTHARDWARE", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpi2Hardware(dispatch, data) {
  baseAxios
    .get(
      `/ithardware/gethardwareitgrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI2HARDWARE", data: respon.data });
    })
    .catch(errorHandler);
}

export function getSoftware(dispatch) {
  baseAxios
    .get(`/itsoftware/getsoftwareitall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTSOFTWARE", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpi3Software(dispatch, data) {
  baseAxios
    .get(
      `/itsoftware/getsoftwareitgrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI3SOFTWARE", data: respon.data });
    })
    .catch(errorHandler);
}
