const initialState = {
  listProject: [],
  listHardware: [],
  listSoftware: [],
  kpi1: null,
  kpi2: null,
  kpi3: null,
};

const itProjectReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_LISTPROJECT":
      return {
        ...state,
        listProject: data,
      };
    case "SET_KPI1ITPROJECT":
      return {
        ...state,
        kpi1: data,
      };
    case "SET_LISTHARDWARE":
      return {
        ...state,
        listHardware: data,
      };
    case "SET_KPI2HARDWARE":
      return {
        ...state,
        kpi2: data,
      };
    case "SET_LISTSOFTWARE":
      return {
        ...state,
        listSoftware: data,
      };
    case "SET_KPI3SOFTWARE":
      return {
        ...state,
        kpi3: data,
      };

    default:
      return state;
  }
};

export default itProjectReducer;
