import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMenuKantinToday, kirimPenilaian } from "../../stores";
import Select from "react-select";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function LoginPage() {
  const dispatch = useDispatch();
  const menuKantin = useSelector((state) => state.kantinReducer);
  const [pertanyaanArr, setPertanyaanArr] = React.useState([]);
  const [nama, setNama] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [karyawanId, setKaryawanId] = React.useState("");
  const [listMenu, setListMenu] = React.useState([]);
  const [idMenu, setIdMenu] = React.useState("");
  const [kodeMenu, setKodeMenu] = React.useState({
    value: "",
    label: "Choose the Menu",
    isDisabled: true,
  });
  const [nilai, setNilai] = React.useState("");
  const [alasan, setAlasan] = React.useState("");
  const [tanggal, setTanggal] = React.useState("");

  let tglSekarang = moment(new Date()).format("YYYY-MM-DD");
  function submit(e) {
    console.log();
    e.preventDefault();
    kirimPenilaian({
      kodeMenuPenilaian: kodeMenu.value,
      tanggalMenu: new Date(`${tglSekarang}T00:00:00.000+00:00`),
      nilai: nilai,
      alasan: alasan,
    });
  }

  React.useEffect(() => {
    let tmp = menuKantin.menuKantinToday.map((val, index) => {
      return {
        ...val,
        label: val.namaMenu,
        value: val.kodeMenu,
      };
    });
    setListMenu(tmp);
    // console.log(tmp);
  }, [menuKantin.menuKantinToday]);
  React.useEffect(() => {
    getMenuKantinToday(dispatch);
  }, []);
  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="survey-form">
          <Card style={{ backgroundColor: "white" }}>
            <Card.Header>
              <Card.Title as="h4">Canteen Menu Rating</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <Form.Group>
                    <label>Select a Menu</label>
                    <Select
                      className="react-select primary pb-2"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      onChange={(value) => {
                        setKodeMenu(value);
                      }}
                      value={kodeMenu}
                      options={[
                        {
                          value: "",
                          label: "Choose the Menu",
                          isDisabled: true,
                        },
                        ...listMenu,
                      ]}
                      placeholder="Single Select"
                    />
                  </Form.Group>
                </Col>

                <Col sm="12">
                  <Form.Group>
                    <label>Berikan Rating Pada Menu Tersebut</label>
                    {/* <Row> */}
                    <ReactStars
                      count={5}
                      onChange={(val) => {
                        setNilai(val.toString());
                      }}
                      size={30}
                      activeColor="#ffd700"
                    />
                  </Form.Group>
                </Col>
                <Col sm="12">
                  <Form.Group>
                    <label>Alasan</label>
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        setAlasan(e.target.value);
                      }}
                      as="textarea"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                className="btn-fill pull-right"
                type="submit"
                variant="info"
                onClick={submit}
              >
                Submit
              </Button>
            </Card.Body>
          </Card>
        </div>

        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
