import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function daftarGedungChecklist(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/lokasichecklist/daftargedungchecklist", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}
export function daftarRuanganChecklist(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/lokasichecklist/daftarruanganchecklist", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}
export function getGedungChecklist(dispatch) {
  baseAxios
    .get("/lokasichecklist/getdatagedungchecklist", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then((respon) => {
      dispatch({ type: "SET_GETALLGEDUNGCHECKLIST", data: respon.data });
    })
    .catch(errorHandler);
}
export function getRuanganByGedung(dispatch) {
  baseAxios
    .get("/lokasichecklist/getruanganbygedung", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then((respon) => {
      dispatch({ type: "SET_GETRUANGANBYGEDUNG", data: respon.data });
    })
    .catch(errorHandler);
}
export function deleteGedungChecklist(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      console.log(data, "????????????????");
      baseAxios
        .delete("/lokasichecklist/deletegedungchecklist", {
          data: { GedungChecklistId: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);
    }
  });
}
export function deleteRuanganChecklist(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      console.log(data, "????????????????");
      baseAxios
        .delete("/lokasichecklist/deletearuanganchecklist", {
          data: { ruanganId: data },
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);
    }
  });
}

export function getShift(dispatch) {
  baseAxios
    .get("/shift/getshiftall", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      console.log(respon, "aaaaaaaaaaaa");
      dispatch({ type: "SET_JAMLAPORAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function downloadQRChecklist(data, ruangan) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .get(`/lokasichecklist/getqrruangchecklist?idRuangan=${data}`, {
      headers: { token: localStorage.getItem("tokenjti") },
      responseType: "blob",
    })
    .then(async (respon) => {
      Swal.close();
      const file = new Blob([respon.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      // window.open(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `QR${ruangan}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(errorHandler);
}
export function editKodeRuanganChecklist(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .patch("/lokasichecklist/editkoderuanganchecklist", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

//===========Form Checklist Kebersihan======================
export function getAreaById(dispatch, data) {
  baseAxios
    .get(`/lokasichecklist/getruanganbyid?idRuangan=${data}`, {})
    .then((respon) => {
      // console.log(respon.data);
      dispatch({ type: "SET_GETRUANGANCHECKLIST", data: respon.data });
    })
    .catch(errorHandler);
}

export function sendDailyChecklist(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });

  let formData = new FormData();
  // data.foto.map((val) => {
  //   console.log(val, ">>>>>>>>>>>>>>>>>>>");
  // });
  // console.log(data.foto[0], "?????????????????????????");

  formData.append("idGedung", data.idGedung);
  formData.append("idRuangan", data.idRuangan);
  formData.append("nama", data.nama);
  formData.append("catatan", data.catatan);
  formData.append("keterangan", data.keterangan);

  formData.append("foto", data.foto[0] === undefined ? "" : data.foto[0]);
  // data.foto.map((val, index) => {
  //   formData.append("foto", val);
  // });
  baseAxios
    .post("/lokasichecklist/checklistharian", formData, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getHasilChecklistByDate(dispatch, data) {
  baseAxios
    .get(
      `/lokasichecklist/gethasilchecklistbydate?idGedung=${data.idGedung}&idRuangan=${data.idRuangan}`,
      {}
    )
    .then((respon) => {
      dispatch({ type: "SET_HASILCHECKLIST", data: respon.data });
    })
    .catch(errorHandler);
}

// ===============Detail Checklist ===============
export function getHasilChecklist(dispatch) {
  baseAxios
    .get(`/lokasichecklist/gethasilchecklist`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then((respon) => {
      dispatch({ type: "SET_MONITORINGHASILCHECKLIST", data: respon.data });
    })
    .catch(errorHandler);
}
