import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { downloadHasilKuisionerExcel, getRekapSurvey } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DataTable from "react-data-table-component";

function ReactTables() {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.surveyReducer);
  const [periode, setPeriode] = React.useState("");
  //   const [modal, setModal] = React.useState(false);
  //   const [project, setProject] = React.useState({});
  const [data, setData] = React.useState([]);
  const [dataNilai, setDataNilai] = React.useState([]);

  const columns = [
    {
      name: "Responden",
      width: "200px",
      sortable: true,
      selector: (row) => row.nama,
    },
    {
      name: "Personnel ID",
      sortable: true,
      width: "150px",
      selector: (row) => row.karyawanId,
    },
    {
      name: "A",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "A") {
            return val.score;
          }
        }),
    },
    {
      name: "B",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "B") {
            return val.score;
          }
        }),
    },
    {
      name: "C",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "C") {
            return val.score;
          }
        }),
    },
    {
      name: "D",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "D") {
            return val.score;
          }
        }),
    },
    {
      name: "E",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "E") {
            return val.score;
          }
        }),
    },
    {
      name: "F",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "F") {
            return val.score;
          }
        }),
    },
    {
      name: "G",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "G") {
            return val.score;
          }
        }),
    },
    {
      name: "H",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "H") {
            return val.score;
          }
        }),
    },
    {
      name: "I",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "I") {
            return val.score;
          }
        }),
    },
    {
      name: "J",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "J") {
            return val.score;
          }
        }),
    },
    {
      name: "K",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "K") {
            return val.score;
          }
        }),
    },
    {
      name: "L",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "L") {
            return val.score;
          }
        }),
    },
    {
      name: "M",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "M") {
            return val.score;
          }
        }),
    },
    {
      name: "N",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "N") {
            return val.score;
          }
        }),
    },
    {
      name: "O",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "O") {
            return val.score;
          }
        }),
    },
    {
      name: "P",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "P") {
            return val.score;
          }
        }),
    },
    {
      name: "Q",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "Q") {
            return val.score;
          }
        }),
    },
    {
      name: "R",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "R") {
            return val.score;
          }
        }),
    },
    {
      name: "S",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "S") {
            return val.score;
          }
        }),
    },
    {
      name: "T",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "T") {
            return val.score;
          }
        }),
    },
    {
      name: "U",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "U") {
            return val.score;
          }
        }),
    },
    {
      name: "V",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "V") {
            return val.score;
          }
        }),
    },
    {
      name: "W",
      // sortable: true,
      cell: (row) =>
        row.pertanyaanArr.map((val) => {
          if (val.jenis === "W") {
            return val.score;
          }
        }),
    },
    {
      name: "Achievement Points",
      width: "150px",
      // sortable: true,
      selector: (row) => row.score,
    },
    {
      name: "Max Points",
      // width: "200px",
      // sortable: true,
      selector: (row) => row.bobotTotal,
    },
    {
      name: "Index per Responden",
      width: "180px",
      sortable: true,
      cell: (row) => Math.round((row.score / row.bobotTotal) * 100) + "%",
    },
  ];
  const ExpandedComponent1 = ({ data }) => (
    <>
      <ul style={{ marginTop: 10 }}>
        <li>Opinion : {data.deskripsi == "" ? "-" : data.deskripsi}</li>
      </ul>
    </>
  );

  const columns1 = [
    {
      name: "Question",
      width: "100px",
      sortable: true,
      selector: (row) => row.nama,
    },
    {
      name: "Total Responden",
      // width: "150px",

      sortable: true,
      selector: (row) => row.jumlah,
    },
    {
      name: "Total Score",
      // width: "200px",
      sortable: true,
      selector: (row) => row.total,
    },

    {
      name: "Index per Question",
      // width: "200px",
      sortable: true,
      cell: (row) =>
        row.jumlah == 0 ? "0 %" : Math.round(row.total / row.jumlah) + "%",
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <>
      <ul style={{ marginTop: 10 }}>
        <li>Type : {data.nama}</li>
        <li>Question : {data.pertanyaan}</li>
      </ul>
    </>
  );

  React.useEffect(() => {
    let tmp =
      survey.rekapkuisioner.data &&
      survey.rekapkuisioner.data.map((val, index) => {
        return {
          ...val,
          createdAt: moment(val.createdAt).format("DD MMM YYYY"),
          actions: (
            <div className="actions-right">
              {/* <Button
              onClick={() => {
                  setDataGedung(val);
                  setModalRuangan(true);
                downloadFile(val.filePendukung[0]);
                console.log(val._id);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>{" "} */}
            </div>
          ),
          // a: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "A") {
          //     return val.score;
          //   }
          // }),
          // b: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "B") {
          //     return val.score;
          //   }
          // }),
          // c: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "C") {
          //     return val.score;
          //   }
          // }),
          // d: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "D") {
          //     return val.score;
          //   }
          // }),
          // e: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "E") {
          //     return val.score;
          //   }
          // }),
          // f: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "F") {
          //     return val.score;
          //   }
          // }),
          // g: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "G") {
          //     return val.score;
          //   }
          // }),
          // h: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "H") {
          //     return val.score;
          //   }
          // }),
          // i: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "I") {
          //     return val.score;
          //   }
          // }),
          // j: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "J") {
          //     return val.score;
          //   }
          // }),
          // k: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "K") {
          //     return val.score;
          //   }
          // }),
          // l: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "L") {
          //     return val.score;
          //   }
          // }),
          // m: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "M") {
          //     return val.score;
          //   }
          // }),
          // n: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "N") {
          //     return val.score;
          //   }
          // }),
          // o: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "O") {
          //     return val.score;
          //   }
          // }),
          // p: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "P") {
          //     return val.score;
          //   }
          // }),
          // q: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "Q") {
          //     return val.score;
          //   }
          // }),
          // r: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "R") {
          //     return val.score;
          //   }
          // }),
          // s: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "S") {
          //     return val.score;
          //   }
          // }),
          // t: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "T") {
          //     return val.score;
          //   }
          // }),
          // u: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "U") {
          //     return val.score;
          //   }
          // }),
          // v: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "V") {
          //     return val.score;
          //   }
          // }),
          // w: val.pertanyaanArr.map((val) => {
          //   if (val.jenis === "W") {
          //     return val.score;
          //   }
          // }),
        };
      });
    setData(tmp);
    setDataNilai(survey.rekapkuisioner.dataNilai);
  }, [survey.rekapkuisioner.data]);
  React.useEffect(() => {
    getRekapSurvey(dispatch);
  }, []);

  // console.log(dataNilai);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Survey Recapitulation</h4>
            <Row>
              <Col md="6">
                <Form.Group>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={periode.val}
                    onChange={(val) => setPeriode(val.value)}
                    options={[
                      { value: "Periode 1", label: "Periode 1" },
                      { value: "Periode 2", label: "Periode 2" },
                      { value: "Periode 3", label: "Periode 3" },
                      { value: "Periode 4", label: "Periode 4" },
                    ]}
                    placeholder="-Choose Periode-"
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                {" "}
                <Button
                  onClick={() => {
                    downloadHasilKuisionerExcel({ periode: periode });
                  }}
                  className="btn-wd btn-outline mr-1"
                  type="button"
                  variant={"info"}
                >
                  Export Survey
                </Button>
              </Col>
            </Row>

            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  expandableRows
                  expandableRowsComponent={ExpandedComponent1}
                />
                {/* <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "nama",
                    },
                    {
                      Header: "Personnel ID",
                      accessor: "karyawanId",
                    },
                    {
                      Header: "A",
                      accessor: "a",
                    },
                    {
                      Header: "B",
                      accessor: "b",
                    },
                    {
                      Header: "C",
                      accessor: "c",
                    },
                    {
                      Header: "D",
                      accessor: "d",
                    },
                    {
                      Header: "E",
                      accessor: "e",
                    },
                    {
                      Header: "F",
                      accessor: "f",
                    },
                    {
                      Header: "G",
                      accessor: "g",
                    },
                    {
                      Header: "H",
                      accessor: "h",
                    },
                    {
                      Header: "I",
                      accessor: "i",
                    },
                    {
                      Header: "J",
                      accessor: "j",
                    },
                    {
                      Header: "K",
                      accessor: "k",
                    },
                    {
                      Header: "L",
                      accessor: "l",
                    },
                    {
                      Header: "M",
                      accessor: "m",
                    },
                    {
                      Header: "N",
                      accessor: "n",
                    },
                    {
                      Header: "O",
                      accessor: "o",
                    },
                    {
                      Header: "P",
                      accessor: "p",
                    },
                    {
                      Header: "Q",
                      accessor: "q",
                    },
                    {
                      Header: "R",
                      accessor: "r",
                    },
                    {
                      Header: "S",
                      accessor: "s",
                    },
                    {
                      Header: "T",
                      accessor: "t",
                    },
                    {
                      Header: "U",
                      accessor: "u",
                    },
                    {
                      Header: "V",
                      accessor: "v",
                    },
                    {
                      Header: "W",
                      accessor: "w",
                    },
                    {
                      Header: "Submit Date",
                      accessor: "createdAt",
                    },

                    {
                      Header: "",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h4 className="title">Percentage of Quetions</h4>

            <Card>
              <Card.Body>
                <DataTable
                  columns={columns1}
                  data={dataNilai}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  pagination
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      > */}
      {/* <Modal.Header closeButton> */}
      {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
      {/* </Modal.Header> */}
      {/* <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Pengajuan Project</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Kategori</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={
                              !project.category
                                ? project.category
                                : project.category.val
                            }
                            onChange={(val) =>
                              setProject({
                                ...project,
                                category: val.label,
                              })
                            }
                            options={monitoring.listKategori.map(
                              (val, index) => {
                                return {
                                  value: val.jenis,
                                  label: val.jenis,
                                };
                              }
                            )}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                        <FormGroup>
                          <label>Judul</label>
                          <Form.Control
                            onChange={(e) => {
                              setProject({
                                ...project,
                                title: e.target.value,
                              });
                            }}
                            placeholder="Masukan Judul Project"
                            type="text"
                          ></Form.Control>
                        </FormGroup>
                        <FormGroup>
                          <label>Deskripsi</label>
                          <Form.Control
                            onChange={(e) => {
                              setProject({
                                ...project,
                                deskripsi: e.target.value,
                              });
                            }}
                            placeholder="Tuliskan Deskripsi Project"
                            type="text"
                          ></Form.Control>
                        </FormGroup>
                        <FormGroup>
                          <label>Tanggal Mulai Project</label>
                          <ReactDatetime
                            dateFormat="DD MMMM YYYY"
                            value={project.startDate}
                            onChange={(e) => {
                              setProject({
                                ...project,
                                startDate: e.toDate().toDateString(),
                              });
                            }}
                            inputProps={{
                              className: "form-control",
                              placeholder: "Pilih tanggal mulai",
                            }}
                            timeFormat={false}
                          ></ReactDatetime>
                        </FormGroup>
                        <FormGroup>
                          <label>Tanggal Akhir Project</label>
                          <ReactDatetime
                            dateFormat="DD MMMM YYYY"
                            value={project.deadlineDate}
                            onChange={(e) => {
                              setProject({
                                ...project,
                                deadlineDate: e.toDate().toDateString(),
                              });
                            }}
                            inputProps={{
                              className: "form-control",
                              placeholder: "Pilih tanggal akhir",
                            }}
                            timeFormat={false}
                          ></ReactDatetime>
                        </FormGroup>
                        <FormGroup>
                          <label>File Pendukung</label>
                          <Form.Control
                            onChange={(e) => {
                              setProject({
                                ...project,
                                dokumen: e.target.files[0],
                              });
                            }}
                            type="file"
                          ></Form.Control>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        pengajuanProject(fd);
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body> */}
      {/* </Modal> */}
    </>
  );
}

export default ReactTables;
