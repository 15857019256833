const initialState = {
  pertanyaan: [],
  variablesurvey: null,
  rekapkuisioner: [],
  statusUser: false,
  ruanganChecklist: [],
  ruanganChecklistShift1: [],
  ruanganChecklistShift2: [],
  ruanganChecklistById: [],
};

const surveyReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_PERTANYAAN":
      return {
        ...state,
        pertanyaan: data,
      };
    case "SET_VARIABLESURVEY":
      return {
        ...state,
        variablesurvey: data,
      };
    case "SET_REKAPSURVEY":
      return {
        ...state,
        rekapkuisioner: data,
      };
    case "SET_STATUS":
      return {
        ...state,
        statusUser: data,
      };
    case "SET_LISTRUANGANCHECKLIST":
      return {
        ...state,
        ruanganChecklist: data,
      };
    case "SET_LISTRUANGANCHECKLISTSHIFT1":
      return {
        ...state,
        ruanganChecklistShift1: data,
      };
    case "SET_LISTRUANGANCHECKLISTSHIFT2":
      return {
        ...state,
        ruanganChecklistShift2: data,
      };
    case "SET_RUANGANCHECKLISTBYID":
      return {
        ...state,
        ruanganChecklistById: data,
      };
    default:
      return state;
  }
};

export default surveyReducer;
