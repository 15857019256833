import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getItemOrder,
  pesanBarang,
  getKategoryCS,
  importStock,
  getStockGudangLt,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";
function ReactTables() {
  const dispatch = useDispatch();
  const consumable = useSelector((state) => state.consumableReducer);

  const [data, setData] = React.useState([]);
  const [orderedItem, setOrderedItem] = React.useState([]);
  const [listKategori, setListKategori] = React.useState([]);
  const [choosenKategori, setChoosenKategori] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  const [modal, setModal] = React.useState(false);
  const [dataStock, setDataStock] = React.useState(null);

  function onsubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("file", dataStock);
    importStock(fd);
  }

  React.useEffect(() => {
    let tmp = consumable.items.map((val, index) => {
      return {
        code: val.kode,
        item: val.nama,
        // qty: val.stock,
        satuan: val.satuan,
        brand: val.brand,
        kategori: val.kategori,
        speck: val.spek,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                let tmp2 = [
                  ...orderedItem,
                  {
                    idItem: val._id,
                    kode: val.kode,
                    nama: val.nama,
                    unit: 1,
                    harga: 0,
                    totalHarga: 0,
                    satuan: val.satuan,
                    kategori: val.kategori,
                    max: val.stock,
                  },
                ];
                setOrderedItem(tmp2);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fas fa-plus fa-lg"></i>
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [consumable.items, orderedItem]);

  React.useEffect(() => {
    let tmp = consumable.kategori.map((val, index) => {
      return {
        label: val.nama,
        value: val.nama,
      };
    });
    setListKategori(tmp);
  }, [consumable.kategori]);

  React.useEffect(() => {
    getItemOrder(dispatch);
    getKategoryCS(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Uploading the Stock
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Order</h4>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Code",
                      accessor: "code",
                    },
                    {
                      Header: "Item",
                      accessor: "item",
                    },
                    {
                      Header: "Speck",
                      accessor: "speck",
                    },
                    // {
                    //   Header: "Stock",
                    //   accessor: "qty",
                    // },
                    {
                      Header: "Unit",
                      accessor: "satuan",
                    },
                    {
                      Header: "Category",
                      accessor: "kategori",
                    },
                    {
                      Header: " ",
                      //   accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Uploading the Stock
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          {/* <label>File EXCEL</label> */}
                          <Form.Control
                            onChange={(e) => {
                              setDataStock(e.target.files[0]);
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            type="file"
                            id="inputFile"
                            // multiple
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={onsubmit}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
