/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";

import LoginPage from "views/Pages/LoginPage.js";
import SettingGedungPage from "views/Pages/PatroliKebersihan/SettingGedung";
import SettingShiftPage from "views/Pages/PatroliKebersihan/SettingShift";
import RekapMonitoringPage from "views/Pages/PatroliKebersihan/RekapLaporan";
import SurveyFormPage from "views/Pages/SurveyForm";
import DashboardMonitoringPage from "views/Pages/PatroliKebersihan/DashboardMonitoring";
import DashboardUtamaPage from "views/DashboardUtama";
import DetailMonitoringKebersihan from "views/Pages/Kebersihan/DetailMonitoringKebersihan";
import TambahGedung from "views/Pages/Kebersihan/TambahGedung";
import SettingShift from "views/Pages/Kebersihan/SettingShift";
import TambahKategori from "views/Pages/Project/TambahKategori";
import PengajuanProject from "views/Pages/Project/PengajuanProject";
import VerifikasiProject from "views/Pages/Project/VerifyProject";
import CalendarProject from "views/Pages/Project/CalendarProject";
import RekapProblem from "views/Pages/Kebersihan/RekapProblem";
import RekapKeluhan from "views/Pages/Kebersihan/RekapKeluhan";
import RekapKunjungan from "views/Pages/Kebersihan/RekapKunjungan";
import RekapSurvey from "views/Pages/Survey/RekapSurvey";
import VariableSurvey from "views/Pages/Survey/VariableSurvey";
import SettingSurvey from "views/Pages/Survey/SurveyForm";
import KeluhanProgress from "views/Pages/KeluhanProgress";
import VerifikasiProposal from "views/Pages/Proposal/VerifikasiProposal";
import VerifikasiTraining from "views/Pages/TrainingPersonnel/Training";
import VerifikasiPembinaan from "views/Pages/TrainingPersonnel/Pembinaan";
import VerifikasiVisit from "views/Pages/PestRodent/Visit";
import TemuanPestRodent from "views/Pages/PestRodent/TemuanPest";
import Safety from "views/Pages/Safety/Kejadian";
import VisitIndustrial from "views/Pages/IndustrialIssue/Visit";
import Issue from "views/Pages/IndustrialIssue/Issue";
import ProjectIT from "views/Pages/IT/ProjectIt";
import KeluhanPengajuanPage from "views/Pages/KeluhanPengajuan";

import RekapConsumable from "views/Pages/Consumable/RekapOrder";
import PesanConsumable from "views/Pages/Consumable/Pemesanan";
import StockBAP from "views/Pages/ConsumableBAP/Stock";
import RekapOrder from "views/Pages/ConsumableBAP/OrderStock";
import VariableConsumable from "views/Pages/Consumable/DetailMonitoringCS";
import DetailMonitoringTP from "views/Pages/TrainingPersonnel/DetailMonitoringTP";
import DetailMonitoringPR from "views/Pages/PestRodent/DetailMonitoringPR";
import DetailMonitoringIT from "views/Pages/IT/DetailMonitoringIT";
import DetailMonitoringST from "views/Pages/Safety/DetailMonitoringST";
import DetailMonitoringIS from "views/Pages/IndustrialIssue/DetailMonitoringIS";
import Hardware from "views/Pages/IT/Hardware";
import Software from "views/Pages/IT/Software";
import CalendarTraining from "views/Pages/TrainingPersonnel/CalendarTraining";
import CalendarVisitPest from "views/Pages/PestRodent/CalendarVisitPest";
import CalendarVisitIndustrial from "views/Pages/IndustrialIssue/CalendarVisitIndustrial";
import AbsensiKaryawan from "views/Pages/TrainingPersonnel/AbsenKaryawan";
import OrderGudang from "views/Pages/Consumable/PemesananGudang";
import RekapWarehouse from "views/Pages/Consumable/RekapOrderGudang";
import DetailConsumableBAP from "views/Pages/ConsumableBAP/DetailConsumable";
import DaftarFinger from "views/Pages/DaftarFinger";
import CalendarPatroli from "views/Pages/Kebersihan/JadwalPatroli";
import SettingJumlahKaryawan from "views/Pages/TrainingPersonnel/SettingJumlahKaryawan";
import CheckListKebersihanPage from "views/Pages/FormKebersihan/CheckListKebersihan";

import PenilaianMenuKantin from "views/Pages/PenilaianMenuKantin";
import TambahMenuKantin from "views/Pages/MenuKantin/TambahMenuKantin";
import RekapKantin from "views/Pages/MenuKantin/RekapNilai";
import DetailMonitoringFM from "views/Pages/MenuKantin/DetailMonitoringFM";

import GedungChecklist from "views/Pages/ChecklistKebersihan/TambahGedungChecklist";
import ChecklistKebersihan from "views/Pages/ChecklistKebersihan";
import HasilChecklist from "views/Pages/ChecklistKebersihan/HasilChecklist";
var routes = [
  // {
  //   show: true,
  //   path: "/dashboard",
  //   layout: "/admin",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       show: true,
  //       path: "/buttons",
  //       layout: "/admin",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       show: true,
  //       path: "/grid-system",
  //       layout: "/admin",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       show: true,
  //       path: "/panels",
  //       layout: "/admin",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       show: true,
  //       path: "/sweet-alert",
  //       layout: "/admin",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       show: true,
  //       path: "/notifications",
  //       layout: "/admin",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  //     {
  //       show: true,
  //       path: "/icons",
  //       layout: "/admin",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //     },
  //     {
  //       show: true,
  //       path: "/typography",
  //       layout: "/admin",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   group: "form",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-forms",
  //       layout: "/admin",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //       group: "form",
  //     },
  //     {
  //       show: true,
  //       path: "/extended-forms",
  //       layout: "/admin",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //       group: "form",
  //     },
  //     {
  //       show: true,
  //       path: "/validation-forms",
  //       layout: "/admin",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //       group: "form",
  //     },
  //     {
  //       path: "/wizard",
  //       layout: "/admin",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //       group: "form",
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-tables",
  //       layout: "/admin",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //     },
  //     {
  //       show: true,
  //       path: "/extended-tables",
  //       layout: "/admin",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //     },
  //     {
  //       show: true,
  //       path: "/react-table",
  //       layout: "/admin",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       show: true,
  //       path: "/google-maps",
  //       layout: "/admin",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //     },
  //     {
  //       show: true,
  //       path: "/full-screen-maps",
  //       layout: "/admin",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //     },
  //     {
  //       show: true,
  //       path: "/vector-maps",
  //       layout: "/admin",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //     },
  //   ],
  // },
  // {
  //   path: "/charts",
  //   layout: "/admin",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  // },
  // {
  //   show: true,
  //   path: "/calendar",
  //   layout: "/admin",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar,
  //   group: "Dashboard",
  // },
  // // Batas Template

  // {
  //   collapse: true,
  //   path: "/monitoringkebersihan",
  //   name: "Monitoring",
  //   state: "openKebersihan",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [
  //     {
  //       path: "/dashboardmonitoring",
  //       layout: "/admin",
  //       name: "Dashboard",
  //       mini: "D",
  //       component: DashboardMonitoringPage,
  //     },
  //     {
  //       path: "/rekapmonitoring",
  //       layout: "/admin",
  //       name: "Rekap Monitoring",
  //       mini: "RM",
  //       component: RekapMonitoringPage,
  //     },
  //     {
  //       path: "/settingarea",
  //       layout: "/admin",
  //       name: "Setting Area",
  //       mini: "SA",
  //       component: SettingGedungPage,
  //     },
  //     {
  //       path: "/settingshift",
  //       layout: "/admin",
  //       name: "Setting Shift",
  //       mini: "SS",
  //       component: SettingShiftPage,
  //     },
  //   ],
  // },

  {
    show: true,
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: DashboardUtamaPage,
    group: "Dashboard",
  },

  // {
  //   show: true,
  //   collapse: true,
  //   path: "/proposal",
  //   name: "Proposal",
  //   state: "openc",
  //   icon: "fas fa-file-signature",
  //   views: [
  //     {
  //       show: true,
  //       path: "/verifikasiProposal",
  //       layout: "/admin",
  //       name: "Verifikasi Proposal",
  //       mini: "VP",
  //       component: VerifikasiProposal,
  //     },
  //   ],
  // },
  {
    show: false,
    path: "/keluhan",
    layout: "/survey",
    name: "Keluhan",
    icon: "nc-icon nc-chart-pie-35",
    component: KeluhanPengajuanPage,
  },
  {
    show: true,
    collapse: true,
    path: "/kebersihan",
    name: "Cleaning Service",
    state: "opena",
    icon: "fas fa-broom",
    group: "Cleaning Service",
    views: [
      {
        show: true,
        path: "/detailMonitoring",
        layout: "/admin",
        name: "Cleaning Recapitulation",
        mini: "CR",
        component: DetailMonitoringKebersihan,
        group: "Cleaning Service",
      },
      {
        show: true,
        path: "/kunjunganGedung",
        layout: "/admin",
        name: "Visiting Report",
        mini: "VR",
        component: RekapKunjungan,
        group: "Cleaning Service",
      },
      {
        show: true,
        path: "/rekapProblem",
        layout: "/admin",
        name: "Problem Report",
        mini: "PR",
        component: RekapProblem,
        group: "Cleaning Service",
      },
      {
        show: true,
        path: "/rekapKeluhan",
        layout: "/admin",
        name: "Complaining Report",
        mini: "CR",
        component: RekapKeluhan,
        group: "Cleaning Service",
      },
      {
        path: "/verifyProject",
        layout: "/admin",
        name: "Project Verification",
        mini: "PV",
        component: VerifikasiProject,
        show: true,
        group: "Cleaning Service",
      },
      {
        path: "/calendarProject",
        layout: "/admin",
        name: "Project Schedule",
        mini: "PS",
        component: CalendarProject,
        show: true,
        group: "Cleaning Service",
      },
    ],
  },

  {
    show: true,
    collapse: true,
    path: "/trainingpersonnel",
    name: "Training & Personnel",
    state: "opend",
    icon: "fas fa-user-tie",
    group: "Training1",
    views: [
      {
        show: true,
        path: "/chartTraining",
        layout: "/admin",
        name: "Training & Personnel Recapitulation",
        mini: "TP",
        component: DetailMonitoringTP,
        group: "Training1",
      },
      {
        show: true,
        path: "/absen",
        layout: "/admin",
        name: "Personnel Attendance",
        mini: "PA",
        component: AbsensiKaryawan,
        group: "Training1",
      },
      {
        show: true,
        path: "/training",
        layout: "/admin",
        name: "Training",
        mini: "TR",
        component: VerifikasiTraining,
        group: "Training",
      },
      {
        show: true,
        path: "/pembinaan",
        layout: "/admin",
        name: "Mentoring",
        mini: "M",
        component: VerifikasiPembinaan,
        group: "Training",
      },
      {
        path: "/calendarTraining",
        layout: "/admin",
        name: "Training Schedule",
        mini: "TS",
        component: CalendarTraining,
        show: true,
        group: "Training",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/pestrodent",
    name: "Pest & Rodent",
    state: "opene",
    icon: "fas fa-cat",
    group: "Pest",
    views: [
      {
        show: true,
        path: "/chartPest",
        layout: "/admin",
        name: "Pest & Rodent Recapitulation",
        mini: "PR",
        component: DetailMonitoringPR,
        group: "Pest",
      },
      {
        show: true,
        path: "/kunjunganPest",
        layout: "/admin",
        name: "Treatment Monitor",
        mini: "TM",
        component: VerifikasiVisit,
        group: "Pest",
      },
      {
        show: true,
        path: "/temuanPest",
        layout: "/admin",
        name: "Finding",
        mini: "F",
        component: TemuanPestRodent,
        group: "Pest",
      },
      {
        path: "/calendarVisitPest",
        layout: "/admin",
        name: "Pest & Rodent Treatment Schedule",
        mini: "TS",
        component: CalendarVisitPest,
        show: true,
        group: "Pest",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/safety",
    name: "Safety",
    state: "openf",
    icon: "fas fa-hard-hat",
    group: "Safety",
    views: [
      {
        show: true,
        path: "/chartSafety",
        layout: "/admin",
        name: "Safety Recapitulation",
        mini: "SR",
        component: DetailMonitoringST,
        group: "Safety",
      },
      {
        show: true,
        path: "/kejadian",
        layout: "/admin",
        name: "Incident",
        mini: "I",
        component: Safety,
        group: "Safety",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/industrial",
    name: "Industrial Issue",
    state: "openg",
    icon: "fas fa-industry",
    group: "Industrial",
    views: [
      {
        show: true,
        path: "/chartIssue",
        layout: "/admin",
        name: "Industrial Issue Recapitulation",
        mini: "IR",
        component: DetailMonitoringIS,
        group: "Industrial",
      },
      {
        show: true,
        path: "/kunjunganIndustrial",
        layout: "/admin",
        name: "Industrial Visit",
        mini: "IV",
        component: VisitIndustrial,
        group: "Industrial",
      },
      {
        show: true,
        path: "/issue",
        layout: "/admin",
        name: "Issue",
        mini: "IS",
        component: Issue,
        group: "Industrial",
      },
      {
        path: "/calendarVisitIndustrial",
        layout: "/admin",
        name: "Industrial Issue Visit Schedule",
        mini: "VS",
        component: CalendarVisitIndustrial,
        show: true,
        group: "Industrial",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/safety",
    name: "IT",
    state: "openh",
    icon: "fas fa-mobile-alt",
    group: "IT",
    views: [
      {
        show: true,
        path: "/chartIT",
        layout: "/admin",
        name: "IT Recapitulation",
        mini: "RP",
        component: DetailMonitoringIT,
        group: "IT",
      },
      {
        show: true,
        path: "/project",
        layout: "/admin",
        name: "IT Project",
        mini: "IP",
        component: ProjectIT,
        group: "IT",
      },
      {
        show: true,
        path: "/hardware",
        layout: "/admin",
        name: "Hardware Problem",
        mini: "HP",
        component: Hardware,
        group: "IT",
      },
      {
        show: true,
        path: "/software",
        layout: "/admin",
        name: "Software Problem",
        mini: "SP",
        component: Software,
        group: "IT",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/survey-admin",
    name: "Survey",
    state: "opensurvey",
    icon: "fas fa-chart-line",
    group: "Survey",
    views: [
      {
        show: true,
        path: "/variable-survey",
        layout: "/admin",
        name: "Survey Variable",
        mini: "SV",
        component: VariableSurvey,
        group: "Survey",
      },
      {
        show: true,
        path: "/rekap-survey",
        layout: "/admin",
        name: "Survey Recapitulation",
        mini: "SR",
        component: RekapSurvey,
        group: "Survey",
      },
      // {
      //   show: true,
      //   path: "/setting-survey",
      //   layout: "/admin",
      //   name: "Creating Survey",
      //   mini: "CS",
      //   component: SettingSurvey,
      //   group: "Survey",
      // },
    ],
  },

  {
    show: true,
    collapse: true,
    path: "/consumablesupply",
    name: "Consumable",
    state: "openi",
    icon: "fas fa-box-open",
    group: "Consumable",
    views: [
      {
        show: true,
        path: "/variableconsumable",
        layout: "/admin",
        name: "Consumable Variable",
        mini: "CV",
        component: VariableConsumable,
        group: "Consumable",
      },
      {
        show: true,
        path: "/pemasanan",
        layout: "/admin",
        name: "Order",
        mini: "O",
        component: PesanConsumable,
        group: "Consumable",
      },
      {
        show: true,
        path: "/rekapconsumable",
        layout: "/admin",
        name: "Order Recapitulation",
        mini: "OR",
        component: RekapConsumable,
        group: "Consumable",
      },
      {
        show: true,
        path: "/pemesanangudang",
        layout: "/admin",
        name: "Warehouse",
        mini: "W",
        component: OrderGudang,
        group: "Consumable",
      },
      {
        show: true,
        path: "/rekapconsumablegudang",
        layout: "/admin",
        name: "Order Warehouse",
        mini: "OW",
        component: RekapWarehouse,
        group: "Consumable",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/menukantin",
    name: "Menu Canteen",
    state: "openj",
    icon: "fas fa-hamburger",
    group: "MenuKantin",
    views: [
      {
        show: true,
        path: "/detailmonitoringfood",
        layout: "/admin",
        name: "Canteen Variable",
        mini: "CV",
        component: DetailMonitoringFM,
        group: "MenuKantin",
      },
      {
        show: true,
        path: "/tambahmenukantin",
        layout: "/admin",
        name: "Add Food",
        mini: "AF",
        component: TambahMenuKantin,
        group: "MenuKantin",
      },

      {
        show: true,
        path: "/rekapkantin",
        layout: "/admin",
        name: "Canteen Recapitulation",
        mini: "CR",
        component: RekapKantin,
        group: "MenuKantin",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/buildingchecklist",
    name: "Building Checklist",
    state: "openk",
    icon: "fas fa-building",
    group: "GedungChecklist",
    views: [
      // {
      //   show: true,
      //   path: "/detailmonitoringfood",
      //   layout: "/admin",
      //   name: "Canteen Variable",
      //   mini: "CV",
      //   component: DetailMonitoringFM,
      //   group: "GedungChecklist",
      // },
      {
        show: true,
        path: "/tambahgedungchecklist",
        layout: "/admin",
        name: " Add Building Checklist",
        mini: "BC",
        component: GedungChecklist,
        group: "GedungChecklist",
      },
      {
        show: true,
        path: "/hasilchecklist",
        layout: "/admin",
        name: " Recapitulation Building  Checklist",
        mini: "BC",
        component: HasilChecklist,
        group: "GedungChecklist",
      },

      // {
      //   show: true,
      //   path: "/rekapkantin",
      //   layout: "/admin",
      //   name: "Canteen Recapitulation",
      //   mini: "CR",
      //   component: RekapKantin,
      //   group: "GedungChecklist",
      // },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/stockBAP",
    name: "Consumable BAP",
    state: "openc",
    icon: "fas fa-box-open",
    group: "Stock BAP",
    views: [
      {
        show: true,
        path: "/variableconsumablebap",
        layout: "/admin",
        name: "Consumable Variable",
        mini: "CV",
        component: DetailConsumableBAP,
        group: "Stock BAP",
      },
      {
        show: true,
        path: "/stock",
        layout: "/admin",
        name: "Stock",
        mini: "S",
        component: StockBAP,
        group: "Stock BAP",
      },
      {
        show: true,
        path: "/rekaporder",
        layout: "/admin",
        name: "Order Recapitulation",
        mini: "OR",
        component: RekapOrder,
        group: "Stock BAP",
      },
      // {
      //   show: true,
      //   path: "/pemesanangudang",
      //   layout: "/admin",
      //   name: "Warehouse",
      //   mini: "W",
      //   component: OrderGudang,
      //   group: "Stock BAP",
      // },
      // {
      //   show: true,
      //   path: "/rekapconsumablegudang",
      //   layout: "/admin",
      //   name: "Order Warehouse",
      //   mini: "OW",
      //   component: RekapWarehouse,
      //   group: "Stock BAP",
      // },
    ],
  },

  // {
  //   collapse: true,
  //   path: "/project",
  //   name: "Project",
  //   state: "openc",
  //   icon: "fas fa-tasks",
  //   show: true,
  //   views: [
  // {
  //   path: "/tambahKategori",
  //   layout: "/admin",
  //   name: "Tambah Kategori",
  //   mini: "TC",
  //   component: TambahKategori,
  //   show: true,
  // },
  // {
  //   path: "/pengajuanProject",
  //   layout: "/admin",
  //   name: "Pengajuan Project",
  //   mini: "AP",
  //   component: PengajuanProject,
  //   show: true,
  // },
  // {
  //   path: "/verifyProject",
  //   layout: "/admin",
  //   name: "Verifikasi Project",
  //   mini: "VP",
  //   component: VerifikasiProject,
  //   show: true,
  // },
  // {
  //   path: "/calendarProject",
  //   layout: "/admin",
  //   name: "Calendar Project",
  //   mini: "CP",
  //   component: CalendarProject,
  //   show: true,
  // },
  // ],
  // },
  // {
  //   collapse: true,
  //   path: "/facilityb",
  //   name: "Facility B",
  //   state: "openb",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [],
  // },
  {
    show: true,
    collapse: true,
    path: "/settingArea",
    name: "Setting",
    state: "openb",
    icon: "fas fa-cogs",
    group: "Setting",
    views: [
      {
        show: true,
        path: "/calendarPatrol",
        layout: "/admin",
        name: "Patrol Calendar",
        mini: "PC",
        component: CalendarPatroli,
        group: "Setting",
      },
      {
        show: true,
        path: "/settingJumlahKaryawan",
        layout: "/admin",
        name: "Setting Employees",
        mini: "SE",
        component: SettingJumlahKaryawan,
        group: "Setting",
      },
      {
        show: true,
        path: "/tambahGedung",
        layout: "/admin",
        name: "Building",
        mini: "B",
        component: TambahGedung,
        group: "Setting",
      },
      {
        show: true,
        path: "/settingShift",
        layout: "/admin",
        name: "Setting Shift",
        mini: "SS",
        component: SettingShift,
        group: "Setting",
      },
    ],
  },
  {
    show: false,
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "nc-icon nc-puzzle-10",
    views: [
      // {
      //   path: "/user-page",
      //   layout: "/admin",
      //   name: "User Page",
      //   mini: "UP",
      //   component: UserPage,
      // },
      {
        show: false,
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage,
      },
    ],
  },
  {
    show: false,
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openSurvey",
    icon: "nc-icon nc-puzzle-10",
    views: [
      {
        show: false,
        path: "/survey-form",
        layout: "/survey",
        name: "Survey",
        mini: "S",
        component: SurveyFormPage,
      },
      {
        show: false,
        path: "/keluhan-progress",
        layout: "/survey",
        name: "Survey",
        mini: "S",
        component: KeluhanProgress,
      },
      {
        show: false,
        path: "/checklist-form",
        layout: "/survey",
        name: "Survey",
        mini: "S",
        component: CheckListKebersihanPage,
      },
      {
        show: false,
        path: "/penilaian-menu",
        layout: "/survey",
        name: "MenuKantin",
        mini: "MK",
        component: PenilaianMenuKantin,
      },
      {
        show: false,
        path: "/form-checklist-kebersihan",
        layout: "/survey",
        name: "MenuKantin",
        mini: "MK",
        component: ChecklistKebersihan,
      },
    ],
  },
  {
    show: false,
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openFinger",
    icon: "nc-icon nc-puzzle-10",
    views: [
      {
        show: false,
        path: "/daftar-finger",
        layout: "/finger",
        name: "Daftar Finger",
        mini: "D",
        component: DaftarFinger,
      },
    ],
  },
];
export default routes;
