import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Tab,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  daftarGedungChecklist,
  daftarRuanganChecklist,
  deleteGedungChecklist,
  deleteRuanganChecklist,
  getGedungChecklist,
  getRuanganByGedung,
  getShift,
  downloadQRChecklist,
  editKodeRuanganChecklist,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function TambahGedungChecklist() {
  const dispatch = useDispatch();
  const date = new Date();
  const gedungChecklist = useSelector((state) => state.gedungChecklistReducer);
  const [modalTambahGedung, setModalTambahGedung] = React.useState(false);
  const [modalTambahRuangan, setModalTambahRuangan] = React.useState(false);
  const [modalEditRuangan, setModalEditRuangan] = React.useState(false);
  const [modalDataGedung, setModalDataGedung] = React.useState("");
  const [addGedungBaru, setAddGedungBaru] = React.useState("");
  const [addRuangan, setAddRuangan] = React.useState("");
  const [getGedungBaru, setGetGedungBaru] = React.useState([]);
  const [getRuangan, setGetRuangan] = React.useState([]);
  const [kodeRuanganChecklist, setKodeRuanganChecklist] = React.useState("");
  const [idKodeRuanganCheckilst, setIdKodeRuanganCheckilst] =
    React.useState("");

  React.useEffect(() => {
    getRuanganByGedung(dispatch);
    getShift(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp =
      gedungChecklist.ruanganByGedung &&
      gedungChecklist.ruanganByGedung.map((val, index) => {
        return {
          ...val,
          totalRuangan: val.ruangan.length,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModalDataGedung(val);
                  setModalTambahRuangan(true);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-plus-circle fa-lg" />
              </Button>{" "}
              {/* <Button
                onClick={() => {
                  console.log(val._id);

                  downloadQRChecklist(val._id);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-qrcode fa-lg" />
              </Button> */}
              <Button
                onClick={() => {
                  deleteGedungChecklist(val._id);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-times fa-lg" />
              </Button>{" "}
            </div>
          ),
        };
      });
    setGetGedungBaru(tmp);
    let tmp2 = [];
    gedungChecklist.ruanganByGedung.map((val, index) => {
      val.ruangan.forEach((val2, index2) => {
        tmp2.push({
          gedung: val.gedungChecklist,
          ruangan: val2.areaChecklist,
          jenisChecklist: val2.jenisChecklist,
          // koderuangan: val2.koderuanganChecklist,
          actions: (
            <div className="actions-right">
              {/* <Button
                onClick={() => {
                  // setModalEditRuangan(true);
                  // setIdRuangan(val2._id);
                  console.log(val2._id);
                  setIdKodeRuanganCheckilst(val2._id);

                  // downloadPDF(val2._id, val2.area);
                  setModalEditRuangan(true);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-edit fa-lg" />
              </Button> */}
              <Button
                onClick={() => {
                  console.log(val2._id);

                  downloadQRChecklist(val2._id);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-qrcode fa-lg" />
              </Button>
              <Button
                onClick={() => {
                  // console.log(val2._id);
                  deleteRuanganChecklist(val2._id);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-times fa-lg" />
              </Button>
            </div>
          ),
        });
      });
    });
    setGetRuangan(tmp2);
  }, [gedungChecklist.ruanganByGedung]);

  function submitGedung(e) {
    e.preventDefault();
    daftarGedungChecklist({
      gedungChecklist: addGedungBaru,
    });
  }

  return (
    <>
      <Container fluid>
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalTambahGedung(true);
          }}
          variant="primary"
        >
          Add Building
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Building Checklist</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={getGedungBaru}
                  columns={[
                    {
                      Header: "Building Name",
                      accessor: "gedungChecklist",
                    },
                    {
                      Header: "Room Total",
                      accessor: "totalRuangan",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h4 className="title">Area</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={getRuangan}
                  columns={[
                    {
                      Header: "Building",
                      accessor: "gedung",
                    },
                    {
                      Header: "Area",
                      accessor: "ruangan",
                    },
                    {
                      Header: "Type Checklist",
                      accessor: "jenisChecklist",
                    },
                    // {
                    //   Header: "Area Code",
                    //   accessor: "koderuangan",
                    // },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modalTambahGedung}
        onHide={() => {
          setModalTambahGedung(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Building</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Building</label>
                          <Form.Control
                            onChange={(e) => {
                              setAddGedungBaru(e.target.value);
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Ruangan</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataBaru({
                                ...dataBaru,
                                area: e.target.value,
                              });
                            }}
                            placeholder="Masukan Nama Ruangan"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitGedung}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modalTambahRuangan}
        onHide={() => {
          setModalTambahRuangan(false);
          setModalDataGedung({});
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Area</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Building</label>
                          <Form.Control
                            value={modalDataGedung.gedungChecklist}
                            // placeholder="Masukan Nama Gedung"
                            disabled={true}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Area's Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setModalDataGedung({
                                ...modalDataGedung,
                                areaChecklist: e.target.value,
                              });
                            }}
                            // placeholder="Masukan Nama Ruangan"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Checklist Type</label>
                          <Form.Control
                            onChange={(e) => {
                              setModalDataGedung({
                                ...modalDataGedung,
                                jenisChecklist: e.target.value,
                              });
                            }}
                            // placeholder="Masukan Nama Ruangan"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      {/* <Col sm="12">
                        <Form.Group>
                          <label>Shift</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={
                              !modalDataGedung.idShift
                                ? modalDataGedung.idShift
                                : modalDataGedung.idShift.val
                            }
                            onChange={(val) =>
                              setModalDataGedung({
                                ...modalDataGedung,
                                idShift: val.value,
                              })
                            }
                            options={gedungChecklist.jamLaporan.map(
                              (val, index) => {
                                return {
                                  value: val._id,
                                  label: val.jam + "",
                                };
                              }
                            )}
                            placeholder="-Choose-"
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        daftarRuanganChecklist({
                          idGedungChecklist: modalDataGedung._id,
                          areaChecklist: modalDataGedung.areaChecklist,
                          jenisChecklist: modalDataGedung.jenisChecklist,
                          idShift: modalDataGedung.idShift,
                        });
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modalEditRuangan}
        onHide={() => {
          setModalEditRuangan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Area Code</label>
                          <Form.Control
                            onChange={(e) => {
                              setKodeRuanganChecklist(e.target.value);
                            }}
                            // placeholder="Masukan Kode Ruangan Baru"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Ruangan</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataBaru({
                                ...dataBaru,
                                area: e.target.value,
                              });
                            }}
                            placeholder="Masukan Nama Ruangan"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        console.log(
                          idKodeRuanganCheckilst,
                          kodeRuanganChecklist
                        );
                        e.preventDefault();
                        editKodeRuanganChecklist({
                          _id: idKodeRuanganCheckilst,
                          kodeRuanganChecklist: kodeRuanganChecklist,
                        });
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TambahGedungChecklist;
