import React from "react";
import ReactStars from "react-rating-stars-component";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getPenilaianKantin } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function RekapNilai() {
  const dispatch = useDispatch();
  const menuKantin = useSelector((state) => state.kantinReducer);
  const [modal, setModal] = React.useState(false);
  const [listMenu, setListMenu] = React.useState([]);
  const [listAlasan, setListAlasan] = React.useState([]);
  const [tampilNamaMenu, setTampilNamaMenu] = React.useState("");
  const [statusAlasan, setStatusAlasan] = React.useState(null);

  React.useEffect(() => {
    getPenilaianKantin(dispatch);
  }, []);
  React.useEffect(() => {
    let tmp =
      menuKantin.penilaian &&
      menuKantin.penilaian.map((val, index) => {
        let tglTampil = moment(val.tanggalMenu).format("YYYY-MM-DD");
        return {
          ...val,
          namaMenu: val.namaMenu,
          tanggalMenu: tglTampil,
          kodeMenu: val.kodeMenu,
          rating: (
            <ReactStars
              count={5}
              value={
                val.totalUser === 0
                  ? 0
                  : Math.round(val.totalRating / val.totalUser)
              }
              size={30}
              activeColor="#ffd700"
              edit={false}
            />
          ),

          // average: (
          // <ReactStars
          //   count={5}
          //   value={
          //     val.totalUser === 0
          //       ? 0
          //       : Math.round(val.totalRating / val.totalUser)
          //   }
          //   size={30}
          //   activeColor="#ffd700"
          //   edit={false}
          // />
          // ),

          actions: (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setListAlasan(val.alasan);
                  setTampilNamaMenu(val.namaMenu);
                  setModal(true);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fas fa-regular fa-comments fa-lg"></i>
              </Button>{" "}
              {/* <Button
                      onClick={() => {
                        setModal(true);
                        setNamaMenu(val.name);
                        setListAlasan(val.alasanTidakSuka);
                        setStatusAlasan(false);
                      }}
                      variant="danger"
                      size="md"
                      className="btn-link remove text-danger"
                    >
                      <i className="fas fa-thumbs-down fa-lg">{` ${val.alasanTidakSuka.length}`}</i>
                    </Button> */}
            </div>
          ),
        };
      });
    setListMenu(tmp);
  }, [menuKantin]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Recapitulation Menu Canteen</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={listMenu}
                  columns={[
                    {
                      Header: "Code Name",
                      accessor: "kodeMenu",
                    },
                    {
                      Header: "Name Menu",
                      accessor: "namaMenu",
                    },
                    { Header: "Date", accessor: "tanggalMenu" },
                    {
                      Header: "Rating",
                      accessor: "rating",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="sm"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            {tampilNamaMenu}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Comment</div>
          <div style={{ marginTop: 20 }}>
            {listAlasan.length === 0 ? (
              <div>No ratings yet</div>
            ) : (
              listAlasan.map((val) => {
                return (
                  <>
                    <Card
                      style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                    >
                      <Card.Header
                        className="d-none d-md-inline"
                        style={{ backgroundColor: "#dae1eb" }}
                      >
                        <Card.Title
                          as="h6"
                          style={{
                            textAlign: "center",
                            alignItems: "center",
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <ReactStars
                            count={5}
                            value={val.nilai}
                            size={30}
                            activeColor="#ffd700"
                            edit={false}
                          />
                        </Card.Title>
                      </Card.Header>
                      <Card.Body style={{ textAlign: "left" }}>
                        <div style={{ fontSize: 12, color: "grey" }}>
                          {val.alasan === "" ? "No Comment" : val.alasan}
                        </div>
                      </Card.Body>
                    </Card>
                  </>
                );
              })
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RekapNilai;
