const initialState = {
  penilaian: [],
  getMenuKantin: [],
  menuKantinToday: [],
  getMenuKantinBaru: [],
  grafikPenilaian1: [],
  grafikPenilaian2: null,
};

const menuKantinReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALLMENUKANTINBARU":
      return {
        ...state,
        getMenuKantinBaru: data,
      };
    case "SET_GETALLMENUKANTIN":
      return {
        ...state,
        getMenuKantin: data,
      };

    case "SET_GETALLPENILAIANKANTIN":
      return {
        ...state,
        penilaian: data,
      };

    case "SET_GETMENUKANTINTODAY":
      return {
        ...state,
        menuKantinToday: data,
      };
    case "SET_GRAFIKPENILAIAN1":
      return {
        ...state,
        grafikPenilaian1: data,
      };
    case "SET_GRAFIKPENILAIAN2":
      return {
        ...state,
        grafikPenilaian2: data,
      };
    default:
      return state;
  }
};

export default menuKantinReducer;
