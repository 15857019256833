import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Modal,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import {
  getShift,
  daftarShift,
  deleteShift,
  getAreaNonGroup,
  assignRoomToShift,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import TagsInput from "components/TagsInput/TagsInput.js";

function RegularTables() {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(false);

  const [durasi, setDurasi] = React.useState("");
  const monitoring = useSelector((state) => state.monitoringReducer);
  const [jamStr, setJamStr] = React.useState([]);
  const [shiftAssign, setShiftAssign] = React.useState(null);
  const [ruangan, setRuangan] = React.useState([]);
  React.useEffect(() => {
    let tmp = [...monitoring.listArea];
    tmp.forEach((val) => {
      val.ruangan.forEach((val2) => {
        val2.check = false;
      });
    });
    setRuangan(tmp);
  }, [monitoring.listArea]);

  React.useEffect(() => {
    if (shiftAssign) {
      let tmp = [...monitoring.listArea];
      tmp.forEach((val) => {
        val.ruangan.forEach((val2) => {
          if (shiftAssign.id === val2.idShift) {
            val2.check = true;
          } else {
            val2.check = false;
          }
        });
      });
      setRuangan(tmp);
    }
  }, [shiftAssign]);
  React.useEffect(() => {
    getShift(dispatch);
    getAreaNonGroup(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Button
              className="btn-wd mr-1"
              onClick={() => {
                setModal(true);
              }}
              variant="primary"
            >
              Add Shift
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={shiftAssign ? "4" : "12"}>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Shift Monitoring</Card.Title>
              </Card.Header>
              <Card.Body className="table-responsive p-0">
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Finishing Time</th>
                      <th>Duration</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {monitoring.jamLaporan.map((val, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{val.jam + ""}</td>
                          <td>{val.durasi}</td>
                          <td>
                            <Button
                              onClick={() => {
                                setShiftAssign({ jam: val.jam, id: val._id });
                              }}
                              variant="danger"
                              size="sm"
                              className="btn-link remove text-success"
                            >
                              <i className="fa fa-check" />
                            </Button>
                            <Button
                              onClick={() => {
                                deleteShift(val._id);
                              }}
                              variant="danger"
                              size="sm"
                              className="btn-link remove text-danger"
                            >
                              <i className="fa fa-times" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          {shiftAssign && shiftAssign.id && (
            <Col md="8">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">
                    Setting Area {shiftAssign ? `for ${shiftAssign.jam}` : ""}
                  </Card.Title>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Building</th>
                        <th>Area</th>
                        <th>Shift</th>
                        <th>Check</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ruangan.map((val, index) => {
                        return val.ruangan.map((val2, index2) => {
                          return (
                            <tr>
                              <td>{val.gedung}</td>
                              <td>{val2.area}</td>
                              <td>
                                {val2.shift.length > 0
                                  ? val2.shift[0].jam.join(",")
                                  : "-"}
                              </td>
                              <td>
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      checked={val2.check}
                                      onClick={(e) => {
                                        let tmp = [...ruangan];
                                        tmp[index].ruangan[index2].check =
                                          e.target.checked;
                                        setRuangan(tmp);
                                        console.log(tmp);
                                      }}
                                      disabled={shiftAssign ? false : true}
                                      type="checkbox"
                                    ></Form.Check.Input>
                                    <span className="form-check-sign"></span>
                                  </Form.Check.Label>
                                </Form.Check>
                              </td>
                            </tr>
                          );
                        });
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Button
                className="btn-wd mr-1"
                onClick={() => {
                  let tmp = [];
                  ruangan.forEach((val) => {
                    return val.ruangan.forEach((val2) => {
                      if (val2.check) {
                        tmp.push(val2._id);
                      }
                    });
                  });
                  assignRoomToShift({
                    idRooms: JSON.stringify(tmp),
                    idShift: shiftAssign.id,
                  });
                }}
                variant="primary"
              >
                Save
              </Button>
            </Col>
          )}
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Card className="stacked-form">
                <Card.Header>
                  <Card.Title as="h4">Add Shift</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form action="#" method="#">
                    <Form.Group>
                      <label>Finishing Time</label>
                      <TagsInput
                        value={jamStr}
                        onChange={(e) => {
                          if (
                            (e.length > 0 &&
                              !isNaN(e[e.length - 1]) &&
                              Number(e[e.length - 1]) >= 0 &&
                              Number(e[e.length - 1]) <= 24) ||
                            e.length === 0
                          ) {
                            setJamStr(e);
                          }
                        }}
                        tagProps={{
                          className: "react-tagsinput-tag tag-azure",
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Duration</label>
                      <Form.Control
                        // placeholder="Durasi pengerjaan"
                        type="text"
                        onChange={(e) => {
                          setDurasi(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <Button
                    onClick={() => {
                      var jam = jamStr.map((i) => Number(i));
                      var data = {
                        durasi: durasi,
                        jam: JSON.stringify(jam),
                      };
                      daftarShift(data);
                    }}
                    className="btn-fill"
                    type="submit"
                    variant="info"
                  >
                    Submit
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegularTables;
