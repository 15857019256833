let date = new Date();
const initialState = {
  username: "",
  perusahaan: "",
  cabang: "",
  menu: [],
  dariTgl: new Date(date.getFullYear(), date.getMonth(), 1).setHours(
    0,
    0,
    0,
    0
  ),
  smpTgl: new Date().setHours(23, 59, 0, 0),
};

const authReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_IDENTITY":
      return {
        ...state,
        username: data.username,
        cabang: data.cabang,
        perusahaan: data.perusahaan,
        menu: data.menu,
      };
    case "SET_TGLAWAL":
      return {
        ...state,
        dariTgl: data,
      };
    case "SET_TGLAKHIR":
      return {
        ...state,
        smpTgl: data,
      };
    default:
      return state;
  }
};

export default authReducer;
