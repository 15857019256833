import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAreaByIdGedung,
  sendDailyChecklist,
  ModalDetailChecklist,
  getHasilChecklistByDate,
} from "../../stores";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function HalamanChecklist() {
  const dispatch = useDispatch();

  // const survey = useSelector((state) => state.surveyReducer);
  const formChecklist = useSelector((state) => state.gedungChecklistReducer);

  let query = new URLSearchParams(useLocation().search);
  const [hasilChecklist, setHasilChecklist] = React.useState([]);

  React.useEffect(() => {
    getHasilChecklistByDate(dispatch, query.get("idGedung"));
  }, []);

  React.useEffect(() => {
    let tmp =
      formChecklist.HasilChecklist &&
      formChecklist.HasilChecklist.map((val) => {});
    setHasilChecklist(tmp);
  }, [formChecklist.HasilChecklist]);

  const handleClick = (val) => {
    // console.log(val, "??????????????");
    dispatch({ type: "SET_DETAILCHECKLIST", data: true });
  };
  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="survey-form">
          <Card style={{ backgroundColor: "white" }}>
            <Card.Header style={{ display: "flex", alignItems: "center" }}>
              <Button
                className="btn-transparent mr-2"
                type="button"
                variant="primary"
                size="md"
                style={{
                  border: "none",
                  backgroundColor: "#00BFFF",
                  border: 0,
                }}
                onClick={handleClick}
              >
                <i className="fas fa-arrow-left"></i>
              </Button>
              <Card.Title
                as="h3"
                style={{
                  marginTop: 10,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  flex: 1,
                  marginBottom: 15,
                }}
              >
                Detail Checklist
              </Card.Title>
            </Card.Header>

            <div
              style={{
                marginTop: 10,
                fontWeight: "bold",
                textTransform: "uppercase",
                flex: 1,
                marginBottom: 15,
                fontSize: 25,
                marginLeft: 15,
              }}
            >
              {" "}
              Building :{/* {namaGedung} */}
            </div>
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  // data={data}
                  pagination
                  expandableRows
                  // expandableRowsComponent={ExpandedComponent1}
                />
              </Card.Body>
            </Card>
          </Card>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default HalamanChecklist;
