import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export function getPenilaianKantin(dispatch) {
  baseAxios
    .get("/menukantin/getallpenilaian", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then((respon) => {
      dispatch({ type: "SET_GETALLPENILAIANKANTIN", data: respon.data });
    })
    .catch(errorHandler);
}

export function kirimPenilaian(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/menukantin/penilaianmenukantin", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      if (respon.status === 200) {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: respon.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch(errorHandler);
}
export function tambahMenuKantin(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/menukantin/tambahmenukantin", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      if (respon.status === 200) {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: respon.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch(errorHandler);
}

export function editMenuKantin(data) {
  baseAxios
    .patch(`/menukantin/editmenukantin`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function deleteMenuKantin(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      baseAxios
        .delete("menukantin/deletemenukantin", {
          data: data,
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);
    }
  });
}
export function getMenuKantinToday(dispatch) {
  baseAxios
    .get("menukantin/getmenukantinbydate")
    .then((respon) => {
      dispatch({ type: "SET_GETMENUKANTINTODAY", data: respon.data });
    })
    .catch(errorHandler);
}

export function getMenuKantin(dispatch) {
  baseAxios
    .get("/menukantin/getallmenukantin")
    .then((respon) => {
      dispatch({ type: "SET_GETALLMENUKANTIN", data: respon.data });
    })
    .catch(errorHandler);
}

export function grafikPenilaian1(dispatch, data) {
  baseAxios
    .get(
      `menukantin/getgrafikpenilaian1?dari=${data.dariTgl}&sampai=${data.smpTgl}&kodeMenuPenilaian=${data.kodeMenuPenilaian}`,
      { headers: { token: localStorage.getItem("tokenjti") } }
    )
    .then((respon) => {
      dispatch({ type: "SET_GRAFIKPENILAIAN1", data: respon.data });
    })
    .catch(errorHandler);
}
export function grafikPenilaian2(dispatch, data) {
  baseAxios
    .get(
      `menukantin/getgrafikpenilaian2?dari=${data.dariTgl}&sampai=${data.smpTgl}&kodeMenuPenilaian=${data.kodeMenuPenilaian}`,
      { headers: { token: localStorage.getItem("tokenjti") } }
    )
    .then((respon) => {
      dispatch({ type: "SET_GRAFIKPENILAIAN2", data: respon.data });
    })
    .catch(errorHandler);
}
//================MENU KANTIN BARU==================================
export function getMenuKantinBaru(dispatch) {
  baseAxios
    .get("/menukantin/getallmenukantinbaru", {
      headers: { token: localStorage.getItem("tokenjti") },
    })

    .then((respon) => {
      dispatch({ type: "SET_GETALLMENUKANTINBARU", data: respon.data.data });
    })
    .catch(errorHandler);
}

export function tambahMenuKantinBaru(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/menukantin/tambahmenukantinbaru", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      if (respon.status === 200) {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: respon.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch(errorHandler);
}

export function deleteMenuKantinBaru(data) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      baseAxios
        .delete("menukantin/deletemenukantinbaru", {
          data: data,
          headers: { token: localStorage.getItem("tokenjti") },
        })
        .then(async (respon) => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        })
        .catch(errorHandler);
    }
  });
}

export function editMenuKantinBaru(data) {
  baseAxios
    .patch(`/menukantin/editmenukantinbaru`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}
